import { AxiosPromise } from 'axios';

import BaseRestService from '@/common/rest/base-rest-service';

import type { IPageRequest } from '@/rest';
import type { UserModel } from '@/types';
import type { PageResponse } from './types';

class UsersRestService extends BaseRestService {
    getAllPageableUsers(page: IPageRequest): AxiosPromise<PageResponse<UserModel>> {
        return super.get(`/api/users?page=${page.page}&size=${page.size}`);
    }

    getLexileLevel() {
        return super.get('/api/users/lexile');
    }

    getUserDetails() {
        return super.get('/api/users/details');
    }
}

const usersRestService: UsersRestService = new UsersRestService();

export { usersRestService };
