import { ModalGetterNames, ModalMutationNames } from '@/common/store/modal-store-const';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store';
import { Vue } from 'vue-property-decorator';

interface ModalState {
    modalVisible: boolean;
    modalComponent: Vue | null;
}

const initialState = (): ModalState => ({
    modalVisible: false,
    modalComponent: null
});

const state: ModalState = initialState();

const getters: GetterTree<ModalState, RootState> = {
    [ModalGetterNames.modalVisible]: (state: ModalState) => state.modalVisible,
    [ModalGetterNames.show]: (state: ModalState) => (component: Vue) => state.modalComponent === component,
    [ModalGetterNames.modalComponent]: (state: ModalState) => state.modalComponent
};

const actions: ActionTree<ModalState, RootState> = {};

const mutations: MutationTree<ModalState> = {
    [ModalMutationNames.showModal]: (state: ModalState, component: Vue) => {
        state.modalVisible = true;
        state.modalComponent = component;
    },

    [ModalMutationNames.hideModal]: (state: ModalState) => {
        state.modalVisible = false;
        state.modalComponent = null;
    },

    [ModalMutationNames.reset]: (state: ModalState) => {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key];
        });
    }
};

const namespaced: boolean = true;

const modal: Module<ModalState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};

export default modal;
