import BaseRestService from '@/common/rest/base-rest-service';
import type { IMySettings } from '@/types';

class MySettingsRestService extends BaseRestService {
    public async getAccountSettings(): Promise<IMySettings.IAccountSettings> {
        function throwInvalidResponseError(): never {
            throw new Error('Error: Invalid response in getAccountSettings.');
        }

        const response = await super.get(`/api/teacher/settings/account`);

        if (response && response.data) {
            const responseData = response.data;
            const result = {} as IMySettings.IAccountSettings;

            if (!responseData.email) result.email = '';
            else result.email = responseData.email;

            if (!responseData.phone) result.phone = '';
            else result.phone = responseData.phone;

            if (!responseData.name) result.name = '';
            else result.name = responseData.name;

            if (!responseData.surname) result.surname = '';
            else result.surname = responseData.surname;

            if (!responseData.username) result.username = '';
            else result.username = responseData.username;

            if (!responseData.password) result.password = '';
            else result.password = responseData.password;

            return result;
        } else {
            throwInvalidResponseError();
        }
    }

    public async updateAccountSettings(payload: IMySettings.IAccountSettings): Promise<void> {
        const fixedPayload = { ...payload } as any;
        if (!fixedPayload.password) {
            delete fixedPayload.password;
        }
        await super.post('/api/teacher/settings/account', fixedPayload);
    }

    public async getBookshelfRows(): Promise<IMySettings.IBookshelfRow[]> {
        function isIBookshelfRow(value): value is IMySettings.IBookshelfRow {
            if (
                typeof value.carouselId === 'string' &&
                typeof value.enabled === 'boolean' &&
                typeof value.id === 'number' &&
                typeof value.title === 'string'
            ) {
                return true;
            } else {
                return false;
            }
        }

        function throwInvalidResponseError(): never {
            throw new Error('Error: Invalid response in getBookshelfRows.');
        }

        const response = await super.get('/api/teacher/settings/bookshelf-rows');

        if (response && response.data) {
            const responseData = response.data;

            if (Array.isArray(responseData)) {
                if (responseData.length) {
                    if (isIBookshelfRow(responseData[0])) {
                        return responseData as IMySettings.IBookshelfRow[];
                    } else {
                        throwInvalidResponseError();
                    }
                } else {
                    return [] as IMySettings.IBookshelfRow[];
                }
            } else {
                throwInvalidResponseError();
            }
        } else {
            throwInvalidResponseError();
        }
    }

    public async updateBookshelfRows(bookshelfRows: IMySettings.IBookshelfRow[]): Promise<void> {
        await super.post('/api/teacher/settings/bookshelf-rows', bookshelfRows);
    }

    public async getBookshelfSettings(): Promise<IMySettings.IBookshelfSettings> {
        function throwInvalidResponseError(): never {
            throw new Error('Error: Invalid response in getBookshelfSettings.');
        }

        function isIBookshelfSettings(value): value is IMySettings.IBookshelfSettings {
            if (
                ((typeof value.rangeFrom === 'number' || value.rangeFrom === null) &&
                    (typeof value.rangeTo === 'number' || value.rangeTo === null) &&
                    typeof value.showBilingualTitles === 'boolean' &&
                    typeof value.showSpanishTitles === 'boolean' &&
                    typeof value.maxLexileWeight === 'number',
                typeof value.minLexileWeight === 'number',
                value.teacherBookshelfRange === 'LEXILE_RANGE_OF_MY_STUDENTS' ||
                    value.teacherBookshelfRange === 'BOOKS_WITHIN_USER_VALUES')
            ) {
                return true;
            } else {
                return false;
            }
        }

        const response = await super.get('/api/teacher/settings/bookshelf');

        if (response && response.data) {
            const responseData = response.data;

            if (isIBookshelfSettings(responseData)) {
                return responseData;
            } else {
                throwInvalidResponseError();
            }
        } else {
            throwInvalidResponseError();
        }
    }

    public async updateBookshelfSettings(bookshelfSettings: IMySettings.IBookshelfSettings) {
        const fixedBookshelfSettings = { ...bookshelfSettings } as any;
        delete fixedBookshelfSettings.maxLexileWeight;
        delete fixedBookshelfSettings.minLexileWeight;

        await super.post('/api/teacher/settings/bookshelf', fixedBookshelfSettings);
    }
}

const mySettingsRestService = new MySettingsRestService();

export default mySettingsRestService;
