import { AxiosPromise } from 'axios';

import BaseRestService from '@/common/rest/base-rest-service';

import type { IPageRequest, IPageResponse } from '@/rest';
import type { AutocompleteModel, BookModel, FiltersModel, StudentModel } from '@/types';

class SearchRestService extends BaseRestService {
    getFilters(): AxiosPromise<FiltersModel> {
        return super.get(`/api/search/filters`);
    }

    fetchAutocompleteResults(query: string | number): AxiosPromise<AutocompleteModel[]> {
        return super.get(`/api/search/autocomplete/${query}`);
    }

    getFilteredBooks(filters: FiltersModel, page: IPageRequest): AxiosPromise<IPageResponse<BookModel>> {
        return super.post(`/api/search/apply-filters?page=${page.page}&size=${page.size}`, filters);
    }

    getFilteredBooksByInsideSearch(filters: FiltersModel, page: IPageRequest): AxiosPromise<IPageResponse<BookModel>> {
        return super.post(`/api/search/inside`, { ...filters, pageNumber: page.page });
    }

    findUsers(query: string): AxiosPromise<StudentModel[]> {
        return super.get(`/api/search/users?query=${encodeURIComponent(query)}`);
    }
}

const searchRestService: SearchRestService = new SearchRestService();

export default searchRestService;
