import { defineStore } from 'pinia';

import { useClassroomAssignmentsStore } from '@/store';
import bookshelfRestService from '@/site-pages/bookshelf/rest/bookshelf-rest-service';
import assignRestService from '@/rest/assignments';

import type { BookAssignments, BookModel, StudentAssignment } from '@/types';
import type { BookDetailsState } from './types';

export const useBookDetailsStore = defineStore('book-details', {
    state: (): BookDetailsState => ({
        books: [],
        assignments: {},
        bookDetails: {},
        fetchingDetails: false,
        fetchingAssignments: false,
        assigning: false,
    }),

    actions: {
        async loadBooks(bookGroupId: number) {
            this.fetchingDetails = true;

            try {
                const { data: books } = await bookshelfRestService.getBooksByGroup(bookGroupId);

                this.books = books;
            } finally {
                this.fetchingDetails = false;
            }
        },

        async assignBook({ idNumber, students }: { idNumber: string; students: StudentAssignment[] }) {
            const assignmentsStore = useClassroomAssignmentsStore();

            this.assigning = true;

            try {
                const { data: assignments }: { data: BookAssignments } = await assignRestService.assignBook(
                    idNumber,
                    students
                );

                this.assignments = {
                    ...this.assignments,
                    [idNumber]: assignments,
                };

                this.updateInteractions({ idNumber, students: assignments.students });

                assignmentsStore.fetchAssignments();
            } finally {
                this.assigning = false;
            }
        },

        async fetchAssignments(idNumber: string) {
            this.fetchingAssignments = true;

            try {
                const { data: assignments }: { data: BookAssignments } = await assignRestService.getAssignments(
                    idNumber
                );

                this.assignments = {
                    ...this.assignments,
                    [idNumber]: assignments,
                };
            } finally {
                this.fetchingAssignments = false;
            }
        },

        async fetchBookDetails(idNumber: string) {
            this.fetchingDetails = true;

            try {
                const { data: bookDetails }: { data: BookModel } = await bookshelfRestService.getBookDetails(idNumber);

                this.bookDetails = { ...this.bookDetails, [idNumber]: bookDetails };

                return bookDetails;
            } finally {
                this.fetchingDetails = false;
            }
        },

        updateInteractions({ idNumber, students }: { idNumber: string; students: StudentAssignment[] }) {
            const bookIndex = this.books.findIndex((book) => book.idNumber === idNumber);
            const book = this.books[bookIndex];
            const bookStudents = book.students.slice();

            students.forEach((student) => {
                const assignedStudent = bookStudents.find((item) => item.id === student.id);

                if (assignedStudent) {
                    assignedStudent.assigned = student.assignDate;
                }
            });

            this.books = [
                ...this.books.slice(0, bookIndex),
                {
                    ...book,
                    students: bookStudents,
                },
                ...this.books.slice(bookIndex + 1),
            ];
        },
    },

    getters: {
        getBookAssignments:
            (state: BookDetailsState) =>
            (idNumber: string): BookAssignments =>
                state.assignments[idNumber] || {
                    groups: [],
                    students: [],
                },

        getBookDetails:
            (state: BookDetailsState) =>
            (idNumber: string): BookModel =>
                state.bookDetails[idNumber],
    },
});
