import { Role } from '@/constants';
import { ReportType } from '@/site-pages/reports/model/report-constants';
import { MultiDistrictReportRoute } from './multi-district';
import { DistrictReportRoute } from './district';
import { SchoolReportRoute } from './school';
import { TeacherReportRoute } from './teacher';

export const ReportRoute = {
    [Role.MULTI_DISTRICT_MANAGER]: {
        [ReportType.REPORT_MULTI_DISTRICT]: MultiDistrictReportRoute.MULTI_DISTRICT,
        [ReportType.REPORT_DISTRICT]: DistrictReportRoute.DISTRICT,
        [ReportType.REPORT_SCHOOL]: DistrictReportRoute.SCHOOL,
        [ReportType.REPORT_CLASSROOM]: DistrictReportRoute.CLASSROOM,
        [ReportType.REPORT_STUDENT]: DistrictReportRoute.STUDENT,
    },
    [Role.DISTRICT_MANAGER]: {
        [ReportType.REPORT_DISTRICT]: DistrictReportRoute.DISTRICT,
        [ReportType.REPORT_SCHOOL]: DistrictReportRoute.SCHOOL,
        [ReportType.REPORT_CLASSROOM]: DistrictReportRoute.CLASSROOM,
        [ReportType.REPORT_STUDENT]: DistrictReportRoute.STUDENT,
    },
    [Role.SCHOOL_MANAGER]: {
        [ReportType.REPORT_SCHOOL]: SchoolReportRoute.SCHOOL,
        [ReportType.REPORT_CLASSROOM]: SchoolReportRoute.CLASSROOM,
        [ReportType.REPORT_STUDENT]: SchoolReportRoute.STUDENT,
    },
    [Role.TEACHER]: {
        [ReportType.REPORT_CLASSROOM]: TeacherReportRoute.CLASSROOM,
        [ReportType.REPORT_STUDENT]: TeacherReportRoute.STUDENT,
    },
};

export const BreakdownReportRoute = {
    [Role.MULTI_DISTRICT_MANAGER]: {
        [ReportType.REPORT_MULTI_DISTRICT]: MultiDistrictReportRoute.MULTI_DISTRICT_ACTIVITY,
        [ReportType.REPORT_DISTRICT]: DistrictReportRoute.DISTRICT_ACTIVITY,
        [ReportType.REPORT_SCHOOL]: DistrictReportRoute.SCHOOL_ACTIVITY,
        [ReportType.REPORT_CLASSROOM]: DistrictReportRoute.CLASSROOM_ACTIVITY,
        [ReportType.REPORT_STUDENT]: DistrictReportRoute.STUDENT_ACTIVITY,
    },
    [Role.DISTRICT_MANAGER]: {
        [ReportType.REPORT_DISTRICT]: DistrictReportRoute.DISTRICT_ACTIVITY,
        [ReportType.REPORT_SCHOOL]: DistrictReportRoute.SCHOOL_ACTIVITY,
        [ReportType.REPORT_CLASSROOM]: DistrictReportRoute.CLASSROOM_ACTIVITY,
        [ReportType.REPORT_STUDENT]: DistrictReportRoute.STUDENT_ACTIVITY,
    },
    [Role.SCHOOL_MANAGER]: {
        [ReportType.REPORT_SCHOOL]: SchoolReportRoute.SCHOOL_ACTIVITY,
        [ReportType.REPORT_CLASSROOM]: SchoolReportRoute.CLASSROOM_ACTIVITY,
        [ReportType.REPORT_STUDENT]: SchoolReportRoute.STUDENT_ACTIVITY,
    },
    [Role.TEACHER]: {
        [ReportType.REPORT_CLASSROOM]: TeacherReportRoute.CLASSROOM_ACTIVITY,
        [ReportType.REPORT_STUDENT]: TeacherReportRoute.STUDENT_ACTIVITY,
    },
};
