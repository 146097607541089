
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

@Component
export default class FormFieldMixin extends mixins(singleErrorExtractorMixin) {
    @Prop({
        default: false,
        type: Boolean
    })
    disabled!: boolean;

    @Prop({
        default: false,
        type: Boolean
    })
    invalid!: boolean;

    @Prop({
        default: false,
        type: Boolean
    })
    required!: boolean;

    @Prop({
        type: [Array, Object, Number, String],
        default: null
    })
    value!: [] | object | number | string | null;

    @Prop({
        type: Boolean,
        default: false
    })
    withClear!: boolean;

    get model() {
        return this.value;
    }

    set model(value) {
        this.$emit('input', value);
    }

    clear() {
        this.model = null;
    }
}
