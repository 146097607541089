import { GetterTree } from 'vuex';
import { flatten, orderBy, uniqBy } from 'lodash';

import { RootState } from '@/store';
import {
    ClassroomDetailsModel,
    ClassroomModel,
    DistrictModel,
    DistrictOrder,
    SchoolModel,
    GroupItem,
    UserModel,
    ClassroomUserModel,
} from '@/types';
import { RostersState, RostersGetters } from './types';

const getters: GetterTree<RostersState, RootState> = {
    [RostersGetters.districts]: (state): DistrictModel[] => Object.values(state.districts),

    [RostersGetters.districtsByName]: (state, getters): DistrictModel[] =>
        orderBy(getters.districts, (district) => district.name.toLowerCase()),

    [RostersGetters.districtsByStandard]: (state, getters): DistrictModel[] =>
        orderBy(getters.districts, ['standard', (district) => district.name.toLowerCase()]),

    [RostersGetters.districtsOrder]: (state): DistrictOrder => state.districtsOrder,

    [RostersGetters.getClassroomById]:
        (state) =>
        (classroomId: number): ClassroomModel | ClassroomDetailsModel | undefined =>
            state.classrooms.get(classroomId),

    [RostersGetters.getDistrictById]:
        (state) =>
        (districtId: number): DistrictModel | undefined =>
            districtId ? state.districts[districtId] : undefined,

    [RostersGetters.getDistrictSchools]:
        (state) =>
        (districtId: number): SchoolModel[] => {
            const district = state.districts[districtId];

            if (!district || !district.schoolIds) {
                return [];
            }

            return orderBy(district.schoolIds.map((schoolId) => state.schools[schoolId]).filter(Boolean), (school) =>
                school.name.toLocaleLowerCase()
            );
        },

    [RostersGetters.getSchoolById]:
        (state) =>
        (schoolId: number): SchoolModel | undefined =>
            schoolId ? state.schools[schoolId] : undefined,

    [RostersGetters.getGroupAdmins]:
        (state) =>
        (groupId: number): UserModel[] => {
            return (
                orderBy(state.admins.get(groupId), [
                    (admin) => admin.name.toLocaleLowerCase(),
                    (admin) => admin.surname.toLocaleLowerCase(),
                ]) || []
            );
        },

    [RostersGetters.getGroupAdminById]:
        (state, getters) =>
        (groupId: number, userId: number): UserModel | undefined => {
            const groupAdmins: UserModel[] = getters[RostersGetters.getGroupAdmins](groupId);

            return groupAdmins.find((user) => user.id === userId);
        },

    [RostersGetters.getSchoolClassrooms]:
        (state, getters) =>
        (schoolId: number): ClassroomModel[] => {
            const school = state.schools[schoolId];

            if (!school || !school.classrooms) {
                return [];
            }

            return orderBy(
                school.classrooms.map((classroomId) => getters[RostersGetters.getClassroomById](classroomId)),
                (classroom) => classroom.name.toLocaleLowerCase()
            );
        },

    [RostersGetters.getSchoolTeachers]:
        (state, getters) =>
        (schoolId: number): ClassroomUserModel[] => {
            const school: SchoolModel = getters[RostersGetters.getSchoolById](schoolId);

            if (!school) {
                return [];
            }

            const teachers: ClassroomUserModel[] = flatten(
                school.classrooms.map((classroomId) => {
                    const users = state.classrooms.get(classroomId)?.teachers.map((user) => {
                        return {
                            ...user,
                            classroomId,
                        };
                    });

                    return users || [];
                })
            );
            const uniqTeachers: ClassroomUserModel[] = uniqBy(teachers, 'id');

            return orderBy(uniqTeachers, [
                (teacher) => (teacher.name ? teacher.name.toLocaleLowerCase() : teacher.surname.toLocaleLowerCase()),
                (teacher) => teacher.surname.toLocaleLowerCase(),
                (teacher) => teacher.username.toLocaleLowerCase(),
            ]);
        },

    [RostersGetters.getClassroomUsers]:
        (state) =>
        (classroomId: number): UserModel[] => {
            return (
                orderBy(state.users.get(classroomId), [
                    (user) => user.name.toLocaleLowerCase(),
                    (user) => user.surname.toLocaleLowerCase(),
                ]) || []
            );
        },

    [RostersGetters.getClassroomUserById]:
        (state, getters) =>
        (classroomId: number, userId: number): UserModel | undefined => {
            const users: UserModel[] = getters[RostersGetters.getClassroomUsers](classroomId);

            return users.find((user) => user.id === userId);
        },

    [RostersGetters.getSchoolsWithClassrooms]: (state): GroupItem[] => {
        return orderBy(state.schoolsWithClassrooms, [(school) => school.name.toLocaleLowerCase()]) || [];
    },
};

export default getters;
