import { RouteConfig } from 'vue-router';
import { useAuth } from '@/store';
import { BookshelfRoute } from './bookshelf';

const AccountLayout = () => import(/* webpackChunkName: "account" */ '@/common/layouts/account/AccountLayout.vue');
const BadgeLoginPage = () => import(/* webpackChunkName: "account" */ '@/site-pages/login/BadgeLoginPage.vue');

const accoutRoutes: RouteConfig[] = [
    {
        path: '/account',
        component: AccountLayout,
        children: [
            {
                path: '',
                redirect: '/login',
            },

            {
                path: '/login',
                name: 'login',
                meta: {
                    public: true,
                    onlyWhenLoggedOut: true,
                },
            },

            {
                path: '/login/badge',
                name: 'login.badge',
                component: BadgeLoginPage,
                meta: {
                    public: true,
                    onlyWhenLoggedOut: true,
                },
            },

            {
                path: '/logout',
                name: 'logout',
            },

            {
                path: '/oauth',
                name: 'oauth',
                meta: {
                    public: true,
                },
                async beforeEnter(to, from, next) {
                    const auth = useAuth();

                    try {
                        await auth.oauthLogin();
                        next({ name: BookshelfRoute.BOOKSHELF });
                    } catch (e) {
                        next({ name: 'login' });
                    }
                },
            },
        ],
    },
];

export default accoutRoutes;
