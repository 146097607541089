export enum ModalMutationNames {
    showModal = 'showModal',
    hideModal = 'hideModal',
    reset = 'RESET'
}

export enum ModalGetterNames {
    modalVisible = 'modalVisible',
    modalComponent = 'modalComponent',
    show = 'show'
}

export const modalNamespace = 'modal';
