import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';

const quizRoutes: RouteConfig[] = [
    {
        path: '/quiz/:id',
        component: () => import(/* webpackChunkName: "quiz-layout" */ '@/common/layouts/quizzes/QuizSiteLayout.vue'),
        children: [
            {
                path: '/quiz/:id',
                name: 'quiz',
                props: true,
                component: () => import(/* webpackChunkName: "quiz-layout" */ '@/site-pages/quiz/view/QuizView.vue'),
                meta: {
                    forgetOnLogOut: true,
                    roles: [Role.STUDENT],
                },
            },
            {
                path: '/quiz/:id/score',
                name: 'quiz-score',
                props: true,
                component: () =>
                    import(/* webpackChunkName: "quiz-layout" */ '@/site-pages/quiz/view/QuizScoreView.vue'),
                meta: {
                    forgetOnLogOut: true,
                    roles: [Role.STUDENT],
                },
            },
        ],
    },
];

export default quizRoutes;
