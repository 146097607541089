import { defineStore } from 'pinia';

import { LexileTestLevel, Role } from '@/constants';
import { lexileRestService, usersRestService } from '@/rest';
import router from '@/router';
import { useAuth } from '@/store';
import { useLexilePolling } from './polling';

import type { LexileState } from './types';
import type { AssessmentResult, LexileStem, AssessmentUserResponse } from '@/types';

export const useLexileAssessmentsStore = defineStore('lexile', {
    state: (): LexileState => ({
        assessmentStarted: false,
        currentLexileLevel: null,
        assessmentResult: { lexileLevel: 0, pointCount: 0 },
        currentQuestionIndex: 0,
        assessment: {
            answeredQuestionNumber: 0,
            notAnsweredQuestionNumber: 0,
            allQuestionNumber: 0,
            lexileTestLevel: {} as LexileTestLevel,
            gradeLevel: '',
            steppedDown: false,
            stems: [
                {
                    id: 0,
                    passageText: '',
                    stemQuestion: '',
                    stemType: '',
                    clozes: [],
                },
            ],
        },
    }),

    actions: {
        async markFormAsStarted() {
            const lexilePolling = useLexilePolling();

            try {
                const { data: isStarted } = await lexileRestService.markFormAsStarted();

                this.assessmentStarted = isStarted;
                lexilePolling.shouldDisplayReminder = false;

                router.push({ name: 'lexile' });
            } catch (error) {
                this.assessmentStarted = false;
                console.error("Can't mark lexile test as started", error);
                throw error;
            }
        },

        async saveUserAnswers(userAnswers: AssessmentUserResponse[]) {
            const {
                data: { lexileLevel, pointCount, stems, isSteppedDown, isSteppedUp },
            } = await lexileRestService.saveLexileUserAnswers(userAnswers);

            const result: AssessmentResult = {
                lexileLevel,
                pointCount,
            };
            const criteria = {
                ...(isSteppedDown && { isSteppedDown }),
                ...(isSteppedUp && { isSteppedUp }),
                ...(pointCount && { isStopped: true }),
            };

            if (stems) {
                this.resetCurrentQuestionIndex();
                this.setStems(stems);
            }

            this.assessmentResult = result;

            return criteria;
        },

        async fetchCurrentLexile() {
            const { data: lexileLevel } = await usersRestService.getLexileLevel();

            this.currentLexileLevel = lexileLevel;
        },

        async triggerLexileCheck() {
            const auth = useAuth();
            const lexilePolling = useLexilePolling();

            if (auth.role === Role.STUDENT && auth.profile?.isAssessmentsEnabled) {
                const { data: hasLexileAvailable } = await lexileRestService.triggerLexileCheck();

                lexilePolling.newLexileAvailable = hasLexileAvailable;
            }
        },

        startAssessment() {
            this.assessmentStarted = true;
        },

        finishAssessment() {
            this.assessmentStarted = false;
        },

        async fetchCurrentAssessment() {
            const { data: assessment } = await lexileRestService.fetchCurrentAssessment();

            this.assessment = assessment;
            this.currentQuestionIndex = 0;
        },

        resetCurrentQuestionIndex() {
            this.currentQuestionIndex = 0;
        },

        next() {
            if (this.currentQuestionIndex + 1 < this.assessment.stems.length) {
                this.currentQuestionIndex++;
            }
        },

        setStems(stems: LexileStem[]) {
            this.assessment = {
                ...this.assessment,
                stems,
            };
        },
    },

    getters: {
        isClozeTest: (state: LexileState) => state.assessment.lexileTestLevel === LexileTestLevel.CLOZE,

        stems: (state: LexileState) => state.assessment.stems,

        stem: (state: LexileState) => state.assessment.stems[state.currentQuestionIndex],

        isLastStem: (state: LexileState) => state.assessment.stems.length === state.currentQuestionIndex + 1,

        answeredQuestionNumber: (state: LexileState) => state.assessment.answeredQuestionNumber,

        gradeLevel: (state: LexileState) => state.assessment.gradeLevel,

        steppedDown: (state: LexileState) => state.assessment.steppedDown,
    },
});
