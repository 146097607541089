import { QuizFrequency, QuizAvailability } from '@/types';

export enum LEVELUP_THEME {
    DEFAULT = 'default',
    STUDENT = 'student',
    STUDENT_NEXT = 'student-next',
}

export enum DATE_FORMAT {
    API = 'yyyy-MM-dd',
}

export enum KEY_CODES {
    ENTER = 13,
}

export enum Role {
    ADMIN = 'ADMIN',
    DISTRICT_MANAGER = 'DISTRICT_MANAGER',
    MULTI_DISTRICT_MANAGER = 'MULTI_DISTRICT_MANAGER',
    SCHOOL_MANAGER = 'SCHOOL_MANAGER',
    TEACHER = 'TEACHER',
    STUDENT = 'STUDENT',
}

export const REPORT_PAGES = {
    [Role.MULTI_DISTRICT_MANAGER]: '/reports/multi-district',
    [Role.DISTRICT_MANAGER]: '/reports/district',
    [Role.SCHOOL_MANAGER]: '/reports/school',
    [Role.TEACHER]: '/reports/classroom',
};

export const HOME_PAGES = {
    default: '/bookshelf',
    [Role.ADMIN]: '/admin',
    [Role.MULTI_DISTRICT_MANAGER]: '/reports/multi-district',
    [Role.DISTRICT_MANAGER]: '/reports/district',
    [Role.SCHOOL_MANAGER]: '/reports/school',
};

export const MODAL_DEFAULT_SETTINGS = {
    adaptive: true,
    width: '92%',
    maxWidth: 720,
    height: 'auto',
    scrollable: true,
};

export const VALIDATION_MESSAGES = {
    maxLength: 'Should not exceed {max} characters.',
    required: 'This field is required.',
    email: 'Please enter a valid email address.',
    uniqueUsername: 'Username is not available.',
};

export enum NOTIFICATION_TYPES {
    INFO = 'lu-info',
    SUCCESS = 'lu-success',
    ERROR = 'lu-error',
}

export const quizFrequencies: QuizFrequency[] = [
    {
        id: 1,
        key: 'NEVER',
        label: 'Never',
    },
    {
        id: 2,
        key: 'AFTER_EVERY_BOOK',
        label: 'After every book',
    },
    {
        id: 3,
        key: 'EVERY_2_BOOKS',
        label: '2 books',
    },
    {
        id: 4,
        key: 'EVERY_3_BOOKS',
        label: '3 books',
    },
    {
        id: 5,
        key: 'EVERY_4_BOOKS',
        label: '4 books',
    },
    {
        id: 6,
        key: 'EVERY_5_BOOKS',
        label: '5 books',
    },
];

export const quizAvailabilities: QuizAvailability[] = [
    {
        id: 1,
        key: 'STUDENT_BOOKSHELF_RANGE',
        label: 'Student Bookshelf Range',
    },
    {
        id: 2,
        key: 'STUDENT_BOOKSHELF_RANGE_AND_LOWER',
        label: 'Student Bookshelf Range and Lower',
    },
    {
        id: 3,
        key: 'QUIZ_ANY_BOOK',
        label: 'Quiz Any Book',
    },
];

export enum BULK_ASSIGNMENT_FILTER {
    GRADE_LEVEL = 'gradeLevel',
    LEXILE_RANGE = 'lexileRange',
}

export const RUNNING_RECORDS_POSTFIX = '_rr';
export const SELF_CORRECTION_PREFIX = 'SC_';

export enum LexileErrorCode {
    HAS_LEXILE_TEST = 12,
    HAS_INTAKE_TEST = 61,
    WAS_ANSWERED = 87,
}

export const LexileErrorCodeLabel = new Map<number, boolean>([
    [LexileErrorCode.HAS_LEXILE_TEST, true],
    [LexileErrorCode.HAS_INTAKE_TEST, true],
]);

export const LexileErrorCodeRestore = new Map<number, boolean>([[LexileErrorCode.WAS_ANSWERED, true]]);

export enum LexileErrorStatus {
    NO_LEXILE_TEST_FOUND = 4,
    NO_INTAKE_TEST_FOUND = 8,
    NO_ACTIVE_TEST_FOUND = 86,
}

export enum StemType {
    FOUNDATIONAL_READING = 'Foundational Reading',
    PICTURE = 'Picture',
    ONE_SENT = '1-sent',
    TWO_SENT = '2-sent',
    NATIVE = 'Native',
}

export enum LexileTestLevel {
    INTAKE = 'INTAKE',
    TESTLET = 'TESTLET',
    CLOZE = 'CLOZE',
}

export enum HTTP_STATUS_CODE {
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
}
