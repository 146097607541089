import { defineStore } from 'pinia';

import { classroomRestService, rostersRestService } from '@/rest';
import { GameLimitOption } from '@/site-pages/classroom/gaming/config';

import { StudentDetailsModel } from '@/types';
import { StudentDetailsState } from './types';
import router from '@/router';

export const useStudentDetailsStore = defineStore('student-details', {
    state: (): StudentDetailsState => ({
        isLoading: false,
        isSaving: false,
        origin: null,
        current: {
            edlinkId: null,
            email: null,
            gameMoney: 0,
            generalGameTimeLimitMinutes: GameLimitOption.MIN_10,
            grade: null,
            id: 0,
            intake: null,
            name: '',
            password: null,
            phone: null,
            schoolOrDistrictId: null,
            surname: '',
            username: '',
            uuid: '',
            lexileLevel: 0,
        },
    }),

    actions: {
        async fetchStudent(userId: number) {
            this.isLoading = true;

            try {
                const { data: user } = await classroomRestService.getClassUserById(userId);

                this.origin = {
                    ...user,
                };
                this.current = {
                    ...user,
                };
            } catch (error) {
                router.replace({ name: 'not-found' });
            } finally {
                this.isLoading = false;
            }
        },

        async saveStudent(user: Partial<StudentDetailsModel>): Promise<void> {
            this.isSaving = true;

            try {
                await classroomRestService.updateClassUser(user);
            } finally {
                this.isSaving = false;
            }
        },

        async validateUsername(username: string) {
            const { data: isAvailable } = await rostersRestService.isUsernameAvailable(username);

            return isAvailable;
        },
    },
});
