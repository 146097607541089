
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LUList extends Vue {
    render(createElement) {
        const emptySlot = createElement(
            'div',
            {
                class: 'lu-list__empty'
            },
            [this.$slots.empty || 'No items.']
        );

        return createElement(
            'div',
            {
                class: 'lu-list'
            },
            [this.$slots.default || emptySlot]
        );
    }
}
