import { ReportQuizzes, ReportRequestModel, ReportResultModel, TotalReportData, ReportSkills } from '@/types/reports';

import { ReportFilters } from '@/types/reports';
import { LUSelectOption } from '@/site-pages/reports/model/filter-model';

export interface ReportState {
    bookQuizzes: Array<ReportQuizzes>;
    reportRequestModel: ReportRequestModel;
    isLoading: boolean;
    studentPhonicsReport: object;
    filters: ReportFilters;
    reportModel: ReportResultModel;
    grades: LUSelectOption[];
    totalReportData: TotalReportData;
    totalSkills: ReportSkills[];
}

export enum ReportGetterNames {
    filters = 'filters',
    getBookQuizzes = 'getBookQuizzes',
    getReportRequest = 'getReportRequest',
    isLoading = 'isLoading',
    studentPhonicsReport = 'studentPhonicsReport',
    reportModel = 'reportModel',
    grades = 'grades',
    totalReportData = 'totalReportData',
    totalDistrictReportData = 'totalDistrictReportData',
    totalDistrictReportDataWithoutId = 'totalDistrictReportDataWithoutId',
    totalSkills = 'totalSkills',
}

export enum ReportActionNames {
    getStudentSkillOrStandard = 'getStudentSkillOrStandard',
    fetchStudentPhonicsReport = 'fetchStudentPhonicsReport',
    saveActivityReportCSV = 'saveActivityReportCSV',
    getSchoolReport = 'getSchoolReport',
    getDistrictReport = 'getDistrictReport',
    getClassRoomReport = 'getClassRoomReport',
    getMultiDistrictReport = 'getMultiDistrictReport',
    getGrades = 'getGrades',
    getTotalReportData = 'getTotalReportData',
    getTotalDistrictReportData = 'getTotalDistrictReportData',
    getMultiDistrictTotalSkills = 'getMultiDistrictSkills',
    getDistrictTotalSkills = 'getDistrictTotalSkills',
}

export enum ReportMutationNames {
    setBookQuizzes = 'setBookQuizzes',
    clearBookQuizzes = 'clearBookQuizzes',
    setReportRequestModel = 'setReportRequestModel',
    clearReportRequestModel = 'clearReportRequestModel',
    setStudentPhonicsReport = 'setStudentPhonicsReport',
    setLoading = 'setLoading',
    setFilters = 'setFilters',
    setReportData = 'setReportData',
    resetReportData = 'resetReportData',
    setTeacherReportData = 'setTeacherReportData',
    resetTeacherReportData = 'resetTeacherReportData',
    setGrades = 'setGrades',
    reset = 'RESET',
    setTotalReportData = 'setTotalReportData',
    resetTotalReportData = 'resetTotalReportData',
    setTotalSkills = 'setTotalSkills',
    resetTotalSkills = 'resetTotalSkills',
    resetFilters = 'resetFilters',
    resetGroupIds = 'resetGroupIds',
}

export enum ReportExternals {
    resetFilters = 'reports/resetFilters',
}

export const reportNamespace = 'reports';
