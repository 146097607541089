
import { Component, Prop } from 'vue-property-decorator';

import FormFieldMixin from '@/mixins/FormFieldMixin.vue';

@Component({
    inheritAttrs: false
})
export default class LuTextInput extends FormFieldMixin {
    @Prop({
        default: '',
        type: String
    })
    type!: string;
}
