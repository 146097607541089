import { defineStore } from 'pinia';

import { LexileTestLevel } from '@/constants';
import { classroomRestService } from '@/rest';
import { sortByUserName } from '@/utils';

import type { AssessmentSetting } from '@/types';
import type { AssessmentSettingsState } from './types';

import { useClassroomSwitcherStore } from '@/store';

export const useAssessmentSettingsStore = defineStore('assessment-settings', {
    state: (): AssessmentSettingsState => ({
        isLoading: false,
        isProcessing: false,
        settings: [],
    }),

    actions: {
        async fetchSettings() {
            this.isLoading = true;

            try {
                const {
                    data: { users: assessmentSettings },
                } = await classroomRestService.getAssessmentSettings();

                this.setSettings(assessmentSettings);
            } finally {
                this.isLoading = false;
            }
        },

        async triggerProgressMonitoring(studentId: number) {
            this.isProcessing = true;
            try {
                await classroomRestService.triggerProgressAssessment(studentId);

                this.updateSettings({
                    id: studentId,
                    currentAssessment: LexileTestLevel.TESTLET,
                });
            } finally {
                this.isProcessing = false;
            }
        },

        async triggerNewInTake(studentId: number) {
            this.isProcessing = true;
            try {
                await classroomRestService.triggerInTakeAssessment(studentId);

                this.updateSettings({
                    id: studentId,
                    currentAssessment: LexileTestLevel.INTAKE,
                });
            } finally {
                this.isProcessing = false;
            }
        },

        async disableAssessments({ id: userId }: AssessmentSetting) {
            const { data: updatedSettings }: { data: AssessmentSetting } =
                await classroomRestService.updateAssessmentSettings({
                    userId,
                    assessmentEnabled: false,
                });

            this.updateSettings(updatedSettings);
        },

        async enableAssessments({ id: userId }: AssessmentSetting) {
            const { data: updatedSettings }: { data: AssessmentSetting } =
                await classroomRestService.updateAssessmentSettings({
                    userId,
                    assessmentEnabled: true,
                });
            this.updateSettings(updatedSettings);
        },

        async bulkDisableAssessments(settings: AssessmentSetting[]) {
            const { data: updatedSettings }: { data: AssessmentSetting[] } =
                await classroomRestService.bulkUpdateAssessmentSettings(
                    settings.map((entry) => ({
                        userId: entry.id,
                        assessmentEnabled: false,
                    }))
                );
            this.bulkUpdateSettings(updatedSettings);
        },

        async bulkEnableAssessments(settings: AssessmentSetting[]) {
            const { data: updatedSettings }: { data: AssessmentSetting[] } =
                await classroomRestService.bulkUpdateAssessmentSettings(
                    settings.map((entry) => ({
                        userId: entry.id,
                        assessmentEnabled: true,
                    }))
                );

            this.bulkUpdateSettings(updatedSettings);
        },

        setSettings(newSettings: AssessmentSetting[]) {
            this.settings = newSettings;
        },

        updateSettings(newSettings: Partial<AssessmentSetting>) {
            const { id, ...restSettings } = newSettings;
            const index = this.settings.findIndex((entry) => entry.id === id);

            this.settings = [
                ...this.settings.slice(0, index),
                {
                    ...this.settings[index],
                    ...restSettings,
                },
                ...this.settings.slice(index + 1),
            ];
        },

        bulkUpdateSettings(settings: AssessmentSetting[]) {
            settings.forEach((entry) => {
                this.updateSettings(entry);
            });
        },
    },

    getters: {
        assessmentSettings: (state: AssessmentSettingsState): AssessmentSetting[] => {
            const classroomSwitcherStore = useClassroomSwitcherStore();
            if (classroomSwitcherStore.selectedStudentIds.length) {
                return sortByUserName(state.settings).filter((setting) => {
                    return classroomSwitcherStore.selectedStudentIds.includes(setting.id);
                });
            } else {
                return sortByUserName(state.settings);
            }
        },
    },
});
