
import { directive as onClickaway } from 'vue-clickaway';
import { Component, Vue, Watch } from 'vue-property-decorator';

import { formatBookTitle } from '@/filters';
import { SearchRoute } from '@/router/routes/search';
import { useAuth, useSearchAutocompleteStore, useSearchFiltersStore, useSearchStore } from '@/store';
import { FilterQueryParam, FiltersModel } from '@/types';
import { handleNavigationDuplicated } from '@/utils';

import type { AutocompleteModel } from '@/types';
import { InterfaceType } from '@/store/auth/types';

const MIN_SEARCH_TEXT_LENGTH = 3;
const AUTOCOMPLETE_DELAY = 1000;

@Component({
    directives: {
        onClickaway,
    },
})
export default class SearchInput extends Vue {
    auth = useAuth();
    autocompleteStore = useSearchAutocompleteStore();
    searchFiltersStore = useSearchFiltersStore();
    store = useSearchStore();

    showList: boolean = false;
    timeout: number | undefined = undefined;

    @Watch('searchFiltersStore.query')
    onQueryChange() {
        this.autocompleteStore.$reset();
    }

    created() {
        this.searchText = (this.$route.query[FilterQueryParam.searchQuery] as string) || '';

        if (!this.isSearchTextEmpty) {
            this.autocompleteStore.fetchResults(this.normalizeSearchQuery);
        }
    }

    beforeDestroy() {
        this.autocompleteStore.$reset();
    }

    get hasItems(): boolean {
        return !!this.autocompleteStore.results.length;
    }

    get shouldShowVariants(): boolean {
        return this.showList && this.hasItems;
    }

    get insideSearchAvailable(): boolean {
        return this.searchFiltersStore.selectedFilters.insideSearchAvailable;
    }

    get placeholderText(): string {
        if (this.auth.hasStudentRole) {
            return 'What do you want to read about?';
        } else {
            return 'Search by title, subtitle or category';
        }
    }

    get searchText(): string {
        return this.searchFiltersStore.query || '';
    }

    set searchText(query: string) {
        this.searchFiltersStore.setQuery(query);
    }

    get selectedFilters() {
        return this.searchFiltersStore.selectedFilters;
    }

    get notEmptyAutocompleteVariants(): AutocompleteModel[] {
        return this.autocompleteStore.results.filter((variant) => variant.title);
    }

    get isValidInput(): boolean {
        const searchText = this.normalizeSearchQuery as string;

        return /^\d+$/.test(searchText) || searchText.length >= MIN_SEARCH_TEXT_LENGTH;
    }

    get isSearchTextEmpty() {
        const searchText = this.searchText as string;

        return searchText.length === 0;
    }

    get shouldHighlightSearch() {
        return this.isSearchActive || this.$route.name === 'search';
    }

    get normalizeSearchQuery(): string {
        return this.searchText.replace(/;|%|\/|\\/gi, '');
    }

    get isSearchActive() {
        return this.store.isActive;
    }

    get studentInterfaceType(): string {
        if (this.auth.hasStudentRole) {
            if (this.auth.interfaceType === InterfaceType.NEXT) {
                return 'search-input_student-next';
            } else {
                return 'search-input_student';
            }
        }

        return '';
    }

    get isStudentNextInterfaceType(): boolean {
        return this.auth.hasStudentRole && this.auth.interfaceType !== InterfaceType.NEXT;
    }

    clearSearch() {
        this.searchText = '';
        this.updateSearchProperty();
        this.updateFilters({ type: null, searchQuery: this.searchText });

        if (this.$route.name === SearchRoute.SEARCH) {
            this.goSearch();
        }
    }

    updateFilters(filters: Partial<FiltersModel>) {
        this.searchFiltersStore.setSelectedFilters({ ...this.selectedFilters, ...filters });
    }

    goSearch() {
        this.hideAutoCompleteResults();
        this.$router
            .push({
                name: SearchRoute.SEARCH,
                query: {
                    ...this.$route.query,
                    [FilterQueryParam.searchQuery]: this.searchText || undefined,
                    [FilterQueryParam.type]: this.selectedFilters.type ? String(this.selectedFilters.type) : undefined,
                    page: undefined,
                    [FilterQueryParam.insideSearchAvailable]: String(this.selectedFilters.insideSearchAvailable),
                },
            })
            .catch(handleNavigationDuplicated);
    }

    onSearchSubmit() {
        if (this.isValidInput || this.searchText === '') {
            this.updateFilters({ type: null, searchQuery: this.searchText } as FiltersModel);
            this.goSearch();
        }
    }

    loadSelectedBook(item: AutocompleteModel) {
        this.searchText = item.title;
        this.updateFilters({ type: item.type, searchQuery: this.searchText } as FiltersModel);
        this.goSearch();
    }

    updateSearchProperty() {
        this.hideAutoCompleteResults();
        clearTimeout(this.timeout);

        this.timeout = window.setTimeout(async () => {
            this.loadAutoCompleteVariants();
        }, AUTOCOMPLETE_DELAY);
    }

    async loadAutoCompleteVariants() {
        this.autocompleteStore.$reset();

        if (this.isValidInput) {
            await this.autocompleteStore.fetchResults(this.normalizeSearchQuery);
            this.showAutoCompleteResults();
        }
    }

    hideAutoCompleteResults() {
        setTimeout(() => (this.showList = false), 200);
    }

    showAutoCompleteResults() {
        if (!this.insideSearchAvailable) this.showList = true;
    }

    onFocus() {
        this.store.setActive(true);
        this.showAutoCompleteResults();
    }

    getTitle(item: AutocompleteModel) {
        return formatBookTitle(item);
    }
}
