import { watch, ref, computed } from 'vue';

import CollectionIcon from '@/assets/my-collection.svg';
import LevelUpGameIcon from '@/assets/level-up-now.svg';
import LevelUpGameDeactivatedIcon from '@/assets/game-deactivated.svg';
import ReportIcon from '@/assets/report.svg';
import { useGameStore, useLexilePolling, useAuth } from '@/store';
import { GameAccessStatus } from '@/store/game/types';
import { InterfaceType } from '@/store/auth/types';

export default function useStudentMenuItems() {
    const auth = useAuth();

    const gameStore = useGameStore();
    const lexilePolling = useLexilePolling();

    const isLocked = ref<boolean>(true);
    const timeIsOver = ref<boolean>(false);
    const hasUpdates = ref<boolean>(false);
    const gameIcon = ref<string>(LevelUpGameIcon);
    const gameUrl = ref<string>('/game');

    const isStudentNextInterfaceType = computed(() => {
        return auth.hasStudentRole && auth.interfaceType === InterfaceType.NEXT;
    });

    watch(
        () => gameStore.gameAccessStatus,
        (newValue: GameAccessStatus | null) => {
            isLocked.value = newValue === null || newValue === GameAccessStatus.NOT_ACCESS;
            gameIcon.value =
                isLocked.value && !isStudentNextInterfaceType.value ? LevelUpGameDeactivatedIcon : LevelUpGameIcon;
            (gameUrl.value = isLocked.value ? '' : '/game'),
                (timeIsOver.value = newValue === GameAccessStatus.TIME_IS_OVER);
        },
        { immediate: true }
    );

    watch(
        () => lexilePolling.newLexileAvailable,
        (newValue: boolean) => (hasUpdates.value = newValue)
    );

    const menu = computed(() => {
        return [
            {
                id: 'menu-collection-tab',
                icon: CollectionIcon,
                name: 'My Favorites',
                url: '/collection',
            },
            {
                id: 'menu-student-report-tab',
                icon: ReportIcon,
                hasUpdates: hasUpdates.value,
                name: 'My Report',
                url: '/report',
            },
            {
                id: 'menu-game-tab',
                icon: gameIcon.value,
                name: 'LevelUp Land',
                url: gameUrl.value,
                isLocked: isLocked.value,
                notEnoughTime: timeIsOver.value,
            },
        ];
    });

    return menu;
}
