import { format, parseISO } from 'date-fns';

export const formattedDate = (value: string | Date | null = '', pattern: string = 'M/d/yy'): string => {
    if (!value) {
        return '';
    }

    if (value instanceof Date) {
        return format(value, pattern);
    }

    return format(parseISO(value), pattern);
};

export const formattedDateRange = (
    value: { start: Date | string; end: Date | string },
    pattern: string = 'M/d/yy'
): string => {
    return value.start && value.end
        ? `${formattedDate(value.start, pattern)} – ${formattedDate(value.end, pattern)}`
        : '';
};

export const formattedBeautyDate = (value: Date | string = ''): string => {
    return formattedDate(value, 'MMM dd, yyyy');
};
