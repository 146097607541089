import { defineStore } from 'pinia';

import { collectionRestService } from '@/rest';

import type { BookModel, TagModel } from '@/types';
import type { CollectionState } from './types';

export const useCollectionStore = defineStore('collection', {
    state: (): CollectionState => ({
        books: [],
        collection: new Set(),
        isLoading: false,
        isAddingBookIntoCollection: false,
        isRemovingBookFromCollection: false,
    }),

    actions: {
        async fetchCollection(tags?: TagModel[]) {
            this.isLoading = true;

            try {
                const { data: books } = await collectionRestService.getCollection(tags);

                this.collection = new Set(books.map((book: BookModel) => book.idNumber));
                this.setBooks(books);
            } finally {
                this.isLoading = false;
            }
        },

        setBooks(books: BookModel[]) {
            this.books = books;
        },

        addBooksToCollection(books: BookModel[]) {
            this.collection = new Set([...this.collection, ...books.map((book: BookModel) => book.idNumber)]);
        },

        async addBookIntoCollection(bookIdNumber: string) {
            this.isAddingBookIntoCollection = true;

            try {
                await collectionRestService.addBookIntoCollection(bookIdNumber);

                this.collection = new Set([...this.collection.add(bookIdNumber)]);
            } finally {
                this.isAddingBookIntoCollection = false;
            }
        },

        async removeBookFromCollection(bookIdNumber: string) {
            this.isRemovingBookFromCollection = true;

            try {
                await collectionRestService.removeBookFromCollection(bookIdNumber);

                this.collection.delete(bookIdNumber);
                this.collection = new Set([...this.collection]);
            } finally {
                this.isRemovingBookFromCollection = false;
            }
        },
    },

    getters: {
        booksInCollection(): BookModel[] {
            return this.books
                .filter((book) => this.isInCollection(book.idNumber))
                .sort((a: BookModel, b: BookModel) => {
                    const aTitle = [a.titlePrefix, a.title].filter(Boolean).join('');
                    const bTitle = [b.titlePrefix, b.title].filter(Boolean).join('');
                    return aTitle.localeCompare(bTitle);
                });
        },

        isInCollection: (state) => (bookId: string) => state.collection.has(bookId),
    },
});
