
import { Component, Prop, Vue } from 'vue-property-decorator';

import LURadio from './LURadio.vue';

export interface RadioGroupOption {
    disabled?: boolean;
    name: string;
    value: string | number | boolean | null;
}

@Component({
    components: {
        LURadio
    }
})
export default class RadioGroup extends Vue {
    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop()
    name!: string;

    @Prop()
    options!: [];

    @Prop()
    value!: [] | null;

    get model() {
        return this.value;
    }

    set model(value) {
        this.$emit('input', value);
    }
}
