import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export type Range = [number, number];

@Component
export default class SliderFilterMixin extends Vue {
    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop()
    max!: number;

    @Prop()
    min!: number;

    @Prop()
    value!: Range;

    model: Range = [this.value[0] ?? this.min, this.value[1] ?? this.max];

    @Watch('value')
    onValueChange(newValue: Range) {
        this.model = newValue;
    }

    update() {
        this.$emit('input', this.model);
    }
}
