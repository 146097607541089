export interface GameState {
    gameAccessStatus: GameAccessStatus | null;
}

export enum GameActionNames {
    gameAccessCheck = 'gameAccessCheck',
}

export enum GameMutationNames {
    setAccessible = 'setAccessible',
    reset = 'RESET',
}

export enum GameGetterNames {
    isGameAccessible = 'isGameAccessible',
}

export enum GameAccessStatus {
    NOT_ACCESS = 'NOT_ACCESS',
    TIME_IS_OVER = 'TIME_IS_OVER',
    ACCESS = 'ACCESS',
}

export const gameNamespace = 'game';
