import { defineStore } from 'pinia';

import { reportsRestService } from '@/rest';
import store from '@/store';
import { StudentReportState } from './types';

export const useStudentReportStore = defineStore('student-report', {
    state: (): StudentReportState => ({
        lexileLevel: 0,
        allTimeReport: {
            booksListenedTo: 0,
            lexileLevel: 0,
            booksRead: 0,
            pagesRead: 0,
            quizzesTaken: 0,
            starsEarned: 0,
            avgQuizPercent: 0,
            spentReadingTime: 0,
        },
        weekReport: {
            booksListenedTo: 0,
            lexileLevel: 0,
            booksRead: 0,
            pagesRead: 0,
            quizzesTaken: 0,
            starsEarned: 0,
            avgQuizPercent: 0,
            spentReadingTime: 0,
        },
    }),

    actions: {
        async fetchWeekReport() {
            store.dispatch('wait/start', 'studentReport.fetchingWeekReport', { root: true });

            try {
                const { data: report } = await reportsRestService.getStudentMyReport({ period: 7 });

                this.weekReport = report;
            } finally {
                store.dispatch('wait/end', 'studentReport.fetchingWeekReport', { root: true });
            }
        },

        async fetchAllTimeReport() {
            store.dispatch('wait/start', 'studentReport.fetchingAllTimeReport', { root: true });

            try {
                const { data: report } = await reportsRestService.getStudentMyReport();

                this.allTimeReport = report;
                this.lexileLevel = report.lexileLevel;
            } finally {
                store.dispatch('wait/end', 'studentReport.fetchingAllTimeReport', { root: true });
            }
        },
    },
});
