
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { compact, isEqual } from 'lodash';

import { formattedLexile } from '@/filters';
import { FiltersModel, ToggleFilter, FilterFlatItem, LelelUpTheme } from '@/types';

@Component
export default class AppliedFilters extends Vue {
    @InjectReactive('appTheme')
    theme!: LelelUpTheme;

    @Prop()
    filters!: FiltersModel;

    @Prop()
    selectedFilters!: FiltersModel;

    togglerFilterNames: Array<string> = [
        ToggleFilter.LEXILE_LEVEL_FILTER_NAME,
        ToggleFilter.PAGE_COUNT_FILTER_NAME,
        ToggleFilter.AUDIO_LENGTH_FILTER_NAME,
    ];

    getChipName(filter: FilterFlatItem) {
        const { filterName, value } = filter;

        if (filter.isCheckbox) {
            return filter.name;
        }

        if (Array.isArray(value)) {
            if (filterName === ToggleFilter.LEXILE_LEVEL_FILTER_NAME) {
                return value.map((lexileLevel) => formattedLexile(lexileLevel)).join('-');
            }

            if (filterName === ToggleFilter.PAGE_COUNT_FILTER_NAME) {
                return value.join('-') + ' Pages';
            }

            if (filterName === ToggleFilter.AUDIO_LENGTH_FILTER_NAME) {
                return value.join('-') + ' Minutes';
            }
        }
    }

    get hasAppliedFilters(): boolean {
        return this.selectedFiltersFlat.length > 0;
    }

    get selectedFiltersFlat(): Array<FilterFlatItem> {
        let filters = Object.keys(this.selectedFilters).map((filterName) => {
            const value = this.selectedFilters[filterName];

            if (this.isCheckboxFilter(filterName)) {
                return value.map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                        filterName,
                        isCheckbox: true,
                    };
                });
            }

            if (this.isToggleFilter(filterName) && value.length) {
                return {
                    value: this.selectedFilters[filterName],
                    filterName,
                    isCheckbox: false,
                };
            }
        });

        filters = compact(filters).reduce((a, b) => a.concat(b), []);

        return filters as Array<FilterFlatItem>;
    }

    removeFilter(filter) {
        const filterName = filter.filterName as string;
        const filterValue = filter.isCheckbox
            ? this.selectedFilters[filterName].filter((f) => f.id !== filter.id)
            : this.filters[filter.filterName];

        this.$emit('change', {
            ...this.selectedFilters,
            ...{ [filterName]: filterValue },
        });
    }

    isCheckboxFilter(filterName: string): boolean {
        const filter = this.selectedFilters[filterName];

        return (
            filter &&
            filter.length > 0 &&
            ![...this.togglerFilterNames, ToggleFilter.SEARCH_QUERY_NAME, ToggleFilter.SEARCH_TYPE].includes(filterName)
        );
    }

    isToggleFilter(filterName: string): boolean {
        return (
            this.togglerFilterNames.includes(filterName) &&
            !isEqual(this.selectedFilters[filterName], this.filters[filterName])
        );
    }

    reset() {
        this.$emit('reset');
    }
}
