import { ClassroomStudent, SchoolClassroom } from '@/site-pages/reports/model/group-report-model';
import { SkillBox } from '@/site-pages/reports/model/common-report-model';
import { ReportQuizzes, RespReportQuestion } from '@/types/reports';
import { DateRange, LUSelectOption } from '@/site-pages/reports/model/filter-model';

const TIMESTAMP_14_DAYS = 3600 * 1000 * 24 * 14;
const TIMESTAMP_30_DAYS = 3600 * 1000 * 24 * 30;
const TIMESTAMP_90_DAYS = 3600 * 1000 * 24 * 90;
const TIMESTAMP_365_DAYS = 3600 * 1000 * 24 * 365;

class ReportUtils {
    defaultGradeLevel = {
        value: 'all',
        title: 'All'
    };

    getPredefinedDatesAsOptions(): LUSelectOption[] {
        return [
            {
                value: {
                    start: new Date(Date.now() - TIMESTAMP_14_DAYS),
                    end: new Date()
                },
                title: 'Last 14 Days'
            },
            {
                value: {
                    start: new Date(Date.now() - TIMESTAMP_30_DAYS),
                    end: new Date()
                },
                title: 'Last 30 Days'
            },
            {
                value: {
                    start: new Date(Date.now() - TIMESTAMP_90_DAYS),
                    end: new Date()
                },
                title: 'Last 90 Days'
            },
            {
                value: {
                    start: new Date(Date.now() - TIMESTAMP_365_DAYS),
                    end: new Date()
                },
                title: 'Last Year'
            }
        ];
    }

    getLanguageOptions(): LUSelectOption[] {
        return [
            {
                value: 'all',
                title: 'All'
            },
            {
                value: 'English',
                title: 'English'
            },
            {
                value: 'Spanish',
                title: 'Spanish'
            },
            {
                value: 'Bilingual',
                title: 'Bilingual'
            }
        ];
    }

    getDefaultDateRange(): DateRange {
        return {
            start: new Date(Date.now() - TIMESTAMP_90_DAYS),
            end: new Date()
        };
    }

    getAvgQuizScore(correct: number, attempted: number): number {
        return attempted ? (correct * 100) / attempted : -1;
    }

    getAvgQuizScoreStudents(correct: number, attempted: number): number {
        return attempted ? (correct * 100) / attempted / 100 : -1;
    }

    getAvgQuizScoreForSkills(correct: number, attempted: number): number {
        return attempted ? Math.round((correct * 100) / attempted) : -1;
    }

    getSkillsFromRoster(roster: SchoolClassroom[] | ClassroomStudent[]): SkillBox[] {
        const skillsMap = new Map();

        roster.forEach(rosterItem => {
            rosterItem.skills.forEach((skill: SkillBox) => {
                const total = skillsMap.get(skill.id);

                if (total) {
                    skillsMap.set(skill.id, {
                        ...skill,
                        attempted: skill.attempted + total.attempted,
                        correct: skill.correct + total.correct
                    });
                } else {
                    skillsMap.set(skill.id, skill);
                }
            });
        });

        return [...skillsMap.values()];
    }

    getSkillsFromDistrict(skills: SkillBox[]): SkillBox[] {
        const skillsMap = new Map();

        skills.forEach((skill: SkillBox) => {
            const total = skillsMap.get(skill.id);

            if (total) {
                skillsMap.set(skill.id, {
                    ...skill,
                    attempted: skill.attempted + total.attempted,
                    correct: skill.correct + total.correct
                });
            } else {
                skillsMap.set(skill.id, skill);
            }
        });

        return [...skillsMap.values()];
    }

    getSkillsFromRosterWithoutDuplicate(
        roster: SchoolClassroom[] | ClassroomStudent[],
        studentsIds: number[],
        duplicatedStudents: ClassroomStudent[]
    ): SkillBox[] {
        const skillsMap = new Map();

        roster.forEach(rosterItem => {
            rosterItem.skills.forEach((skill: SkillBox) => {
                const total = skillsMap.get(skill.id);

                if (total) {
                    skillsMap.set(skill.id, {
                        ...skill,
                        attempted: skill.attempted + total.attempted,
                        correct: skill.correct + total.correct
                    });
                } else {
                    skillsMap.set(skill.id, skill);
                }
            });
        });

        if (duplicatedStudents.length > 0) {
            duplicatedStudents.forEach(st => {
                const count = reportUtils.getCount(studentsIds, st.id);
                if (count > 1) {
                    duplicatedStudents.forEach(student => {
                        student.skills.forEach((skill: SkillBox) => {
                            const total = skillsMap.get(skill.id);

                            if (total) {
                                skillsMap.set(skill.id, {
                                    ...skill,
                                    attempted: total.attempted - (count - 1) * skill.attempted,
                                    correct: total.correct - (count - 1) * skill.correct
                                });
                            }
                        });
                    });
                }
            });
        }

        return [...skillsMap.values()];
    }

    getCount(arr: number[], e: number | string): number {
        return arr.filter(function(item) {
            return item === e;
        }).length;
    }

    removeDuplicatedFromLexile(
        lexileLevel: number[],
        studentsIds: number[],
        duplicatedStudents: ClassroomStudent[]
    ): number[] {
        if (duplicatedStudents.length > 0) {
            duplicatedStudents.forEach(st => {
                const count = reportUtils.getCount(studentsIds, st.id);
                if (count > 1) {
                    const index = lexileLevel.indexOf(st.currentLexile);
                    if (index > -1) {
                        lexileLevel.splice(index, count - 1);
                    }
                }
            });
        }
        return lexileLevel;
    }

    removeDuplicatedFromAvgScore(
        avgScore: number[],
        studentsIds: number[],
        duplicatedStudents: ClassroomStudent[]
    ): number[] {
        if (duplicatedStudents.length > 0) {
            duplicatedStudents.forEach(st => {
                const count = reportUtils.getCount(studentsIds, st.id);
                if (count > 1) {
                    const index = avgScore.indexOf(st.avgQuizPercent);
                    if (index > -1) {
                        avgScore.splice(index, count - 1);
                    }
                }
            });
        }
        return avgScore;
    }

    makeBookQuizzes(bookQuiz: any[]): ReportQuizzes[] {
        return bookQuiz.map(b => {
            return {
                idNumber: b.idNumber,
                title: b.title,
                lexile: b.lexileWeight,
                lastAttempted: b.lastAttempted,
                multiQuestions: b.multiQuestions,
                respQuestions: b.respQuestions.map((question: RespReportQuestion) => {
                    return {
                        ...question,
                        answer: question.userAnswer
                    };
                })
            };
        });
    }
}

const reportUtils: ReportUtils = new ReportUtils();

export default reportUtils;
