import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';
import RunningRecordsLayoutNoHeader from '@/site-pages/running-records/layouts/RunningRecordsLayoutNoHeader.vue';

const runningRecordsRoutes: RouteConfig[] = [
    {
        path: '/running-records/:idNumber',
        name: 'runningRecords',
        component: () => import('@/site-pages/running-records/RunningRecords.vue'),
        meta: {
            layout: RunningRecordsLayoutNoHeader,
            forgetOnLogOut: true,
            roles: [Role.STUDENT],
        },
    },
];

export default runningRecordsRoutes;
