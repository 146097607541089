import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

import { HTTP_STATUS_CODE } from '@/constants';
import { runningRecordsRestService } from '@/rest';

import type { RunningRecordReview } from '@/types';
import type { RunningRecordReviewState } from './types';

export const useRunningRecordReview = defineStore('running-record-review', {
    state: (): RunningRecordReviewState => ({
        isFetching: false,
        runningRecord: null,
        review: null,
    }),

    actions: {
        async fetchRunningRecord(recordId: number) {
            this.isFetching = true;
            this.review = null;

            try {
                const { data: runningRecord } = await runningRecordsRestService.fetchRunningRecord(recordId);

                if (runningRecord.assignmentStatus === 'REVIEWED') {
                    await this.fetchRunningRecordReview(recordId);
                }

                this.runningRecord = runningRecord;
            } finally {
                this.isFetching = false;
            }
        },

        async fetchRunningRecordReview(recordId: number) {
            try {
                const { data: review } = await runningRecordsRestService.fetchRunningRecordReview(recordId);

                this.review = review;
            } catch (error) {
                if ((error as AxiosResponse)?.status !== HTTP_STATUS_CODE.NOT_FOUND) {
                    throw error;
                }
            }
        },

        async saveRunningRecordReview(review: RunningRecordReview) {
            const { data: updatedReview }: { data: RunningRecordReview } =
                await runningRecordsRestService.saveRunningRecordReview(review);

            this.review = updatedReview;
        },

        setRunningRecordReview(review: RunningRecordReview) {
            this.review = review;
        },
    },

    getters: {},
});
