import { defineStore } from 'pinia';

import { carouselRestService } from '@/rest';
import { useCollectionStore } from '../collection';

import type { BookModel, CarouselModel } from '@/types';
import type { BookshelfState } from './types';

export const useBookshelfStore = defineStore('bookshelf', {
    state: (): BookshelfState => ({
        isLoading: false,
        books: {},
        carousels: [],
        abortController: null,
    }),

    actions: {
        addCarousels(carousels: CarouselModel[]) {
            const existingCarouselIds = this.carousels.map((carousel) => carousel.id);

            this.carousels = this.carousels.concat(
                carousels.filter((carousel) => !existingCarouselIds.includes(carousel.id))
            );
        },

        addBooks(books: BookModel[]) {
            const newBooks = books.reduce((acc, book) => {
                acc[book.idNumber] = book;
                return acc;
            }, {});

            this.books = {
                ...this.books,
                ...newBooks,
            };
        },

        async fetchCarousels() {
            const collectionStore = useCollectionStore();

            this.isLoading = true;

            try {
                if (!this.abortController) {
                    this.abortController = new AbortController();
                }

                const { data: carousels } = await carouselRestService.getAllCarousels({
                    signal: this.abortController.signal,
                });

                const books = carousels.reduce(
                    (acc: BookModel[], carousel: CarouselModel) => acc.concat(carousel.books),
                    []
                );

                this.addBooks(books);
                this.addCarousels(carousels);
                collectionStore.addBooksToCollection(books.filter((book: BookModel) => book.inCollection));
            } finally {
                this.isLoading = false;
                this.abortController = null;
            }
        },

        reset() {
            this.carousels = [];

            if (this.abortController) {
                this.abortController.abort('Cancel previous requests.');
                this.abortController = null;
            }
        },
    },

    getters: {
        getBooks: (state: BookshelfState): BookModel[] => Object.values(state.books),
    },
});
