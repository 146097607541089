import { MutationTree } from 'vuex';

import reportUtils from '@/utils/reports-utils';
import { GradeLevel, ReportFilters, ReportQuizzes, ReportRequestModel } from '@/types';
import { LUSelectOption } from '@/site-pages/reports/model/filter-model';
import { ReportState, ReportMutationNames } from './types';
import { initialState } from './';
import { orderBy } from 'lodash';

const mutations: MutationTree<ReportState> = {
    [ReportMutationNames.setBookQuizzes]: (state: ReportState, bookQuizzes: ReportQuizzes[]) => {
        state.bookQuizzes = bookQuizzes;
    },

    [ReportMutationNames.clearBookQuizzes]: (state: ReportState) => {
        state.bookQuizzes = [];
    },

    [ReportMutationNames.setReportRequestModel]: (state: ReportState, reportRequestModel: ReportRequestModel) => {
        state.reportRequestModel = reportRequestModel;
    },

    [ReportMutationNames.clearReportRequestModel]: (state: ReportState) => {
        state.reportRequestModel = {} as ReportRequestModel;
    },

    [ReportMutationNames.setLoading]: (state: ReportState, isLoading: boolean) => {
        state.isLoading = isLoading;
    },

    [ReportMutationNames.setStudentPhonicsReport]: (state: ReportState, report: object) => {
        state.studentPhonicsReport = report;
    },

    [ReportMutationNames.setFilters]: (state: ReportState, filters: ReportFilters) => {
        state.filters = filters;
    },

    [ReportMutationNames.resetFilters]: (state: ReportState) => {
        state.filters = initialState().filters;
    },

    [ReportMutationNames.resetGroupIds]: (state: ReportState) => {
        state.filters.groupIds = initialState().filters.groupIds;
    },

    [ReportMutationNames.resetReportData]: (state: ReportState) => {
        const reportModel = initialState().reportModel;
        state.reportModel = reportModel;
    },

    [ReportMutationNames.resetTotalReportData]: (state: ReportState) => {
        state.totalReportData = initialState().totalReportData;
    },

    [ReportMutationNames.setReportData]: (state: ReportState, data) => {
        const {
            id,
            name,
            classes,
            parentGroup,
            parentGroups,
            countStudents,
            students,
            districts,
            duplicatedStudents,
            avgQuizScore,
            readingGroups,
        } = data;

        state.reportModel.id = id;
        state.reportModel.name = name;
        state.reportModel.classrooms = classes || [];

        if (parentGroup) {
            state.reportModel.parentGroups = [parentGroup];
        }

        if (parentGroups) {
            state.reportModel.parentGroups = parentGroups;
        }

        if (!state.reportModel.groups.length) {
            const groupList = readingGroups ? readingGroups : state.reportModel.classrooms;

            state.reportModel.groups = groupList.map(({ id, name }) => ({
                id,
                name,
            }));
        }
        state.reportModel.countStudents = countStudents;
        state.reportModel.students = students || [];
        state.reportModel.districts = districts || [];
        state.reportModel.duplicatedStudents = duplicatedStudents || [];
        state.reportModel.avgQuizScore = avgQuizScore || [];
    },

    [ReportMutationNames.resetReportData]: (state: ReportState) => {
        const reportModel = initialState().reportModel;
        state.reportModel = reportModel;
    },

    [ReportMutationNames.setTeacherReportData]: (state: ReportState, data) => {
        const { classActivityDto, studentReports, classRooms, readingGroups } = data;

        const names = data.reportClassRooms.reduce((result: any[], classRoom: any) => [...result, classRoom.name], []);
        state.reportModel.names = orderBy(names, (name) => name.toLocaleLowerCase());

        state.reportModel.parentGroups = data.reportClassRooms.length ? data.reportClassRooms[0].parentGroups : [];

        state.reportModel.teacherClassrooms = classRooms;
        state.reportModel.groups = readingGroups;
        state.reportModel.classActivityDto = classActivityDto;
        state.reportModel.studentReports = studentReports;

        state.reportModel.hasOneClassroom = classRooms.length === 1;
        state.reportModel.id = state.reportModel.hasOneClassroom ? data.reportClassRooms[0].id : null;
    },

    [ReportMutationNames.setTotalReportData]: (state: ReportState, data) => {
        const {
            booksRead,
            timeSpentReading,
            booksListenedTo,
            avgQuizPercent,
            quizzesTaken,
            countStudents,
            progress,
            avgGrowth,
        } = data;
        state.totalReportData.booksRead = booksRead;
        state.totalReportData.timeSpentReading = timeSpentReading;
        state.totalReportData.booksListenedTo = booksListenedTo;
        state.totalReportData.countStudents = countStudents;
        state.totalReportData.avgQuizPercent = avgQuizPercent;
        state.totalReportData.quizzesTaken = quizzesTaken;
        state.totalReportData.progress = progress || [];
        state.totalReportData.avgGrowth = avgGrowth;
    },

    [ReportMutationNames.resetTotalSkills]: (state: ReportState) => {
        state.totalSkills = initialState().totalSkills;
    },

    [ReportMutationNames.setTotalSkills]: (state: ReportState, data) => {
        state.totalSkills = data;
    },

    [ReportMutationNames.setGrades]: (state: ReportState, grades: []) => {
        const gradeValues: LUSelectOption[] = grades.map((grade) => {
            return {
                value: grade,
                title: GradeLevel[grade],
            };
        });
        state.grades = [reportUtils.defaultGradeLevel, ...gradeValues];
    },

    [ReportMutationNames.reset]: (state: ReportState) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

export default mutations;
