import Cookies from 'js-cookie';

const ColibrioCookiesHelper = {
    setBookLocationCookie(cookieValue: string): void {
        this._setCookie('BLOCATION', cookieValue);
    },

    setBookIdCookie(cookieValue: string): void {
        this._setCookie('BID', cookieValue);
    },

    getBookLocationCookie(): string {
        const lastBookLocation = this._getCookie('BLOCATION');
        return lastBookLocation ? lastBookLocation[1] : '';
    },

    getBookIdCookie(): string {
        const lastBookId = this._getCookie('BID');
        return lastBookId ? lastBookId[1] : '';
    },

    deleteCookies(): void {
        Cookies.remove('BLOCATION');
        Cookies.remove('BID');
    },

    _setCookie(cookieName: string, cookieValue: string): void {
        Cookies.set(cookieName, cookieValue, {
            secure: true,
            SameSite: 'None;',
        });
    },

    _getCookie(cookieName: string): RegExpMatchArray | null {
        return Cookies.get(cookieName);
    },
};

export { ColibrioCookiesHelper };
