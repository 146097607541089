import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';
import searchRouteGuard from '@/router/guards/search-guard';

export enum SearchRoute {
    SEARCH = 'search',
}

const SearchView = () => import(/* webpackChunkName: "search" */ '@/site-pages/search/SearchView.vue');

const searchRoutes: RouteConfig[] = [
    {
        path: '/search',
        name: SearchRoute.SEARCH,
        beforeEnter: searchRouteGuard,
        component: SearchView,
        props: (route) => ({ query: route.query.q }),
        meta: {
            forgetOnLogOut: true,
            roles: [Role.STUDENT, Role.TEACHER],
            title: 'Search Results',
        },
    },
];

export default searchRoutes;
