export class ValidationException extends Error {
    constructor(public errors: Error[], message = 'Validation Failed') {
        super(message);
        this.name = 'Validation Exception';
        this.errors = errors;
    }
}

export class ApiException extends Error {
    public data: {
        error?: string;
        errorStatus?: number;
        userMessage?: string;
        message?: string;
    };

    constructor(data: { userMessage?: string; message?: string; error?: string }) {
        super(data.userMessage);

        this.name = 'API Ecxeption';
        this.data = data;
    }
}

export default { ValidationException };
