import { defineStore } from 'pinia';
import { format, parseISO } from 'date-fns';

import { BULK_ASSIGNMENT_FILTER } from '@/constants';
import { assignRestService, reportsRestService, rostersRestService } from '@/rest';

import type { BookModel, Grade, RosterFilters } from '@/types';
import type { BulkAssignmentState } from './types';

enum BulkAssignmentAbortSignal {
    GROUP_STUDENTS = 'group-students-abort',
}

export const useAssignmentWizardStore = defineStore('assignment-wizard', {
    state: (): BulkAssignmentState => ({
        currentStepIndex: 0,
        displayDate: format(new Date(), 'yyyy-MM-dd'),
        dueDate: null,
        groups: [],
        rosterFilters: {
            gradeLevel: [],
            lexileRange: [-1000, 2000],
        },
        books: [],
        groupIds: [],
        studentIds: new Set(),
        activeLexile: false,
        grades: [],
        abortControllers: {},
    }),

    actions: {
        goToStep(stepIndex: number) {
            this.currentStepIndex = stepIndex;
        },

        setBooks(books: BookModel[]) {
            this.books = books;
        },

        setDisplayDate(value: Date | null) {
            this.displayDate = value ? format(value, 'yyyy-MM-dd') : null;
        },

        setDueDate(value: Date | null) {
            this.dueDate = value ? format(value, 'yyyy-MM-dd') : null;
        },

        setGroups(groups: []) {
            this.groups = groups;
        },

        setGrades(grades: Grade[]) {
            this.grades = grades;
        },

        setRosterFilter(filter: Partial<RosterFilters>) {
            this.rosterFilters = {
                ...this.rosterFilters,
                ...filter,
            };
            this.fetchGroupStudents();
        },

        setStudentIds(studentIds: number[]) {
            this.studentIds = new Set(studentIds);
        },

        setGroupIds(groupIds: number[]) {
            this.groupIds = groupIds;
        },

        async assignBooks() {
            const {
                activeLexile,
                bookIdNumbers: books,
                displayDate,
                dueDate,
                rosterFilters: {
                    lexileRange: [lowerLexile, upperLexile],
                    gradeLevel: grades,
                },
                selectedStudentIds: students,
            } = this;

            const params = {
                books,
                displayDate,
                dueDate,
                grades,
                students,
                ...(activeLexile
                    ? {
                          lowerLexile,
                          upperLexile,
                      }
                    : {}),
            };

            await assignRestService.bulkAssign(params);
        },

        async fetchGroupStudents() {
            const {
                rosterFilters: { gradeLevel: grades, lexileRange },
                groupIds: groups,
                activeLexile,
            } = this;

            const [lowerLexile, upperLexile] = lexileRange;

            if (this.abortControllers[BulkAssignmentAbortSignal.GROUP_STUDENTS]) {
                this.cancelRequest(BulkAssignmentAbortSignal.GROUP_STUDENTS);
            }

            if (!groups.length) {
                this.setStudentIds([]);
                return;
            }

            try {
                const abortController = new AbortController();

                this.abortControllers = {
                    ...this.abortControllers,
                    [BulkAssignmentAbortSignal.GROUP_STUDENTS]: abortController,
                };

                const params = activeLexile
                    ? {
                          groups,
                          grades,
                          lowerLexile,
                          upperLexile,
                      }
                    : {
                          groups,
                          grades,
                      };
                const { data: studentIds } = await rostersRestService.getGroupStudents({
                    ...params,
                    signal: abortController.signal,
                });

                this.setStudentIds(studentIds);
            } finally {
                this.abortControllers = {
                    ...this.abortControllers,
                    [BulkAssignmentAbortSignal.GROUP_STUDENTS]: null,
                };
            }
        },

        async fetchGroups() {
            const { data: groups } = await rostersRestService.getGroups();

            this.setGroups(groups);
        },

        async fetchGrades(groupIds?: number[]) {
            const payload = { groupIds };
            const { data: grades } = await reportsRestService.getGrades(payload);

            this.setGrades(grades);
            this.setRosterFilter({
                [BULK_ASSIGNMENT_FILTER.GRADE_LEVEL]: grades,
            });
        },

        cancelRequest(requestId: string) {
            if (this.abortControllers[requestId]) {
                this.abortControllers[requestId]?.abort();

                this.abortControllers = {
                    ...this.abortControllers,
                    [requestId]: null,
                };
            }
        },
    },

    getters: {
        bookIdNumbers: (state: BulkAssignmentState): string[] => state.books.map((book) => book.idNumber),

        getDisplayDate: (state: BulkAssignmentState) => (state.displayDate ? parseISO(state.displayDate) : null),

        getDueDate: (state: BulkAssignmentState) => (state.dueDate ? parseISO(state.dueDate) : null),

        selectedStudentIds: (state: BulkAssignmentState): number[] => [...state.studentIds],
    },
});
