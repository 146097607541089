import { BookModel, Grade } from '@/types';

export interface AssignmentModel {
    book: {
        id: number;
        idNumber: string;
        title: string;
        titlePrefix: string;
        lexileCode: string;
        lexileWeight: number;
        bookGroupId: number;
    };
    completed: boolean;
    completedDate: string | null;
    dateAssigned: string;
    displayDate: string | null;
    dueDate: string | null;
    status: keyof typeof AssignmentStatus;
    type: `${AssignmentType}`;
    student: {
        id: number;
        name: string;
        surname: string;
        username: string;
    };
}

export interface GroupAssignment {
    assigned: boolean;
    id: number;
    name: string;
    studentIds: number[];
}

export interface StudentAssignment {
    assignDate: string | null;
    displayDate: string | null;
    completedDate: string | null;
    dueDate: string | null;
    id: number;
    lexileLevel: number | null;
    name: string;
    assigned: boolean;
    surname: string;
    username: string;
}

export interface StudentRunningRecordAssignment {
    userRunningRecordId?: number;
    assignDate: string | null;
    displayDate: string | null;
    completedDate: string | null;
    dueDate: string | null;
}

export interface StudentMultipleAssignment {
    studentId: number;
    name: string;
    surname: string;
    username: string;
    lexileLevel: number | null;
    assignments: StudentRunningRecordAssignment[];
}

export interface StudentAssignmentShort {
    id: number;
    displayDate: string | null;
    dueDate: string | null;
}

export interface IUpdateAssignment {
    id: number;
    idNumber: string;
    name: string;
    surname: string;
    username: string;
    assigned: boolean;
    dueDate: string | null;
    displayDate: string | null;
    type: string;
}

export interface BatchAssignment {
    force: boolean;
    displayDate: string | null;
    dueDate: string | null;
    studentsIds: number[];
}

export interface AssignmentLogEntryModel {
    assignDate: string;
    book: Partial<BookModel>;
    displayDate: string;
    dueDate: string | null;
    grades: Grade[];
    lowerLexile: number;
    numberOfStudents: number;
    upperLexile: number;
}

export enum AssignmentStatus {
    ASSIGNED = 'ASSIGNED',
    OVERDUE = 'OVERDUE',
    COMPLETED_ON_TIME = 'COMPLETED_ON_TIME',
    COMPLETED_LATE = 'COMPLETED_LATE',
}

export enum AssignmentFilterStatus {
    ALL = 'ALL',
    ALL_INCOMPLETE = 'ALL_INCOMPLETE',
    ALL_COMPLETE = 'ALL_COMPLETE',
    ASSIGNED = 'ASSIGNED',
    OVERDUE = 'OVERDUE',
    COMPLETED_ON_TIME = 'COMPLETED_ON_TIME',
    COMPLETED_LATE = 'COMPLETED_LATE',
}

export const AssignmentStatuses = {
    [AssignmentStatus.ASSIGNED]: 'Assigned',
    [AssignmentStatus.OVERDUE]: 'Overdue',
    [AssignmentStatus.COMPLETED_ON_TIME]: 'Completed On Time',
    [AssignmentStatus.COMPLETED_LATE]: 'Completed Late',
};

export enum AssignmentType {
    BOOK = 'book',
    RUNNING_RECORD = 'running_record',
}

export const AssignmentTypes = {
    [AssignmentType.BOOK]: 'Book',
    [AssignmentType.RUNNING_RECORD]: 'Running Record',
};
