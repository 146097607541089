import { defineStore } from 'pinia';

import { usersRestService } from '@/rest';

import type { PageRequest } from '@/rest';
import type { UsersState } from './types';

export const useUsersStore = defineStore('users', {
    state: (): UsersState => ({
        users: [],
        totalNumberOfUsers: 0,
    }),

    actions: {
        async fetchUsers(page: PageRequest) {
            const {
                data: { content: users, totalElements },
            } = await usersRestService.getAllPageableUsers(page);

            this.users = users;
            this.totalNumberOfUsers = totalElements;
        },
    },
});
