import { defineStore } from 'pinia';

import { classroomRestService } from '@/rest';
import { useClassroomRosterStore } from './roster';

import type { ReadingGroup, StudentModel, UserModel } from '@/types';
import type { ReadingGroupsState } from './types';

export const useReadingGroupsStore = defineStore('reading-groups', {
    state: (): ReadingGroupsState => ({
        classroomId: null,
        isLoading: false,
        isSaving: false,
        groups: new Map(),
    }),

    actions: {
        async fetchGroups() {
            const roster = useClassroomRosterStore();

            this.isLoading = true;

            try {
                const {
                    data: { id, subGroups, users },
                } = await classroomRestService.getClass();

                this.classroomId = id;
                this.setGroups(subGroups);

                roster.setRoster(users);
            } finally {
                this.isLoading = false;
            }
        },

        async createGroup({ name, students }: { name: string; students: StudentModel[] }) {
            if (!this.classroomId) {
                return;
            }

            this.isSaving = true;

            try {
                const { data: newGroup } = await classroomRestService.addGroup(name, this.classroomId, students);

                this.setGroup(newGroup);
            } finally {
                this.isSaving = false;
            }
        },

        async renameGroup({ groupId, groupName }: { groupId: number; groupName: string }) {
            this.isSaving = true;

            try {
                const { data: group } = await classroomRestService.renameGroup(groupId, groupName);
                this.setGroup(group);
            } finally {
                this.isSaving = false;
            }
        },

        async removeGroup(groupId: number) {
            this.isSaving = true;

            try {
                await classroomRestService.removeGroup(groupId);
                this.deleteGroup(groupId);
            } finally {
                this.isSaving = false;
            }
        },

        async updateGroup({
            selectedStudents,
            groupId,
        }: {
            selectedStudents: number[];
            groupId: number;
        }): Promise<void> {
            this.isSaving = true;

            const roster = useClassroomRosterStore();

            try {
                const selectedStudentsList = roster.students
                    .filter((student: StudentModel) => selectedStudents.includes(student.id))
                    .map((student: StudentModel): UserModel => {
                        const { id, name, surname, username } = student;

                        return {
                            id,
                            name,
                            surname,
                            username,
                        };
                    });

                const { data: group } = await classroomRestService.manageGroup(groupId, selectedStudentsList);

                this.setGroup(group);
            } finally {
                this.isSaving = false;
            }
        },

        setGroups(newGroups: ReadingGroup[]) {
            const groups = new Map();

            newGroups.forEach((group) => groups.set(group.id, group));

            this.groups = groups;
        },

        setGroup(group: ReadingGroup) {
            this.groups = new Map(this.groups.set(group.id, group));
        },

        deleteGroup(groupId: number) {
            const groups = new Map(this.groups);

            groups.delete(groupId);

            this.groups = groups;
        },
    },

    getters: {
        readingGroups: (state: ReadingGroupsState): ReadingGroup[] => {
            const roster = useClassroomRosterStore();

            let groups = [...state.groups.values()]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((group) => ({
                    ...group,
                    users: group.users.map(({ id }: StudentModel) => roster.roster.get(id)!),
                }));

            groups.forEach((group) => {
                group.users = group.users.filter((user) => user !== undefined);
            });
            groups = groups.filter((group) => group.users.length > 0);
            return groups;
        },

        studentGroups() {
            return (studentId: number) => {
                return this.readingGroups.filter((group: ReadingGroup) =>
                    group.users.find((student) => student.id === studentId)
                );
            };
        },
    },
});
