import type { AutocompleteModel, BookModel, FiltersModel, StudentModel } from '@/types';

export interface SearchParamsModel {
    size: number;
    last: boolean;
    page: number;
    totalPages: number;
    totalElements: number;
}

export interface SearchState {
    isActive: boolean;
    isLoading: boolean;
    params: SearchParamsModel;
    results: BookModel[];
    searchTypeStatus: SearchTypeStatus;
}

export enum SearchTypeStatus {
    SwitchedToInsideSearch,
    SwitchedToSearch,
}

export interface SearchAutocompleteState {
    isLoading: boolean;
    results: AutocompleteModel[];
}

export interface SearchFiltersState {
    filters: FiltersModel;
    selectedFilters: FiltersModel;
    query: string;
    isLoading: boolean;
}

export interface StudentSearchState {
    isLoading: boolean;
    students: StudentModel[];
}
