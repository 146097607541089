import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

let CSRF_TOKEN: string = '';

if (document && document.cookie && document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`))) {
    CSRF_TOKEN = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`))![1];
}

const AXIOS = axios.create({
    headers: { 'X-XSRF-TOKEN': CSRF_TOKEN },
});

axios.defaults.headers.common['X-XSRF-TOKEN'] = CSRF_TOKEN;
axios.defaults.withCredentials = true;

export default class BaseRestService {
    removeHeader() {
        axios.defaults.headers.common = {};
    }

    get(resource: string, config?: AxiosRequestConfig): AxiosPromise {
        return axios.get(resource, config);
    }

    head(resource: string, config?: AxiosRequestConfig): AxiosPromise {
        return axios.head(resource, config);
    }

    post(resource: string, data?, config?: AxiosRequestConfig): AxiosPromise {
        return axios.post(resource, data, config);
    }

    patch(resource: string, data): AxiosPromise {
        return axios.patch(resource, data);
    }

    put(resource: string, data, config?: AxiosRequestConfig): AxiosPromise {
        return axios.put(resource, data, config);
    }

    delete(resource: string, config?: AxiosRequestConfig): AxiosPromise {
        return axios.delete(resource, config);
    }

    shadowGet(resource: string, config?: AxiosRequestConfig): AxiosPromise {
        return this.get(resource, this.updateConfigWithNoLoading(config));
    }

    shadowPost(resource: string, data, config?: AxiosRequestConfig): AxiosPromise {
        return this.post(resource, data, this.updateConfigWithNoLoading(config));
    }

    private updateConfigWithNoLoading(config: AxiosRequestConfig | undefined) {
        return config
            ? {
                  ...config,
                  params: {
                      ...config.params,
                      noLoading: true,
                  },
              }
            : {
                  params: {
                      noLoading: true,
                  },
              };
    }

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data, config?: AxiosRequestConfig) {
        return AXIOS(data, config);
    }

    shadowCustomRequest(data) {
        data = {
            ...data,
            params: {
                ...data.params,
                noLoading: true,
            },
        };
        return this.customRequest(data);
    }

    cancelableCustomRequest(data) {
        const cancelSource = axios.CancelToken.source();
        data = {
            ...data,
            cancelToken: cancelSource.token,
        };
        return {
            promise: AXIOS(data),
            cancelSource,
        };
    }

    cancelableShadowCustomRequest(data) {
        const cancelSource = axios.CancelToken.source();
        data = {
            ...data,
            cancelToken: cancelSource.token,
        };
        return {
            promise: this.shadowCustomRequest(data),
            cancelSource,
        };
    }
}
