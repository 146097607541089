
import { Component, Prop } from 'vue-property-decorator';

import FormFieldMixin from '@/mixins/FormFieldMixin.vue';

@Component({
    inheritAttrs: false
})
export default class LuTextarea extends FormFieldMixin {
    @Prop({
        default: 5,
        type: Number
    })
    rows!: number;

    @Prop({
        default: '',
        type: String
    })
    type!: string;
}
