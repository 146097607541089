
import { Component, Mixins, Prop } from 'vue-property-decorator';

import SliderInput from '@/components/SliderInput.vue';
import SliderFilterMixin from '@/components/filter-menu/mixins/SliderFilterMixin';

const MERGE_TOOLTIP_DELTA = 15;

@Component({
    components: {
        SliderInput,
    },
})
export default class AudioLengthFilter extends Mixins(SliderFilterMixin) {
    @Prop({ default: 0, type: Number })
    min!: number;

    @Prop({ default: 0, type: Number })
    max!: number;

    get shouldMergeTooltip(): boolean {
        const [start, end] = this.model;

        return (end * 100) / this.max - (start * 100) / this.max < MERGE_TOOLTIP_DELTA;
    }

    rangeFormatter(range: number[]): string {
        const [start, end] = range;

        return start === end ? this.tooltipFormatter(start) : this.tooltipFormatter(`${start}—${end}`);
    }

    tooltipFormatter(value: number | string): string {
        return `${value} min`;
    }
}
