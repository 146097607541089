
import { Component, Vue } from 'vue-property-decorator';

import LUSelect, { LUSelectOption } from './LUSelect.vue';
import { GradeLevel, GRADES } from '@/types';

@Component({
    components: { LUSelect }
})
export default class GradePicker extends Vue {
    get grades(): LUSelectOption[] {
        return GRADES.map(grade => ({
            title: GradeLevel[grade],
            value: grade === 'NO_GL' ? null : grade
        }));
    }
}
