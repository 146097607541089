import { defineStore } from 'pinia';

import { assignRestService } from '@/rest';

import type { AssignmentLogEntryModel } from '@/types';
import type { AssignmentLogState } from './types';

export const useAssignmentLogStore = defineStore('assignment-log', {
    state: (): AssignmentLogState => ({
        isLoading: false,
        entries: [],
        params: {
            page: 0,
            size: 0,
            totalElements: 0,
            totalPages: 0,
        },
    }),

    actions: {
        async fetchLog(page: number = 0) {
            this.isLoading = true;

            try {
                const { data } = await assignRestService.getAssignmentLog(page);
                const { content: entries, size, totalElements } = data;

                this.setEntries(entries);
                this.setParams({
                    size,
                    totalElements,
                });
            } finally {
                this.isLoading = false;
            }
        },

        setEntries(entries: AssignmentLogEntryModel[]) {
            this.entries = entries;
        },

        setParams(params: Partial<AssignmentLogState['params']>) {
            this.params = {
                ...this.params,
                ...params,
            };
        },
    },

    getters: {},
});
