import { RouteConfig } from 'vue-router';

import { pinia, useAuth } from '@/store';
import { HOME_PAGES } from '@/constants';
import accountRoutes from './account';
import mySettingsRoutes from './my-settings';
import adminRoutes from './admin';
import assignmentsRoutes from './assignments';
import assignRunningRecordRoutes from './assign-running-record';
import bookshelfRoutes from './bookshelf';
import classroomRoutes from './classroom';
import collectionRoutes from './collection';
import gameRoutes from './game';
import lexileRoutes from './lexile';
import playerRoutes from './player';
import quizRoutes from './quiz';
import reportsRoutes from './reports';
import rostersRoutes from './rosters';
import runningRecordsRoutes from './running-records';
import searchRoutes from './search';
import studentReportRoutes from './student-report';

export * from './bookshelf';
export * from './reports';
export * from './search';

const routes: RouteConfig[] = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "site-layout" */ '@/common/layouts/site-page/SiteLayout.vue'),
        children: [
            {
                path: '',
                redirect: () => {
                    const auth = useAuth(pinia);

                    return auth.role ? HOME_PAGES[auth.role] || HOME_PAGES.default : HOME_PAGES.default;
                },
            },
            ...assignmentsRoutes,
            ...assignRunningRecordRoutes,
            ...bookshelfRoutes,
            ...collectionRoutes,
            ...classroomRoutes,
            ...studentReportRoutes,
            ...reportsRoutes,
            ...rostersRoutes,
            ...gameRoutes,
            ...searchRoutes,
            ...mySettingsRoutes,
        ],
    },
    ...accountRoutes,
    ...lexileRoutes,
    ...runningRecordsRoutes,
    ...quizRoutes,
    ...playerRoutes,
    ...adminRoutes,
    {
        path: '*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "notfound" */ '@/common/view/NotFoundPage.vue'),
    },
];

export default routes;
