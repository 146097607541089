import { AxiosPromise, AxiosRequestConfig } from 'axios';

import BaseRestService from '@/common/rest/base-rest-service';

import type { BookshelfRow, CarouselModel } from '@/types';

class CarouselRestService extends BaseRestService {
    getTopPicksCarousel(config?: AxiosRequestConfig): AxiosPromise<CarouselModel> {
        return super.get('/api/carousels/top', config);
    }

    getAllCarousels(config?: AxiosRequestConfig): AxiosPromise<CarouselModel[]> {
        return super.get('/api/carousels/all', config);
    }

    getNycCarousels(config?: AxiosRequestConfig): AxiosPromise<CarouselModel[]> {
        return super.get('/api/carousels/exclusive', config);
    }

    getGrammarWritingInterventionCarousels(config?: AxiosRequestConfig): AxiosPromise<CarouselModel[]> {
        return super.get('/api/carousels/grammar-writing-intervention', config);
    }

    getPoetryCarousels(config?: AxiosRequestConfig): AxiosPromise<CarouselModel[]> {
        return super.get('/api/carousels/poetry', config);
    }

    getStudentDefaults(): AxiosPromise<BookshelfRow[]> {
        return super.get('/api/carousels/student-defaults');
    }
}

const carouselRestService: CarouselRestService = new CarouselRestService();

export default carouselRestService;
