import { AxiosPromise } from 'axios';

import BaseRestService from '@/common/rest/base-rest-service';

import type { QuestionResponseModel, QuizUserResultModel } from '@/types';

class QuizRestService extends BaseRestService {
    getQuiz(idNumber: string) {
        return super.get(`/api/books/${idNumber}/quiz`);
    }

    getQuizAnswers(idNumber: string): AxiosPromise<QuizUserResultModel> {
        return super.get(`/api/books/${idNumber}/quiz/answers`);
    }

    saveResponses(idNumber: string, responses: QuestionResponseModel) {
        return super.post(`/api/books/${idNumber}/quiz`, responses);
    }

    getQuizStatus(idNumber: string) {
        return super.get(`/api/books/${idNumber}/quiz/status`);
    }

    saveQuizProgress(idNumber: string, request: { questionId: number; answerId?: number; answer?: string }) {
        return super.post(`/api/books/${idNumber}/quiz/progress`, request);
    }
}

const quizRestService: QuizRestService = new QuizRestService();

export default quizRestService;
