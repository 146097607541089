
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    model: {
        prop: 'checked',
        event: 'change'
    }
})
export default class Checkbox extends Vue {
    @Prop({
        type: [Array, Boolean],
        default: false
    })
    checked!: [] | boolean;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    @Prop({ type: Boolean, default: false })
    indeterminate!: boolean;

    @Prop({ default: null })
    value!: [] | boolean;

    get model(): [] | boolean {
        return this.checked;
    }

    set model(value: [] | boolean) {
        this.$emit('change', value);
    }
}
