import { NavigationGuard, Route } from 'vue-router';

import { pinia, useAuth } from '@/store';

const aclRouteGuard = (to: Route, from: Route, next: Function): NavigationGuard => {
    const isPublic = to.meta!.public;

    if (isPublic) {
        return next();
    }

    const auth = useAuth(pinia);
    const roles: string[] | undefined = to.meta!.roles;

    if (!roles) {
        return next();
    }

    if (auth.role && roles.includes(auth.role)) {
        return next();
    }

    return next('/');
};

export default aclRouteGuard;
