import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';
import { useLexileAssessmentsStore } from '@/store';

const lexileRoutes: RouteConfig[] = [
    {
        path: '/lexile',
        component: () => import(/* webpackChunkName: "lexile-layout" */ '@/common/layouts/lexile/LexileSiteLayout.vue'),
        children: [
            {
                path: '/lexile',
                name: 'lexile',
                component: () =>
                    import(/* webpackChunkName: "lexile-layout" */ '@/site-pages/lexile/view/LexileView.vue'),
                meta: {
                    forgetOnLogOut: true,
                    roles: [Role.STUDENT],
                },
            },
            {
                path: '/result',
                name: 'lexile-result',
                beforeEnter(to, from, next) {
                    const lexileAssessmentsStore = useLexileAssessmentsStore();

                    if (!lexileAssessmentsStore.assessmentStarted) {
                        return next('/bookshelf');
                    }

                    next();
                },
                component: () =>
                    import(
                        /* webpackChunkName: "lexile-result-page" */ '@/site-pages/lexile/component/LexileResultPage.vue'
                    ),
                meta: {
                    forgetOnLogOut: true,
                    roles: [Role.STUDENT],
                },
            },
        ],
    },
];

export default lexileRoutes;
