import Vue, { DirectiveOptions } from 'vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { DirectiveBinding } from 'vue/types/options';

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import '@/styles/overrides/_perfect-scrollbar.scss';

const state = new WeakMap();
const scrollOptions = {
    wheelPropagation: true
};

const Scrollbar: DirectiveOptions = {
    bind(el: HTMLElement, { value: targetSelector }: DirectiveBinding) {
        let node = el;

        if (targetSelector) {
            node = el.querySelector(targetSelector);
        }

        Vue.nextTick(() => {
            const scrollbar = new PerfectScrollbar(node, Object.assign(scrollOptions));
            state.set(el, scrollbar);
        });
    },

    componentUpdated(el: HTMLElement, { value: targetSelector }: DirectiveBinding) {
        let scrollbar = state.get(el);

        if (targetSelector) {
            const node = el.querySelector(targetSelector);
            scrollbar = new PerfectScrollbar(node, Object.assign(scrollOptions));
        }

        if (scrollbar) {
            Vue.nextTick(() => {
                scrollbar.update();
                state.set(el, scrollbar);
            });
        }
    },

    unbind(el) {
        const scrollbar = state.get(el);

        state.delete(el);

        if (scrollbar) {
            scrollbar.destroy();
        }
    }
};

export default Scrollbar;
