import { LexileTestLevel } from '@/constants';
import { StudentInterfaceTypeSetting } from '@/store/auth/types';
import { GameLimitOption } from '@/site-pages/classroom/gaming/config';

import type { Grade, UserModel } from '@/types';

export type InboxType = 'constructed_response' | 'running_record' | 'aloud_reading';

export enum InboxItemType {
    CONSTRUCTED_RESPONSE = 'constructed_response',
    RUNNING_RECORD = 'running_record',
    ALOUD_READING = 'aloud_reading',
}

export enum InboxItemTypeName {
    constructed_response = 'Constructed Response',
    running_record = 'Running Record',
    aloud_reading = 'Read Aloud',
}

export interface BaseStudentModel {
    id: number;
    name: string;
    surname: string;
    username: string;
    lexileLevel?: number;
    formattedLexileLevel?: string;
}

export interface StudentModel extends BaseStudentModel {
    gameAccess: boolean;
    gameLimit: string;
    quizFrequency: string;
    quizAvailability: string;
    constructedResponse: boolean;
    referToBook: boolean;
    currentGameMoney: number;
    bookPlayerSettings: BookPlayerSettingsModel;
    qrToken: string;
    studentInterfaceSettings?: {
        interfaceSetting: StudentInterfaceTypeSetting;
        userId: number;
    };
}

export interface BookPlayerSettingsModel {
    isAudioBookAvailable: boolean;
    isAloudReadingAvailable: boolean;
}

export interface StudentDetailsModel extends Omit<BaseStudentModel, 'formattedLexileLevel'> {
    edlinkId: string | null;
    email: string | null;
    gameMoney: number;
    generalGameTimeLimitMinutes: GameLimitOption;
    grade: string | null;
    intake: string | null;
    phone: string | null;
    schoolOrDistrictId: number | null;
    uuid: string;
    password: string | null;
}

export interface StudentGamingSettings {
    userId: number;
    gameAccess: boolean;
    gameLimit: string;
    currentGameMoney: number;
    awardGameMoney?: number;
}

export interface BatchImportStudentModel extends Omit<UserModel, 'id'> {
    id?: number;
    password: string;
    gradeLevel: Grade | null;
    schoolOrDistrictId?: string;
}

export interface ResponseGrade {
    questionId: number | null;
    userId: number;
    skillId: number;
    standardId: number;
    meet: boolean;
}

export interface SkillStandardGroupDto {
    id: number;
    skill: SkillDto;
    standard: StandardDto;
    meet: boolean;
}

export interface SkillDto {
    id: number;
    skillName: string;
}

export interface StandardDto {
    id: number;
    standardName: string;
    description: string;
}

export interface QuizRespResponseDto {
    questionText: string;
    questionGuidance: string;
    userAnswer: string;
    questionSkillStandardGroups: SkillStandardGroupDto[];
    respMeets: ResponseGrade[];
}

export interface ClassroomInboxItem {
    userId: number;
    userUsername: string;
    userName: string;
    userSurname: string;
    aloudReadingId: null | number;
    questionId: number | null;
    questionQuizBookTitle: string;
    questionQuizBookLexileWeight: number;
    dateCompleted: string;
    constructedAnswersAssessed: boolean;
    type: InboxType;
}

export interface ConstructedResponseModel extends Omit<ClassroomInboxItem, 'constructedAnswersAssessed'> {
    questionText: string;
    questionGuidance: string;
    questionSkillStandardGroups: SkillStandardGroupDto[];
    respMeets: ResponseGrade[];
    userAnswer: string;
}

export interface AssessmentSetting extends UserModel {
    lexileLevel: number | null;
    lastAssessmentDate: string | null;
    assessmentEnabled: boolean;
    currentAssessment: LexileTestLevel | null;
}
