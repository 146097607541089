import { UserDetailModel } from '@/types';
import { InterfaceType } from '@/store/auth/types';

const IS_AUTHENTICATED = 'authenticated';
const ROLE = 'role';
const USERNAME = 'username';
const HAS_LEXILE = 'hasLexile';
const USER_TOKEN = 'userToken';
const PROFILE = 'profile';
const INTERFACE_TYPE = 'interfaceType';
const IS_FULL_INTERFACE_TYPE = 'isFullInterfaceType';

/**
 * Manage how Token key will be stored and retrieved from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 **/
const storageProvider = localStorage;

//todo I'd prefer to keep most of this in the store and use https://github.com/robinvdvleuten/vuex-persistedstate to sync with localStorage.
// but we don't have to do it in this changeset though
export const TokenService = {
    getAuthStatus(): string | null {
        return storageProvider.getItem(IS_AUTHENTICATED);
    },

    saveAuthStatus(accessToken: string): void {
        storageProvider.setItem(IS_AUTHENTICATED, accessToken);
    },

    removeAuthStatus(): void {
        storageProvider.removeItem(IS_AUTHENTICATED);
    },

    getRole(): string | null {
        return storageProvider.getItem(ROLE);
    },

    setRole(role: string): void {
        storageProvider.setItem(ROLE, role);
    },

    removeRole(): void {
        storageProvider.removeItem(ROLE);
    },

    setUsername(username: string): void {
        storageProvider.setItem(USERNAME, username);
    },

    getUsername(): string | null {
        return storageProvider.getItem(USERNAME);
    },

    removeUsername(): void {
        storageProvider.removeItem(USERNAME);
    },

    removeLexileCheck(): void {
        storageProvider.removeItem(HAS_LEXILE);
    },

    setUserToken(userToken: string): void {
        storageProvider.setItem(USER_TOKEN, userToken);
    },

    getUserToken(): string | null {
        return storageProvider.getItem(USER_TOKEN);
    },

    removeUserToken(): void {
        storageProvider.removeItem(USER_TOKEN);
    },

    getProfile(): any {
        return JSON.parse(storageProvider.getItem(PROFILE) as string);
    },

    setProfile(profile: UserDetailModel): void {
        storageProvider.setItem(PROFILE, JSON.stringify(profile));
    },

    removeProfile(): void {
        storageProvider.removeItem(PROFILE);
    },

    setInterfaceType(interfaceType: InterfaceType): void {
        storageProvider.setItem(INTERFACE_TYPE, interfaceType);
    },

    getInterfaceType(): InterfaceType | null {
        const interfaceTypeFromLocalStorage = storageProvider.getItem(INTERFACE_TYPE);
        return interfaceTypeFromLocalStorage as InterfaceType | null;
    },

    removeInterfaceType(): void {
        storageProvider.removeItem(INTERFACE_TYPE);
    },

    setIsFullInterfaceType(isFull: boolean): void {
        storageProvider.setItem(IS_FULL_INTERFACE_TYPE, isFull ? 'true' : '');
    },

    getIsFullInterfaceType(): boolean {
        const isFullInterfaceTypeFromLocalStorage = storageProvider.getItem(IS_FULL_INTERFACE_TYPE);
        return !!isFullInterfaceTypeFromLocalStorage || false;
    },

    removeIsFullInterfaceType(): void {
        storageProvider.removeItem(IS_FULL_INTERFACE_TYPE);
    },
};
