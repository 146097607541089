import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';
import inboxRoutes from './inbox';
import rosterRoutes, { ClassroomRosterRoute } from './roster';

export enum ClassroomRoute {
    CLASSROOM = 'classroom',
    BOOKS_ASSIGNMENTS = 'classroom.assignments',
    RUNNING_RECORDS_ASSIGNMENTS = 'classroom.assignments.running-records',
    QUIZ_SETTINGS = 'classroom.quiz.settings',
    READING_GROUPS = 'classroom.groups',
    GAMING = 'classroom.gaming',
    ASSESSMENTS = 'classroom.assessments',
    BOOKSHELF_SETTINGS = 'classroom.bookshelf',
}

const BookshelfSettingsView = () =>
    import(
        /* webpackChunkName: "classroom-bookshelf" */ '@/site-pages/classroom/bookshelf-settings/BookshelfSettingsView.vue'
    );

const ClassroomLexileAssessmentsView = () =>
    import(
        /* webpackChunkName: "classroom-assessments" */ '@/site-pages/classroom/lexile-assessments/LexileAssessmentsView.vue'
    );

const ClassroomGamingView = () =>
    import(/* webpackChunkName: "classroom-gaming" */ '@/site-pages/classroom/gaming/ClassroomGamingView.vue');

const ClassroomQuizSettings = () =>
    import(/* webpackChunkName: "classroom-gaming" */ '@/site-pages/classroom/view/ClassroomQuizSettings.vue');

const classroomRoutes: RouteConfig[] = [
    {
        path: '/classroom',
        name: ClassroomRoute.CLASSROOM,
        component: () => import(/* webpackChunkName: "classroom" */ '@/site-pages/classroom/view/Classroom.vue'),
        redirect: { name: ClassroomRosterRoute.ROSTER },
        children: [
            ...inboxRoutes,
            ...rosterRoutes,
            {
                path: '/classroom/assignments',
                component: () =>
                    import(
                        /* webpackChunkName: "classroom" */ '@/site-pages/classroom/assignments/ClassroomAssignmentsView.vue'
                    ),
                meta: {
                    key: 'assignments',
                    roles: [Role.TEACHER],
                },
                children: [
                    {
                        path: '',
                        name: ClassroomRoute.BOOKS_ASSIGNMENTS,
                        component: () =>
                            import(
                                /* webpackChunkName: "classroom" */ '@/site-pages/classroom/assignments/components/ClassroomBooksAssignments.vue'
                            ),
                        meta: {
                            key: 'assignments',
                            roles: [Role.TEACHER],
                            title: 'Assignments',
                        },
                        beforeEnter: (to, from, next) => {
                            (to.meta as any).from = from;
                            next();
                        },
                    },
                    {
                        path: 'running-records',
                        name: ClassroomRoute.RUNNING_RECORDS_ASSIGNMENTS,
                        component: () =>
                            import(
                                /* webpackChunkName: "classroom" */ '@/site-pages/classroom/assignments/components/ClassroomRunningRecordsAssignments.vue'
                            ),
                        meta: {
                            key: 'assignments',
                            roles: [Role.TEACHER],
                            title: 'Assignments',
                        },
                    },
                ],
            },
            {
                path: '/classroom/quiz-settings',
                name: ClassroomRoute.QUIZ_SETTINGS,
                component: ClassroomQuizSettings,
                meta: {
                    roles: [Role.TEACHER],
                },
            },
            {
                path: '/classroom/groups',
                name: ClassroomRoute.READING_GROUPS,
                component: () =>
                    import(
                        /* webpackChunkName: "classroom" */ '@/site-pages/classroom/view/ClassroomReadingGroups.vue'
                    ),
                meta: {
                    roles: [Role.TEACHER],
                },
            },
            {
                path: '/classroom/gaming',
                name: ClassroomRoute.GAMING,
                component: ClassroomGamingView,
                meta: {
                    roles: [Role.TEACHER],
                },
            },
            {
                path: '/classroom/assessments',
                name: ClassroomRoute.ASSESSMENTS,
                component: ClassroomLexileAssessmentsView,
                meta: {
                    roles: [Role.TEACHER],
                },
            },
            {
                path: '/classroom/bookshelf-settings',
                name: ClassroomRoute.BOOKSHELF_SETTINGS,
                component: BookshelfSettingsView,
                meta: {
                    roles: [Role.TEACHER],
                },
            },
        ],
    },
];

export default classroomRoutes;
