import { AxiosPromise } from 'axios';

import BaseRestService from '@/common/rest/base-rest-service';

import type { IPageRequest, PageResponse } from '@/rest';
import type { ReadingGroup } from '@/types';

class GroupsRestService extends BaseRestService {
    getAllPageableGroups(page: IPageRequest): AxiosPromise<PageResponse<ReadingGroup>> {
        return super.get(`/api/reading-groups?page=${page.page}&size=${page.size}`);
    }
}

const groupsRestService: GroupsRestService = new GroupsRestService();

export { groupsRestService };
