
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SearchBar extends Vue {
    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: false, type: Boolean })
    hideButton!: boolean;

    @Prop({ default: 'Search by title, subtitle or category' })
    placeholder!: string;

    @Prop({ default: '' })
    value!: string;

    @Prop({ default: 'default' })
    theme!: string;

    get query(): string {
        return this.value;
    }

    set query(query: string) {
        this.$emit('input', query);
    }

    get isEmpty() {
        return this.query.length === 0;
    }

    get searchClasses() {
        return [
            'search-bar',
            this.disabled && 'search-bar_disabled',
            this.hideButton && 'search-bar_no-button',
            `search-bar_${this.theme}`,
        ];
    }

    clear() {
        this.query = '';
        this.$emit('clear');
    }

    submit() {
        if (this.disabled) {
            return false;
        }

        this.$emit('submit', this.query);
    }

    onFocus() {
        this.$emit('focus');
    }
}
