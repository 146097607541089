import { defineStore } from 'pinia';

import { searchRestService } from '@/rest';
import { sortStudentsByFullName } from '@/utils';
import type { StudentSearchState } from './types';

export const useStudentSearchStore = defineStore('student-search', {
    state: (): StudentSearchState => ({
        isLoading: false,
        students: [],
    }),

    actions: {
        async fetchStudents(query: string) {
            this.isLoading = true;

            try {
                const { data: students } = await searchRestService.findUsers(query);

                this.students = students;
            } finally {
                this.isLoading = false;
            }
        },
    },

    getters: {
        sortedStudents: (state: StudentSearchState) => sortStudentsByFullName(state.students, false),
    },
});
