import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import track from '@/common/rest/track/track-polling';
import modal from '@/common/store/modal-store';
import { modalNamespace } from '@/common/store/modal-store-const';
import { reportNamespace } from './reports/types';
import reports, { ReportsPersistedState } from './reports';
import { trackNamespace } from '@/common/rest/track/track-polling-model';
import { rostersNamespace } from '@/store/rosters/types';
import rosters from '@/store/rosters';
import { createPinia, PiniaVuePlugin, Store } from 'pinia';

export const pinia = createPinia();

export * from './auth';
export * from './quiz';
export * from './book-details';
export * from './bulk-assignment';
export * from './bookshelf';
export * from './classroom';
export * from './cloze';
export * from './collection';
export * from './game';
export * from './groups';
export * from './inbox';
export * from './lexile';
export * from './running-records';
export * from './search';
export * from './student-report';
export * from './users';
export * from './tags';
export * from './route';

export * from '@/player/store/activities';
export * from '@/player/store/book-reader';

Vue.use(Vuex);
Vue.use(PiniaVuePlugin);

export interface RootState {}

const stores: Store[] = [];

pinia.use(({ store }) => {
    stores.push(store);
});

export const resetAllStores = () => {
    stores.forEach((store) => store.$reset());
};

const modules = {
    [trackNamespace]: track,
    [modalNamespace]: modal,
    [reportNamespace]: reports,
    [rostersNamespace]: rosters,
};

const storeOptions: StoreOptions<RootState> = {
    state: {},
    mutations: {},
    actions: {
        reset({ commit }) {
            Object.keys(modules).forEach((moduleName) => {
                commit(`${moduleName}/RESET`);
            });
        },
    },
    modules,
    plugins: [ReportsPersistedState],
};

const store = new Vuex.Store<RootState>(storeOptions);

export default store;
