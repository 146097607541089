
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import Paginate from 'vuejs-paginate';
import { formatNumber, pluralize } from '@/filters';

@Component({
    components: {
        Paginate
    }
})
export default class BEPagination extends Vue {
    @PropSync('parentCurrentPage', { type: Number, default: 0 })
    currentPage!: number;

    @Prop({ default: 20, type: Number })
    pageSize!: number;

    @Prop({ default: 'item', type: String })
    itemName!: string;

    @Prop()
    totalElements!: number;

    get totalPages(): number {
        return Math.ceil(this.totalElements / this.pageSize);
    }

    get selectedPage(): number {
        return this.currentPage + 1;
    }

    set selectedPage(value: number) {
        this.currentPage = value - 1;
    }

    get pagesOverflow(): boolean {
        return this.totalPages > 10;
    }

    get summary(): string {
        const { resultsRange, totalElements, itemName } = this;
        const resultsWord = pluralize(itemName, totalElements);

        return `Showing ${resultsRange} ${resultsWord}`;
    }

    get resultsRange(): string {
        const { endingNumber, startingNumber, totalElements } = this;

        return `${formatNumber(startingNumber)}&ndash;${formatNumber(endingNumber)} of ${formatNumber(totalElements)}`;
    }

    get startingNumber(): number {
        const { currentPage, pageSize } = this;

        return currentPage * pageSize + 1;
    }

    get endingNumber(): number {
        const { pageSize } = this;
        const endingNumber = this.startingNumber + pageSize - 1;

        return endingNumber > this.totalElements ? this.totalElements : endingNumber;
    }

    get hasPages(): boolean {
        return this.totalPages > 1;
    }

    paginationHandler(pageNum: number): void {
        this.$emit('fetch', pageNum - 1);
    }
}
