
import { Component, Ref } from 'vue-property-decorator';

import FormFieldMixin from '@/mixins/FormFieldMixin.vue';
import LUButton from './LUButton.vue';

@Component({
    components: { LUButton }
})
export default class LuFileInput extends FormFieldMixin {
    @Ref('input') readonly input!: HTMLInputElement;

    get model() {
        return this.value;
    }

    set model(value) {
        this.$emit('input', value);
    }
}
