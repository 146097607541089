import { MutationTree } from 'vuex';
import Vue from 'vue';

import {
    ClassroomDetailsModel,
    ClassroomModel,
    DistrictModel,
    DistrictOrder,
    SchoolDto,
    SchoolModel,
    GroupItem,
    UserModel,
} from '@/types';
import { RostersMutations, RostersState } from './types';

export const initialState = (): RostersState => ({
    admins: new Map(),
    classrooms: new Map(),
    districts: {},
    districtsOrder: 'BY_NAME',
    schools: {},
    schoolsWithClassrooms: [],
    users: new Map(),
});

const mutations: MutationTree<RostersState> = {
    [RostersMutations.setClassrooms]: (state: RostersState, classrooms: ClassroomModel[]) => {
        const classroomMap = new Map();

        classrooms.forEach((classroom) => classroomMap.set(classroom.id, classroom));

        state.classrooms = new Map([...state.classrooms, ...classroomMap]);
    },

    [RostersMutations.setDistricts]: (state: RostersState, districts: DistrictModel[]) => {
        state.districts = districts.reduce((acc, district) => {
            acc[district.id] = district;

            return acc;
        }, {});
    },

    [RostersMutations.setSchools]: (state: RostersState, schools: SchoolDto[]) => {
        const schoolModels = {};
        const { classrooms } = state;

        schools.forEach((school) => {
            schoolModels[school.id] = {
                ...school,
                classrooms: school.classrooms.map((classroom) => classroom.id),
            };

            school.classrooms.forEach((classroom) => classrooms.set(classroom.id, classroom));
        });

        state.schools = schoolModels;
        state.classrooms = new Map(classrooms);
    },

    [RostersMutations.setSchoolsWithClassrooms]: (state: RostersState, schools: GroupItem[]) => {
        state.schoolsWithClassrooms = schools;
    },

    [RostersMutations.setDistrictsOrder]: (state: RostersState, order: DistrictOrder) => {
        state.districtsOrder = order;
    },

    [RostersMutations.updateDistrict]: (state: RostersState, district: DistrictModel) => {
        const { id } = district;

        state.districts[id] = {
            ...state.districts[id],
            ...district,
        };
    },

    [RostersMutations.setDistrictSchools]: (
        state: RostersState,
        { districtId, schools }: { districtId: number; schools: SchoolModel[] }
    ) => {
        const schoolIds = schools.map((school) => school.id);

        Vue.set(state.districts, districtId, {
            ...state.districts[districtId],
            schoolIds,
        });
    },

    [RostersMutations.setGroupAdmins]: (
        state: RostersState,
        { groupId, users }: { groupId: number; users: UserModel[] }
    ) => {
        const admins = state.admins.set(groupId, users);

        state.admins = new Map(admins);
    },

    [RostersMutations.updateSchool]: (state: RostersState, school: SchoolDto) => {
        const { classrooms, id: schoolId } = school;
        let classroomIds: number[] = [];

        if (Array.isArray(classrooms)) {
            classroomIds = [...new Set(classrooms.map((classroom) => classroom.id))];
        }

        Vue.set(state.schools, schoolId, {
            ...(state.schools[schoolId] || {}),
            ...school,
            classrooms: classroomIds,
        });
    },

    [RostersMutations.updateClassroom]: (state: RostersState, classroom: ClassroomModel | ClassroomDetailsModel) => {
        const { id: classroomId } = classroom;
        const { classrooms } = state;

        classrooms.set(classroomId, {
            ...(classrooms.get(classroomId) || {}),
            ...classroom,
        });

        state.classrooms = new Map(classrooms);
    },

    [RostersMutations.addClassroom]: (state: RostersState, classroom: ClassroomDetailsModel) => {
        const { id: classroomId } = classroom;
        const { classrooms } = state;

        classrooms.set(classroomId, classroom);

        state.classrooms = new Map(classrooms);
    },

    [RostersMutations.addClassroomToSchool]: (
        state: RostersState,
        { schoolId, classroom }: { schoolId: number; classroom: ClassroomDetailsModel }
    ) => {
        const { id: classroomId } = classroom;
        const school = state.schools[schoolId];

        if (school) {
            Vue.set(state.schools, schoolId, {
                ...school,
                classrooms: [...new Set([...school.classrooms, classroomId])],
            });
        }
    },

    [RostersMutations.setClassroomUsers]: (
        state: RostersState,
        { classroomId, users }: { classroomId: number; users: UserModel[] }
    ) => {
        const admins = state.admins.set(classroomId, users);

        state.users = new Map(admins);
    },

    [RostersMutations.reset]: (state: RostersState) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

export default mutations;
