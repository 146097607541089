import { Route } from 'vue-router';

import { useGameStore } from '@/store';
import { GameAccessStatus } from '@/store/game/types';
import { useAuth } from '@/store';
import { BookshelfRoute } from '../routes/bookshelf';

const gameRouteGuard = async (_: Route, __: Route, next: Function) => {
    const gameStore = useGameStore();
    const auth = useAuth();

    if (auth.hasStudentRole && gameStore.gameAccessStatus === null) {
        await gameStore.getGameAccessStatus();
    }

    const isGameAccessible = gameStore.gameAccessStatus === GameAccessStatus.ACCESS;
    const timeIsOver = gameStore.gameAccessStatus === GameAccessStatus.TIME_IS_OVER;

    if (!isGameAccessible || timeIsOver) {
        next({ name: BookshelfRoute.BOOKSHELF });
        return;
    }

    next();
};

export default gameRouteGuard;
