import qs from 'qs';

import BaseRestService from '@/common/rest/base-rest-service';

interface TrackLoggedInTimeOptions {
    timeToTrack?: number;
}
class TrackRestService extends BaseRestService {
    trackLoggedInTime(options: TrackLoggedInTimeOptions = {}) {
        const query = Object.keys(options).length ? `?${qs.stringify(options)}` : '';
        return super.shadowGet(`/api/auth/activities/auth-track${query}`);
    }
}

const trackRestService: TrackRestService = new TrackRestService();

export default trackRestService;
