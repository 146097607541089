import { Route, RouteConfig } from 'vue-router';

import { Role } from '@/constants';
import { castNumericParamsToProps } from '@/router/lib';

export enum InboxRoute {
    INBOX = 'inbox',
    CONSTRUCTED = 'inbox.constructed',
    INBOX_GRADED = 'inbox.graded',
    RUNNING_RECORDS_READING = 'runningRecordsReview.reading',
    RUNNING_RECORDS_SUBMIT = 'runningRecordsReview.submit',
    READ_ALOUD_FOR_GRADE = 'readAloud.forGrade',
    READ_ALOUD_GRADED = 'readAloud.graded',
}

import EmptyComponentView from '@/common/view/EmptyComponentView.vue';

const ClassroomInboxView = () =>
    import(/* webpackChunkName: "classroom" */ '@/site-pages/classroom/inbox/ClassroomInbox.vue');

const ClassroomInboxGrid = () =>
    import(/* webpackChunkName: "classroom" */ '@/site-pages/classroom/inbox/views/ClassroomInboxGrid.vue');

const ClassroomInboxForGradeView = () =>
    import(/* webpackChunkName: "classroom" */ '@/site-pages/classroom/inbox/views/ClassroomInboxForGradeView.vue');

const ClassroomInboxGradedView = () =>
    import(/* webpackChunkName: "classroom" */ '@/site-pages/classroom/inbox/views/ClassroomInboxGradedView.vue');

const ClassroomInboxGradeResponseView = () =>
    import(/* webpackChunkName: "classroom" */ '@/site-pages/classroom/inbox/views/InboxGradeResponse.vue');

const RunningRecordsReviewView = () => import('@/site-pages/running-records/views/RunningRecordsReview.vue');
const ReadingReviewView = () => import('@/site-pages/running-records/views/ReadingReview.vue');
const RunningRecordsReviewSubmitView = () => import('@/site-pages/running-records/views/RunningRecordReviewSubmit.vue');

// const ReadAloudReviewCommonView = () => import('@/site-pages/classroom/inbox/views/read-aloud/ReadAloudReviewCommonView.vue');
const ReadAloudReviewView = () => import('@/site-pages/classroom/inbox/views/read-aloud/ReadAloudReviewView.vue');
// const ReadAloudReviewGradedView = () => import('@/site-pages/classroom/inbox/views/read-aloud/ReadAloudReviewGradedView.vue');

const runningRecordsReviewProps = (route: Route) => ({
    recordId: Number.parseInt(route.params.id, 10),
});

const readAloudReviewProps = (route: Route) => ({
    recordId: Number.parseInt(route.params.id),
});

const inboxRoutes: RouteConfig[] = [
    {
        path: '/classroom/inbox',
        component: ClassroomInboxView,
        meta: {
            roles: [Role.TEACHER],
        },
        children: [
            {
                path: '',
                component: ClassroomInboxGrid,
                meta: {
                    roles: [Role.TEACHER],
                },
                children: [
                    {
                        path: '',
                        name: InboxRoute.INBOX,
                        component: ClassroomInboxForGradeView,
                        meta: {
                            key: 'inbox',
                            roles: [Role.TEACHER],
                        },
                    },
                    {
                        path: 'graded',
                        name: InboxRoute.INBOX_GRADED,
                        component: ClassroomInboxGradedView,
                        meta: {
                            key: 'inbox',
                            roles: [Role.TEACHER],
                        },
                    },
                ],
            },
            {
                path: 'constructed/:studentId/:questionId',
                name: InboxRoute.CONSTRUCTED,
                component: ClassroomInboxGradeResponseView,
                props: (route: Route) => ({
                    ...castNumericParamsToProps(route, ['questionId', 'studentId']),
                }),
                meta: {
                    roles: [Role.TEACHER],
                },
            },
            {
                path: 'read-aloud',
                component: EmptyComponentView,
                props: readAloudReviewProps,
                meta: {
                    roles: [Role.TEACHER],
                },
                children: [
                    {
                        path: 'for-grade/:id',
                        name: InboxRoute.READ_ALOUD_FOR_GRADE,
                        component: ReadAloudReviewView,
                        props: readAloudReviewProps,
                        meta: {
                            roles: [Role.TEACHER],
                        },
                    },
                    // {
                    //     path: 'graded/:id',
                    //     name: InboxRoute.READ_ALOUD_GRADED,
                    //     component: ReadAloudReviewGradedView,
                    //     props: readAloudReviewProps,
                    //     meta: {
                    //         roles: [Role.TEACHER],
                    //     },
                    // },
                ],
            },
            {
                path: 'running-records/:id',
                component: RunningRecordsReviewView,
                props: runningRecordsReviewProps,
                meta: {
                    key: 'running-records',
                    roles: [Role.TEACHER],
                },
                children: [
                    {
                        path: '/',
                        name: InboxRoute.RUNNING_RECORDS_READING,
                        component: ReadingReviewView,
                        props: runningRecordsReviewProps,
                        meta: {
                            key: 'running-records',
                            roles: [Role.TEACHER],
                        },
                    },
                    {
                        path: 'submit',
                        name: InboxRoute.RUNNING_RECORDS_SUBMIT,
                        component: RunningRecordsReviewSubmitView,
                        props: runningRecordsReviewProps,
                        meta: {
                            key: 'running-records',
                            roles: [Role.TEACHER],
                        },
                    },
                ],
            },
        ],
    },
];

export default inboxRoutes;
