import { defineStore } from 'pinia';

import { quizRestService } from '@/rest';
import router from '@/router';
import { QuizStatus } from '@/site-pages/quiz/interface';
import { BookshelfRoute } from '@/router/routes/bookshelf';

import type {
    MultiQuestionResponseModel,
    QuestionResponseModel,
    QuizModel,
    QuizUserResultModel,
    RespQuestionResponseModel,
} from '@/types';
import type { QuizState } from './types';

export const useQuizStore = defineStore('quiz', {
    state: (): QuizState => ({
        loading: false,
        startQuizLoading: false,
        quizzes: new Map<string, QuizModel>(),
        responses: {},
        triedToFinish: false,
        quizResult: {} as QuizUserResultModel,
        isAudioPlaying: false,
    }),

    actions: {
        async loadQuiz(idNumber: string) {
            this.loading = true;

            try {
                const { data: quiz } = await quizRestService.getQuiz(idNumber);
                const newQuizzes = new Map(this.quizzes);

                const responses = quiz.answeredQuestions.reduce(
                    (a, v) => ({
                        ...a,
                        [v.questionId]: v.answer ? v.answer : v.answerId,
                    }),
                    {}
                );

                newQuizzes.set(quiz.idNumber, {
                    ...quiz,
                    questions: [...quiz.multiQuestions, ...quiz.respQuestions].sort(
                        (a, b) => a.displayOrder - b.displayOrder
                    ),
                });

                this.responses = responses;
                this.quizzes = newQuizzes;
            } finally {
                this.loading = false;
            }
        },

        async startQuiz(idNumber: string, nextRoutePath: string) {
            try {
                this.startQuizLoading = true;

                const {
                    data: { status },
                } = await quizRestService.getQuizStatus(idNumber);

                if (status === QuizStatus.QUIZ_FINISHED || status === QuizStatus.QUIZ_NOT_AVAILABLE) {
                    return router.push(nextRoutePath);
                }

                return router.push({
                    name: 'quiz',
                    params: {
                        id: idNumber,
                    },
                });
            } catch (error) {
                router.push(nextRoutePath);
            } finally {
                this.startQuizLoading = false;
            }
        },

        async finishQuiz(id: string) {
            this.loading = true;
            const quiz = this.getQuizById(id);

            if (!quiz) {
                return;
            }

            this.triedToFinish = true;

            return new Promise(async (resolve, reject) => {
                // Check all responses were given.
                if (
                    Object.values(this.responses).length == quiz.questions.length &&
                    !Object.values(this.responses).includes('') &&
                    this.allQuestionAnswered(id)
                ) {
                    // Construct responses models to save to DB.
                    const multiResps: MultiQuestionResponseModel[] = quiz.multiQuestions.map((q) => ({
                        questionId: q.id,
                        answerId: Number.parseInt(this.responses[q.id] as string, 10),
                    }));

                    const respResps: RespQuestionResponseModel[] = quiz.respQuestions.map((q) => ({
                        questionId: q.id,
                        answer: this.responses[q.id] as string,
                    }));

                    const resps: QuestionResponseModel = {
                        multiChoiceAnswers: multiResps,
                        freeFormAnswers: respResps,
                    };

                    try {
                        await quizRestService.saveResponses(id, resps);
                        return resolve(true);
                    } finally {
                        this.triedToFinish = false;
                        this.loading = false;
                    }
                } else {
                    this.loading = false;
                    reject();
                }
            });
        },

        async loadQuizAnswers(idNumber: string) {
            this.loading = true;

            try {
                const { data: result } = await quizRestService.getQuizAnswers(idNumber);

                this.quizResult = result;
            } finally {
                this.loading = false;
            }
        },

        async setAnswer({
            bookIdNumber,
            questionId,
            answer,
            isText,
        }: {
            bookIdNumber: string;
            questionId: number;
            answer: number | string;
            isText: boolean;
        }) {
            this.responses = {
                ...this.responses,
                [questionId]: answer,
            };

            if (isText) {
                quizRestService.saveQuizProgress(bookIdNumber, {
                    questionId: questionId,
                    answer: answer as string,
                });
            }
        },
    },

    getters: {
        getQuizById: (state) => (idNumber: string) => state.quizzes.get(idNumber),

        /**
         * Response is incomplete if Finish button was clicked and response is empty.
         */
        responseIncomplete: (state) => (id: number) =>
            state.triedToFinish && (!state.responses[id] || state.responses[id] == ''),

        allQuestionAnswered(state: QuizState) {
            return (id: string) => {
                const quiz = this.getQuizById(id);

                if (!quiz) {
                    return false;
                }

                //check if all question was answered
                const multiRespsFilled: boolean = quiz.multiQuestions
                    .map((q) => !!state.responses[q.id])
                    .some((result) => result === false);

                //check if question was answered
                const respRespsFilled: boolean = quiz.respQuestions
                    .map((q) => {
                        return !!state.responses[q.id] && state.responses[q.id] !== '';
                    })
                    .some((result) => result === false);

                return ![!multiRespsFilled, !respRespsFilled].some((result) => result === false);
            };
        },
    },
});
