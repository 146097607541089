
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    inheritAttrs: false,

    model: {
        prop: 'checked',
        event: 'input'
    }
})
export default class LURadio extends Vue {
    @Prop({
        default: false
    })
    checked!: boolean;

    @Prop({
        default: false,
        type: Boolean
    })
    disabled!: boolean;

    @Prop({ default: null })
    value!: string | number | boolean | null;

    @Watch('checked')
    onCheckedChange(newVal: boolean) {
        this.computedInnerChecked = newVal;
    }

    innerChecked = this.checked;

    get computedInnerChecked(): boolean {
        return this.innerChecked;
    }
    set computedInnerChecked(value: boolean) {
        this.innerChecked = value;
    }
    get isChecked() {
        return String(this.value) === String(this.computedInnerChecked);
    }

    handleChange({ target: { checked } }) {
        const { value } = this;

        this.computedInnerChecked = Boolean(value);

        this.$emit('input', checked ? value : null);
    }
}
