
import { Component, Prop, Vue } from 'vue-property-decorator';

import BookCardSkeleton from './BookCardSkeleton.vue';

@Component({
    components: {
        BookCardSkeleton,
    },
})
export default class BookshelfSkeleton extends Vue {
    @Prop({ default: 6 })
    columns!: number;
}
