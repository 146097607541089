import axios from 'axios';

import router from '@/router';
import { LexileErrorCodeLabel } from '@/constants';
import { pinia, useAuth } from '@/store';

let requestsCount = 0;

const manageStoreLoading = (increase: boolean, config: any) => {
    const auth = useAuth(pinia);

    if (config && config.params && config.params.noLoading) {
        return;
    }
    increase ? requestsCount++ : requestsCount--;
    requestsCount = Math.max(requestsCount, 0);
    if (increase) {
        auth.loading = true;
    } else if (!requestsCount) {
        auth.loading = false;
    }
};

const axiosSetup = () => {
    const auth = useAuth(pinia);

    axios.interceptors.request.use(
        (config) => {
            //call mutation with name auth/authLoading and pass param
            //todo maybe need another solution to prevent show load progress when polling new available test
            manageStoreLoading(true, config);
            auth.error = null;
            return config;
        },
        (error) => {
            manageStoreLoading(false, error.config);
            auth.error = error;
            return Promise.reject(error);
        }
    );

    // Stores the 401 interceptor position so that it can be later ejected when needed
    axios.interceptors.response.use(
        (response) => {
            manageStoreLoading(false, response.config);
            auth.error = null;
            return Promise.resolve(response);
        },
        (error) => {
            //call mutation with name auth/authLoading and pass param
            manageStoreLoading(false, error.config);
            auth.error = error;

            // Request is canceled
            if (axios.isCancel(error)) {
                return Promise.reject(error);
            }

            if (error.response && error.response.status === 401) {
                const { name: currentRouteName } = router.currentRoute;

                if (currentRouteName !== 'login' && currentRouteName !== 'login.badge') {
                    const { forgetOnLogOut } = router.currentRoute.meta!;
                    const redirect = forgetOnLogOut ? '' : router.currentRoute.fullPath;

                    router.push({
                        name: 'login',
                        query: {
                            redirect,
                        },
                    });
                }

                return Promise.reject(error);
            }

            if (error.response && LexileErrorCodeLabel.get(error.response.data.status)) {
                if (router.currentRoute.name !== 'lexile') {
                    router.push({ name: 'lexile' });
                }

                return Promise.reject(error);
            }

            return Promise.reject(error.response);
        }
    );
};

export default axiosSetup;
