import { Module } from 'vuex';

import { RootState } from '@/store';

import actions from './actions';
import getters from './getters';
import mutations, { initialState } from './mutations';
import { RostersState } from './types';

const state: RostersState = initialState();

const namespaced: boolean = true;

const inbox: Module<RostersState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};

export default inbox;
