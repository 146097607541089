import {
    ClassroomDetailsModel,
    ClassroomModel,
    DistrictModel,
    DistrictOrder,
    SchoolModel,
    GroupItem,
    UserModel,
} from '@/types';

export interface RostersState {
    admins: Map<number, UserModel[]>;
    classrooms: Map<number, ClassroomModel | ClassroomDetailsModel>;
    districts: {
        [districtId: number]: DistrictModel;
    };
    districtsOrder: DistrictOrder;
    schools: {
        [schoolId: number]: SchoolModel;
    };
    schoolsWithClassrooms: GroupItem[];
    users: Map<number, UserModel[]>;
}

export enum RostersActions {
    createClassroom = 'CREATE_CLASSROOM',
    fetchClassroomDetails = 'FETCH_CLASSROOM_DETAILS',
    fetchDistricts = 'FETCH_DISTRICTS',
    fetchDistrictAdmins = 'FETCH_DISTRICT_ADMINS',
    fetchDistrictAdminById = 'FETCH_DISTRICT_ADMIN_BY_ID',
    fetchSchools = 'FETCH_SCHOOLS',
    fetchSchoolsWithClassrooms = 'FETCH_SCHOOLS_WITH_CLASSROOMS',
    fetchSchoolAdmins = 'FETCH_SCHOOL_ADMINS',
    fetchSchoolAdminById = 'FETCH_SCHOOL_ADMIN_BY_ID',
    fetchSchoolDetails = 'FETCH_SCHOOL_DETAILS',
    fetchClassroomUserById = 'FETCH_CLASSROOM_USER_BY_ID',
    saveClassroom = 'SAVE_CLASSROOM',
    saveDistrict = 'SAVE_DISTRICT',
    saveDistrictAdmin = 'SAVE_DISTRICT_ADMIN',
    saveSchool = 'SAVE_SCHOOL',
    saveSchoolAdmin = 'SAVE_SCHOOL_ADMIN',
    saveClassroomUser = 'SAVE__CLASSROOM_USER',
    validateUsername = 'VALIDATE_USERNAME',
}

export enum RostersGetters {
    districts = 'districts',
    districtsByName = 'districtsByName',
    districtsByStandard = 'districtsByStandard',
    districtsOrder = 'districtsOrder',
    getClassroomById = 'getClassroomById',
    getDistrictById = 'getDistrictById',
    getDistrictSchools = 'getDistrictSchools',
    getSchoolById = 'getSchoolById',
    getGroupAdmins = 'getGroupAdmins',
    getGroupAdminById = 'getGroupAdminById',
    getSchoolClassrooms = 'getSchoolClassrooms',
    getSchoolsWithClassrooms = 'getSchoolsWithClassrooms',
    getSchoolTeachers = 'getSchoolTeachers',
    getClassroomUsers = 'getClassroomUsers',
    getClassroomUserById = 'getClassroomUserById',
}

export enum RostersMutations {
    reset = 'RESET',
    addClassroom = 'ADD_CLASSROOM',
    addClassroomToSchool = 'ADD_CLASSROOM_TO_SCHOOL',
    setClassrooms = 'SET_CLASSROOMS',
    setClassroomUsers = 'SET_CLASSROOM_USERS',
    setDistricts = 'SET_DISTRICTS',
    setDistrictsOrder = 'SET_DISTRICTS_ORDER',
    setDistrictSchools = 'SET_DISTRICT_SCHOOLS',
    setGroupAdmins = 'SET_GROUP_ADMINS',
    setSchools = 'SET_SCHOOLS',
    setSchoolsWithClassrooms = 'SET_SCHOOLS_WITH_CLASSROOMS',
    updateClassroom = 'UPDATE_CLASSROOM',
    updateDistrict = 'UPDATE_DISTRICTS',
    updateSchool = 'UPDATE_SCHOOL',
}

export enum RostersProgresses {
    fetchingClassroomDetails = 'rosters.fetchingClassroomDetails',
    fetchingDistricts = 'rosters.fetchingDistricts',
    fetchingDistrictAdmins = 'rosters.fetchingDistrictAdmins',
    fetchingSchools = 'rosters.fetchingSchools',
    fetchingSchoolAdmins = 'rosters.fetchingSchoolAdmins',
    fetchingSchoolDetails = 'rosters.fetchingSchoolDetails',
    fetchingClassroomUsers = 'rosters.fetchingClassroomUsers',
    classroomSaving = 'rosters.classroomSaving',
    districtAdminSaving = 'rosters.districtAdminSaving',
    districtSaving = 'rosters.districtSaving',
    schoolSaving = 'rosters.schoolSaving',
    schoolAdminSaving = 'rosters.schoolAdminSaving',
    userSaving = 'rosters.userSaving',
}

export const rostersNamespace = 'rosters';
