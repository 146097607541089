export const AwardStars = [25, 50, 100];

export enum GameLimitOption {
    'MIN_0' = '0 min',
    'MIN_5' = '5 min',
    'MIN_10' = '10 min',
    'MIN_20' = '20 min',
    'MIN_60' = '1 hour',
    'UNLIMITED' = 'Unlimited',
}
