
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LUPageHeader extends Vue {
    @Prop()
    title!: string;

    onClick() {
        this.$emit('click');
    }
}
