import { RouteConfig } from 'vue-router';
import { pinia, useAuth, useLexileAssessmentsStore } from '@/store';
import { Role } from '@/constants';
import { BookDetailsState } from '@/shared/model/book-details-model';

export enum BookshelfRoute {
    BOOKSHELF = 'bookshelf.carousel',
    BOOKSHELF_DETAILS = 'bookshelf.details',
    BOOKSHELF_CONSOLIDATED_DETAILS_FOR_STUDENT = 'BOOKSHELF_CONSOLIDATED_DETAILS_FOR_STUDENT',
    BOOKSHELF_NYC_LANDING = 'BOOKSHELF_NYC_LANDING',
    BOOKSHELF_GRAMMAR_WRITING_INTERVENTION_LANDING = ' BOOKSHELF_GRAMMAR_WRITING_INTERVENTION_LANDING',
    BOOKSHELF_POETRY_LANDING = 'BOOKSHELF_POETRY_LANDING',
    PLAYER = 'bookshelf.player',
    PLAYER_ON_SET_PAGE = 'bookshelf.player-on-set-page',
}

const BookshelfView = () => import(/* webpackChunkName: "bookshelf" */ '@/site-pages/bookshelf/view/BookshelfView.vue');

const BookshelfCarouselView = () =>
    import(/* webpackChunkName: "bookshelfcarousel" */ '@/site-pages/bookshelf/view/BookshelfCarousel.vue');

const BookshelfDetailsView = () =>
    import(/* webpackChunkName: "bookDetails" */ '@/site-pages/bookshelf/view/BookshelfDetailsView.vue');

const ConsolidatedBookDetailsForStudentView = () =>
    import(/* webpackChunkName: "bookDetails" */ '@/site-pages/book-details/ConsolidatedBookDetailsForStudentView.vue');

const BookshelfLandingView = () =>
    import(/* webpackChunkName: "bookshelf-landing" */ '@/site-pages/bookshelf/view/BookshelfLanding.vue');

const bookshelfRoutes: RouteConfig[] = [
    {
        path: '/bookshelf',
        component: BookshelfView,
        children: [
            {
                path: '',
                name: BookshelfRoute.BOOKSHELF,
                component: BookshelfCarouselView,
                meta: {
                    key: 'bookshelf',
                    roles: [Role.STUDENT, Role.TEACHER],
                },
                beforeEnter: (to, from, next) => {
                    (to.meta as any).from = from;
                    next();
                },
            },
            {
                path: 'nyc-enrichment',
                name: BookshelfRoute.BOOKSHELF_NYC_LANDING,
                component: BookshelfLandingView,
                meta: {
                    key: 'nyc-enrichment-bookshelf',
                    roles: [Role.TEACHER],
                },
            },
            {
                path: 'grammar-writing-intervention',
                name: BookshelfRoute.BOOKSHELF_GRAMMAR_WRITING_INTERVENTION_LANDING,
                component: BookshelfLandingView,
                meta: {
                    key: 'grammar-writing-intervention-bookshelf',
                    roles: [Role.TEACHER],
                },
            },
            {
                path: 'poetry',
                name: BookshelfRoute.BOOKSHELF_POETRY_LANDING,
                component: BookshelfLandingView,
                meta: {
                    key: 'poetry-bookshelf',
                    roles: [Role.TEACHER],
                },
            },
            {
                path: 'consolidated-details/:idNumber',
                name: BookshelfRoute.BOOKSHELF_CONSOLIDATED_DETAILS_FOR_STUDENT,
                component: ConsolidatedBookDetailsForStudentView,
                props: true,
                meta: {
                    key: 'book-details-student',
                    roles: [Role.STUDENT],
                },
            },
            {
                path: 'details/:idNumber',
                redirect: 'details/:idNumber/' + BookDetailsState.RESOURCES_VIEW,
                meta: {
                    key: 'book-details-redirect',
                    roles: [Role.TEACHER],
                },
            },
            {
                path: 'details/:idNumber/:tab',
                name: BookshelfRoute.BOOKSHELF_DETAILS,
                component: BookshelfDetailsView,
                props: true,
                meta: {
                    key: 'book-details',
                    roles: [Role.TEACHER],
                },
            },
        ],
        beforeEnter: async (to, from, next) => {
            const auth = useAuth(pinia);
            const lexileAssessmentsStore = useLexileAssessmentsStore(pinia);

            if (auth.hasStudentRole && auth.profile?.isAssessmentsEnabled) {
                await lexileAssessmentsStore.fetchCurrentLexile();
            }

            next();
        },
    },
];

export default bookshelfRoutes;
