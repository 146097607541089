import { defineStore } from 'pinia';

import { searchRestService } from '@/rest';
import { SearchTypeStatus, useCollectionStore, useSearchFiltersStore } from '@/store';

import { defaultSearchParams } from './constants';
import { omitDefaultValues } from './lib';

import type { BookModel, FiltersModel } from '@/types';
import type { SearchParamsModel, SearchState } from './types';

export const useSearchStore = defineStore('search', {
    state: (): SearchState => ({
        isActive: false,
        params: defaultSearchParams,
        results: [],
        isLoading: false,
        searchTypeStatus: SearchTypeStatus.SwitchedToSearch,
    }),

    actions: {
        async applySearch({ filters, params }: { filters: Partial<FiltersModel>; params: SearchParamsModel }) {
            const collectionStore = useCollectionStore();
            const searchFiltersStore = useSearchFiltersStore();

            this.isLoading = true;

            searchFiltersStore.setSelectedFilters(filters);
            this.setParams(params);

            const selectedFilters: FiltersModel = omitDefaultValues(
                JSON.parse(JSON.stringify(filters)),
                searchFiltersStore.defaults
            );

            try {
                const searchRequest =
                    searchRestService[
                        searchFiltersStore.selectedFilters.insideSearchAvailable
                            ? 'getFilteredBooksByInsideSearch'
                            : 'getFilteredBooks'
                    ];

                const {
                    data: { content: results, last, totalElements, totalPages },
                } = await searchRequest(selectedFilters, params);

                this.setResults(results);

                this.setParams({ last, totalElements, totalPages });

                this.searchTypeStatus =
                    SearchTypeStatus[
                        searchFiltersStore.selectedFilters.insideSearchAvailable
                            ? 'SwitchedToInsideSearch'
                            : 'SwitchedToSearch'
                    ];

                collectionStore.addBooksToCollection(results.filter((book: BookModel) => book.inCollection));
            } finally {
                this.isLoading = false;
            }
        },

        setActive(value: boolean) {
            this.isActive = value;
        },

        setResults(results: BookModel[]) {
            this.results = results;
        },

        setParams(params: Partial<SearchParamsModel>) {
            this.params = { ...this.params, ...params };
        },
    },

    getters: {
        noResults: (state: SearchState) => state.results.length === 0,
    },
});
