
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';

import LUTableCell from './LUTableCell.vue';
import { LUTableColumn } from './LUTable.vue';

@Component({
    components: {
        LUTableCell,
    },
})
export default class LUTableRow extends Vue {
    @Prop({ default: false, type: Boolean })
    bulkRow!: boolean;

    @Prop({ default: false, type: Boolean })
    header!: boolean;

    @Prop({ default: () => [] })
    columns!: LUTableColumn[];

    @Prop({ default: () => ({}) })
    row;

    @Prop()
    rowIndex!: number;

    @Prop({ default: false, type: Boolean })
    highlighted!: boolean;

    @InjectReactive()
    density!: string;

    @InjectReactive()
    hover!: string;

    isHovered = false;

    get rowClasses(): string[] {
        return [
            'lu-table-row',
            ...(this.bulkRow ? ['lu-table-row_bulk'] : []),
            (!this.header && this.hover && this.isHovered) || this.highlighted ? 'lu-table-row_highlighted' : '',
        ];
    }

    getCellSlotName(column: LUTableColumn): string {
        return `row.cell.${column.slotName ? column.slotName : column.field}`;
    }
}
