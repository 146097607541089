import { defineStore } from 'pinia';

import { carouselRestService, classroomRestService, mySettingsRestService } from '@/rest';
import { useClassroomRosterStore } from './roster';

import type { BookshelfDefaults, BookshelfSettingsDto, BookshelfSettingsViewModel, StudentModel } from '@/types';
import type { BookshelfSettingsState } from './types';

export const useBookshelfSettingsStore = defineStore('bookshelf-settings', {
    state: (): BookshelfSettingsState => ({
        defaults: {
            rows: [],
        },
        isLoading: false,
        settings: new Map(),
    }),

    actions: {
        async fetchSettings() {
            const roster = useClassroomRosterStore();

            this.isLoading = true;

            try {
                const [{ data: bookshelfSettings }, { data: rows }] = await Promise.all([
                    classroomRestService.getBookshelfSettings(),
                    carouselRestService.getStudentDefaults(),
                    roster.fetchRoster(),
                ]);

                this.defaults = {
                    ...this.defaults,
                    ...{ rows },
                };

                this.setSettings(bookshelfSettings);
            } finally {
                this.isLoading = false;
            }
        },

        async bulkUpdateBookshelfSettings(settings: BookshelfSettingsViewModel[]) {
            const { data: newSettings } = await classroomRestService.bulkUpdateBookshelfSettings(settings);

            this.setSettings(newSettings);
        },

        async updateBookshelfSettings(settings: BookshelfSettingsViewModel) {
            const { data: newSettings }: { data: BookshelfSettingsDto } =
                await classroomRestService.updateBookshelfSettings(settings);

            this.updateSettings(newSettings);
        },

        setSettings(newSettings: BookshelfSettingsDto[]) {
            const settings = new Map(this.settings);

            newSettings.forEach(({ userId, ...rest }) => settings.set(userId, { userId, ...rest }));

            this.settings = settings;
        },

        updateSettings(newSettings: BookshelfSettingsDto) {
            const { userId } = newSettings;
            const settings = new Map(this.settings);

            settings.set(userId, newSettings);

            this.settings = settings;
        },
    },

    getters: {
        defaultSettings: (state: BookshelfSettingsState): BookshelfDefaults => state.defaults,

        bookshelfSettings(state: BookshelfSettingsState): BookshelfSettingsViewModel[] {
            const roster = useClassroomRosterStore();

            const settings = roster.students.map((student: StudentModel) => ({
                ...student,
                ...state.settings.get(student.id)!,
            }));

            return settings;
        },
    },
});
