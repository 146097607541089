
import { Component, Vue } from 'vue-property-decorator';

import { LUTextInput } from '.';

enum PasswordInputType {
    PASSWORD = 'password',
    TEXT = 'text'
}

@Component({
    inheritAttrs: false,

    components: {
        LUTextInput
    }
})
export default class extends Vue {
    type: string = PasswordInputType.PASSWORD;

    get iconName(): string {
        return this.type === PasswordInputType.PASSWORD ? 'visibility' : 'visibility_off';
    }

    get iconLabel(): string {
        return this.type === PasswordInputType.PASSWORD ? 'show' : 'hide';
    }

    toggle() {
        this.type = this.type === PasswordInputType.PASSWORD ? PasswordInputType.TEXT : PasswordInputType.PASSWORD;
    }
}
