import { BookshelfRoute } from '@/router/routes/bookshelf';
import { useRouteStore } from '@/store';

const getRoutePathAfterBookFinish = () => {
    const routeStore = useRouteStore();

    const fromRoute = routeStore.previousRoute;
    const fromBeforeBookAndQuizRoute = routeStore.previousRouteBeforeBookAndQuiz;

    const defaultRoutePath = '/bookshelf/' as string;

    if (!fromRoute && !fromBeforeBookAndQuizRoute) {
        return defaultRoutePath;
    }

    const fromName = fromRoute?.name;

    const isFromQuiz = fromName === 'quiz' || fromName === 'quiz-score';
    const isFromBook = fromName === BookshelfRoute.PLAYER || fromName === BookshelfRoute.PLAYER_ON_SET_PAGE;

    const fixedFromRoute = isFromBook || isFromQuiz ? fromBeforeBookAndQuizRoute : fromRoute;

    return fixedFromRoute ? (fixedFromRoute.fullPath as string) : defaultRoutePath;
};

export { getRoutePathAfterBookFinish };
