import { AxiosPromise } from 'axios';
import qs from 'qs';

import BaseRestService from '@/common/rest/base-rest-service';

import type {
    ClassroomDetailsModel,
    ClassroomModel,
    DistrictModel,
    GroupModel,
    SchoolModel,
    UserDetailModel,
} from '@/types';

class RostersRestService extends BaseRestService {
    getClassroomDetails(classrooomId: number) {
        return super.get(`/api/roster/classrooms/${classrooomId}`);
    }

    getGroups() {
        return super.get(`/api/groups`);
    }

    getGroupStudents(params) {
        const query = qs.stringify(params, {
            addQueryPrefix: true,
            arrayFormat: 'repeat',
        });

        return super.get(`/api/groups/students${query}`, params);
    }

    getDistricts() {
        return super.get('/api/roster/districts');
    }

    getSchools(districtId: number) {
        return super.get(`/api/roster/schools/district/${districtId}`);
    }

    getSchoolsWithClassrooms(districtId: number) {
        return super.get(`/api/roster/districts/${districtId}/schools`);
    }

    getSchoolDetails(schoolId: number) {
        return super.get(`/api/roster/schools/${schoolId}`);
    }

    isUsernameAvailable(username: string): AxiosPromise<boolean> {
        return super.get(`/api/roster/users/available?username=${username}`);
    }

    getDistrictAdmins(districtId: number) {
        return super.get(`/api/district/${districtId}/admins`);
    }

    getDistrictAdminById(districtId: number, userId: number) {
        return super.get(`/api/district/${districtId}/admins/${userId}`);
    }

    getSchoolAdmins(schoolId: number) {
        return super.get(`/api/school/${schoolId}/admins`);
    }

    getSchoolAdminById(schoolId: number, userId: number) {
        return super.get(`/api/school/${schoolId}/admins/${userId}`);
    }

    updateClassroom(classroom: Partial<ClassroomDetailsModel>) {
        const { id } = classroom;

        return super.put(`/api/roster/classrooms/${id}`, classroom);
    }

    updateDistrict(district: DistrictModel) {
        return super.put(`/api/groups`, district);
    }

    createClassroom(classroom: Partial<ClassroomModel>) {
        return super.post(`/api/roster/classrooms`, classroom);
    }

    createSchool(school: GroupModel) {
        return super.post(`/api/roster/schools`, school);
    }

    createDistrictAdmin({ districtId, user }: { districtId: number; user: Partial<UserDetailModel> }) {
        return super.post(`/api/district/${districtId}/admins`, user);
    }

    updateDistrictAdmin({ districtId, user }: { districtId: number; user: Partial<UserDetailModel> }) {
        return super.put(`/api/district/${districtId}/admins/${user.id}`, user);
    }

    updateSchool(school: SchoolModel) {
        const { id, name } = school;

        return super.put(`/api/groups`, { id, name });
    }

    createSchoolAdmin({ schoolId, user }: { schoolId: number; user: Partial<UserDetailModel> }) {
        return super.post(`/api/school/${schoolId}/admins`, user);
    }

    updateSchoolAdmin({ schoolId, user }: { schoolId: number; user: Partial<UserDetailModel> }) {
        return super.put(`/api/school/${schoolId}/admins/${user.id}`, user);
    }

    createClassroomUser({ classroomId, user }: { classroomId: number; user: Partial<UserDetailModel> }) {
        return super.post(`/api/roster/classrooms/${classroomId}/users`, user);
    }

    updateClassroomUser({ classroomId, user }: { classroomId: number; user: Partial<UserDetailModel> }) {
        return super.put(`/api/roster/classrooms/${classroomId}/users/${user.id}`, user);
    }

    getClassroomUserById(classroomId: number, userId: number) {
        return super.get(`/api/roster/classrooms/${classroomId}/users/${userId}`);
    }
}

const rostersRestService: RostersRestService = new RostersRestService();

export default rostersRestService;
