import { NavigationGuard, Route } from 'vue-router';
import { useAuth } from '@/store';
import { TokenService } from '@/utils';

const authGuard = (to: Route, from: Route, next: Function): NavigationGuard => {
    const auth = useAuth();
    const isPublic = to.matched.some((record) => record.meta.public);
    const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);
    const loggedIn = !!TokenService.getAuthStatus();
    const onAccountPages = to.name === 'login';

    if (to.name === 'logout') {
        auth.logout().then(() => {
            window.location.href = '/';
        });
        return next(false);
    }

    if (to.name === 'login') {
        auth.logout().then(() => {
            if (to.query && to.query.redirect && typeof to.query.redirect === 'string') {
                const params = new URLSearchParams();
                const pathToEncode = to.query.redirect;
                params.set('redirect', encodeURIComponent(pathToEncode));
                window.location.href = `/?${params.toString()}`;
            } else {
                window.location.href = '/';
            }
        });

        return next(false);
    }

    if (!isPublic && !loggedIn) {
        const { forgetOnLogOut } = from.meta!;

        if (!onAccountPages && !forgetOnLogOut) {
            const params = new URLSearchParams();
            const pathToEncode = to.fullPath;
            params.set('redirect', encodeURIComponent(pathToEncode));
            window.location.href = `/?${params.toString()}`;
        } else {
            window.location.href = '/';
        }

        return next(false);
    }

    if (loggedIn && onlyWhenLoggedOut) {
        window.location.href = '/';
        return next(false);
    }

    return next();
};

export default authGuard;
