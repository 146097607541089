
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { LUButton } from '@/components';

@Component({
    components: {
        LUButton,
    },
})
export default class SortableList extends Vue {
    @Prop()
    items!: [];

    model = this.items;

    @Watch('items')
    onValueChange() {
        this.model = this.items;
    }

    isFirstItem(index: number): boolean {
        return index === 0;
    }

    isLastItem(index: number): boolean {
        return index === this.model.length - 1;
    }

    moveUp(index: number) {
        if (index === 0) {
            return;
        }

        this.model.splice(index - 1, 0, this.model.splice(index, 1)[0]);

        this.$emit('update', this.model);
    }

    moveDown(index: number) {
        if (index === this.model.length - 1) {
            return;
        }

        this.model.splice(index + 1, 0, this.model.splice(index, 1)[0]);
        this.$emit('update', this.model);
    }
}
