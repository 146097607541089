import { AxiosPromise } from 'axios';
import qs from 'qs';

import { serializeQueryString } from '@/utils/serialize-query-string';
import BaseRestService from '@/common/rest/base-rest-service';
import { ReportRequestModel } from '@/types/reports';
import { formattedDate } from '@/filters';
import { StudentReportActivity } from '@/site-pages/reports/model/student-report-model';

const formatDateForApi = (date: Date) => formattedDate(date, 'yyyy-MM-dd');

interface BuildQueryProps {
    startDate: Date;
    endDate: Date;
    language?: string;
    grade?: string;
    groupIds?: number[];
    reportType?: string;
    classroomIds?: number[] | string[];
    readingGroupIds?: number[];
}

class ReportsRestService extends BaseRestService {
    getStudentAchievements(studentId: number) {
        return super.get(`/api/report/student/${studentId}/achievement`);
    }

    getStudentInfo(studentId: number, startDate: Date, endDate: Date, language: string) {
        const query = this.buildQuery({ startDate, endDate, language });
        const apiUrl = `/api/report/student/${studentId}${query}`;
        return super.get(apiUrl);
    }

    getStudentMyReport({ period = 0 } = {}): AxiosPromise<StudentReportActivity> {
        const apiUrl = `/api/report/student${period ? `?period=${period}` : ''}`;
        return super.get(apiUrl);
    }

    getStudentSkillsStandards(studentId: number, startDate: Date, endDate: Date, language: string) {
        const query = this.buildQuery({ startDate, endDate, language });
        const apiUrl = `/api/report/student/${studentId}/skillsandstandards${query}`;
        return super.get(apiUrl);
    }

    getStudentActivityBreakdown(studentId: number, startDate: Date, endDate: Date, language: string) {
        const query = this.buildQuery({ startDate, endDate, language });
        const apiUrl = `/api/report/student/${studentId}/activitybreakdown${query}`;
        return super.get(apiUrl);
    }

    getStudentSkillOrStandard({ studentId, id, type, startDate, endDate, language }: ReportRequestModel) {
        const query = this.buildQuery({ startDate, endDate, language });
        const apiUrl = `/api/report/student/${studentId}/${type}/${id}${query}`;
        return super.get(apiUrl);
    }

    getClassroom({ id, startDate, endDate, language, grade, classroomIds, readingGroupIds }: ReportRequestModel) {
        const query = this.buildQuery({
            startDate,
            endDate,
            language,
            grade,
            readingGroupIds,
            classroomIds: id ? [id] : classroomIds,
        });
        const apiUrl = `/api/report/classroom${query}`;
        return super.get(apiUrl);
    }

    getSchoolReport({ id, startDate, endDate, language, grade }: ReportRequestModel) {
        const query = this.buildQuery({ startDate, endDate, language, grade });
        const apiUrl = `/api/report/school${id ? `/${id}` : ''}${query}`;
        return super.get(apiUrl);
    }

    getDistrictReport({ id, startDate, endDate, language, grade }: ReportRequestModel) {
        const query = this.buildQuery({ startDate, endDate, language, grade });
        const apiUrl = `/api/report/district${id ? `/${id}` : ''}${query}`;
        return super.get(apiUrl);
    }

    getMultiDistrictReport({ startDate, endDate, language, grade, groupIds }: ReportRequestModel) {
        const query = this.buildQuery({ startDate, endDate, language, grade, groupIds });
        const apiUrl = `/api/report/multi-district${query}`;
        return super.get(apiUrl);
    }

    getTotalReportData({ startDate, endDate, language, grade, groupIds }: ReportRequestModel) {
        const query = this.buildQuery({ startDate, endDate, language, grade, groupIds });
        const apiUrl = `/api/report/total-data${query}`;
        return super.get(apiUrl);
    }

    getMultiDistrictTotalSkills({ startDate, endDate, language, grade, groupIds }: ReportRequestModel) {
        const query = this.buildQuery({ startDate, endDate, language, grade, groupIds });
        const apiUrl = `/api/report/total-skills${query}`;
        return super.get(apiUrl);
    }

    getTotalDistrictReportData({ id, startDate, endDate, language, grade, groupIds, reportType }: ReportRequestModel) {
        const query = this.buildQuery({ startDate, endDate, language, grade, groupIds, reportType });
        const apiUrl = `/api/report/district/total-district-data${id ? `/${id}` : ''}${query}`;
        return super.get(apiUrl);
    }

    getDistrictTotalSkills({ id, startDate, endDate, language, grade, groupIds, reportType }: ReportRequestModel) {
        const query = this.buildQuery({ startDate, endDate, language, grade, groupIds, reportType });
        const apiUrl = `/api/report/district/total-district-skills${id ? `/${id}` : ''}${query}`;
        return super.get(apiUrl);
    }

    getRosterSkillsStandards(
        type: string,
        id: string,
        startDate: Date,
        endDate: Date,
        language: string,
        grade: string,
        groupIds: number[],
        classroomIds: number[]
    ) {
        const suffix = !Number.isNaN(Number.parseInt(id)) ? `${id}/skillsandstandards` : 'skillsandstandards';
        const options = { startDate, endDate, language, grade, classroomIds };
        const query = groupIds.length ? this.buildQuery({ ...options, groupIds }) : this.buildQuery(options);
        const apiUrl = `/api/report/${type}/${suffix}${query}`;
        return super.get(apiUrl);
    }

    getSkillStandardDetails(
        skillStandardId: string,
        type: string,
        reportType: string,
        id: string,
        startDate: Date,
        endDate: Date,
        language: string,
        grade: string,
        groupIds: number[],
        classroomIds: number[]
    ) {
        const query = serializeQueryString({
            type,
            reportType,
        });
        let options: BuildQueryProps = { startDate, endDate, language, grade, classroomIds: id ? [id] : classroomIds };
        if (groupIds.length) options = { ...options, groupIds };

        const filterQuery = this.buildQuery(options).replace('?', '&');
        const fullQuery = `${query}${filterQuery}`;
        const apiUrl = `/api/report/breakdown/${skillStandardId}${fullQuery}`;
        return super.get(apiUrl);
    }

    private buildQuery({
        startDate,
        endDate,
        language,
        grade,
        groupIds,
        reportType,
        classroomIds,
        readingGroupIds,
    }: BuildQueryProps): string {
        const params = {
            startDate: formatDateForApi(startDate),
            endDate: formatDateForApi(endDate),
            language,
            classroomIds: classroomIds || null,
            readingGroupIds: readingGroupIds || null,
        };
        let query: string;
        if (grade) {
            query = serializeQueryString({
                ...params,
                grade,
            });
            if (groupIds) {
                query = serializeQueryString({
                    ...params,
                    grade,
                    groupIds,
                    reportType,
                });
            } else {
                query = serializeQueryString({
                    ...params,
                    grade,
                    reportType,
                });
            }
        } else {
            query = serializeQueryString({
                ...params,
            });
            if (groupIds) {
                query = serializeQueryString({
                    ...params,
                    groupIds,
                    reportType,
                });
            } else {
                query = serializeQueryString({
                    ...params,
                    reportType,
                });
            }
        }

        return query;
    }

    getStudentPhonicsReport({ studentId, startDate, endDate }: ReportRequestModel) {
        const apiUrl = `/api/report/phonics/student/${studentId}?startDate=${formatDateForApi(
            startDate
        )}&endDate=${formatDateForApi(endDate)}`;

        return super.get(apiUrl);
    }

    getActivityReportCSV({
        startDate,
        endDate,
        orderBy,
        orderType,
        language,
        grade,
        groupIds,
        groupBy = [],
    }: ReportRequestModel) {
        const groupParams = groupBy.reduce((acc, key) => {
            acc[key] = true;
            return acc;
        }, {});
        const query = serializeQueryString({
            startDate: formatDateForApi(startDate),
            endDate: formatDateForApi(endDate),
            orderBy,
            orderType,
            language,
            grade,
            ...groupParams,
            ...(groupIds?.length && { groupIds }),
        });
        const apiUrl = `/api/report/export/district/activity${query}`;

        return super.get(apiUrl);
    }

    getGrades(groupIds) {
        const query = qs.stringify(groupIds, {
            addQueryPrefix: true,
            arrayFormat: 'repeat',
        });
        return super.get(`/api/grades${query}`);
    }

    getRunningRecordsAssessments({
        studentId,
        startDate,
        endDate,
    }: {
        studentId: number;
        startDate: Date;
        endDate: Date;
    }) {
        const query = serializeQueryString({
            startDate: formatDateForApi(startDate),
            endDate: formatDateForApi(endDate),
        });

        return super.get(`/api/report/running-records/${studentId}${query}`);
    }

    getReadAloudAssessments({ studentId, startDate, endDate }: { studentId: number; startDate: Date; endDate: Date }) {
        const query = serializeQueryString({
            startDate: formatDateForApi(startDate),
            endDate: formatDateForApi(endDate),
        });

        return super.get(`/api/report/read-aloud/${studentId}${query}`);
    }
}

const reportsRestService: ReportsRestService = new ReportsRestService();

export { reportsRestService };
