import axios, { CancelTokenSource } from 'axios';
import { defineStore } from 'pinia';

import { useAuth } from '@/store';
import { BookModel, BookPlayerState } from '@/types';
import router, { BookshelfRoute } from '@/router';
import bookshelfRestService from '@/site-pages/bookshelf/rest/bookshelf-rest-service';

import { ColibrioCookiesHelper } from './cookies-helper';
import { useActivitiesStore } from './activities';
import bookshelf from '@/router/routes/bookshelf';

interface IBookReaderState {
    status: BookPlayerState;
    bookLocation: string;
    bookId: string;
    book: BookModel;
    file: Blob | null;
    cancelSource: CancelTokenSource | null;
    closeMenuFlag: boolean;
    syncMediaPlayerPlaying: boolean;
}

export const useBookReaderStore = defineStore('book-reader', {
    state: (): IBookReaderState => ({
        status: 'loading',
        bookLocation: ColibrioCookiesHelper.getBookLocationCookie(),
        bookId: ColibrioCookiesHelper.getBookIdCookie(),
        book: {} as BookModel,
        file: null,
        cancelSource: null,
        closeMenuFlag: false,
        syncMediaPlayerPlaying: false,
    }),
    actions: {
        async finishBook(bookIdNumber: string, nextRoutePath: string, voiceRecordingLinkId?: string) {
            const auth = useAuth();
            const activities = useActivitiesStore();

            this.status = 'loading';

            try {
                await activities.finishBook(bookIdNumber, voiceRecordingLinkId);

                if (auth.hasTeacherRole) {
                    return router.push(nextRoutePath);
                }

                activities.showPoints = true;
            } catch (error) {
                router.push(nextRoutePath);
            } finally {
                this.status = 'finished';
            }
        },
        async loadBookForReader(bookIdNumber: string, isEmbeddedBookReader = false) {
            this.status = 'loading';

            try {
                const cancelSource: CancelTokenSource = axios.CancelToken.source();

                this.cancelSource = cancelSource;

                const [bookresult, response] = await Promise.all([
                    bookshelfRestService.getBookDetails(bookIdNumber, { cancelToken: cancelSource.token }),
                    bookshelfRestService.getBookFile(bookIdNumber, { cancelToken: cancelSource.token }),
                ]);

                this.book = bookresult.data;
                this.file = new Blob([response.data]);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    const { path, query, hash } = router.currentRoute;
                    if (!isEmbeddedBookReader) {
                        router.push({
                            name: 'not-found',
                            params: { pathMatch: path },
                            query,
                            hash,
                        });
                    }
                }
            } finally {
                this.status = 'finished';
            }
        },
        changeBookLocation(bookLocation: string) {
            ColibrioCookiesHelper.setBookLocationCookie(bookLocation);
            this.bookLocation = bookLocation;
        },
        changeBookId(bookId: string) {
            ColibrioCookiesHelper.setBookIdCookie(bookId);
            this.bookId = bookId;
        },
        deleteBookCookies() {
            ColibrioCookiesHelper.deleteCookies();
            this.bookLocation = '';
            this.bookId = '';
        },
        clear() {
            this.book = {} as BookModel;
            this.status = 'loading';
            this.bookLocation = ColibrioCookiesHelper.getBookLocationCookie();
            this.bookId = ColibrioCookiesHelper.getBookIdCookie();
            this.file = null;
            if (this.cancelSource) {
                this.cancelSource.cancel('Cancel Colibrio previous requests.');
            }
            this.cancelSource = null;
            this.closeMenuFlag = false;
            this.syncMediaPlayerPlaying = false;
        },
        setCloseMenuFlag(value: boolean) {
            this.closeMenuFlag = value;
        },
    },
});
