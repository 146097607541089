
import { Component, Prop, Vue } from 'vue-property-decorator';
import Paginate from 'vuejs-paginate';

import { formatNumber, pluralize } from '@/filters';

@Component({
    components: {
        Paginate
    }
})
export default class LUTablePagination extends Vue {
    @Prop({ default: 0, type: Number })
    currentPage!: number;

    @Prop({ default: 20, type: Number })
    pageSize!: number;

    @Prop({ default: false, type: Boolean })
    inline!: number;

    @Prop()
    total!: number;

    get pageModel(): number {
        return this.currentPage + 1;
    }

    set pageModel(value: number) {
        this.$emit('currentPage:update', value - 1);
    }

    get summary(): string {
        const { resultsRange, total } = this;
        const resultsWord = pluralize('row', total);

        return `Showing ${resultsRange} ${resultsWord}`;
    }

    get resultsRange(): string {
        const { endingNumber, startingNumber, total } = this;

        return `${formatNumber(startingNumber)}&ndash;${formatNumber(endingNumber)} of ${formatNumber(total)}`;
    }

    get startingNumber(): number {
        const { currentPage, pageSize } = this;

        return currentPage * pageSize + 1;
    }

    get endingNumber(): number {
        const { pageSize } = this;
        const endingNumber = this.startingNumber + pageSize - 1;

        return endingNumber > this.total ? this.total : endingNumber;
    }

    get pageCount(): number {
        return Math.ceil(this.total / this.pageSize);
    }

    get pageRange(): number {
        return this.inline ? 0 : 10;
    }

    get hasPages(): boolean {
        return this.pageCount > 1;
    }

    get pagesOverfow(): boolean {
        const { pageCount } = this;
        return !this.inline && pageCount > 10;
    }

    onPaginationChange(pageNum) {
        this.$emit('change', pageNum);
    }
}
