import numeral from 'numeral';

import capitalize from './capitalize';
import fullName from './fullname';
import pluralize from './pluralize';

export * from './book-title';
export * from './date';
export * from './format-due-date';

export const commaSeparated = (value: string[] | number[] = []): string => {
    return value.join(', ');
};

export const formattedLexile = (value?: number, fallbackValue: string = ''): string => {
    if (value === null || value === undefined) {
        return fallbackValue;
    }

    return value < 0 ? `BR${Math.abs(value)}L` : `${value}L`;
};

export const formattedLexileRange = (range: number[] = [0, 0]): string => {
    const [min, max] = range;
    return `${formattedLexile(min)}-${formattedLexile(max)}`;
};

export const lexileGrowth = (value: number, fallbackValue: string = ''): string => {
    switch (value) {
        case null:
        case undefined:
            return fallbackValue;
        case 0:
            return '0L';
        default:
            return (value > 0 ? '+' : '–') + `${Math.abs(value)}L`;
    }
};

export const formatAverageQuizScore = (value: number | null | undefined) => {
    if (!value) return 'N/A';

    return `${value.toFixed(2)}%`;
};

export const formatNumber = (value: number): string => {
    return numeral(value).format('0,0');
};

export const firstLetter = (value: string = ''): string => {
    return value.charAt(0);
};

export const plural = (value: number, word: string): string => {
    return `${value} ${word}${value == 1 ? '' : 's'}`;
};

export const fullNameReverse = (value: { surname: string; name: string }): string => {
    return [value.surname, value.name].filter(Boolean).join(', ');
};

export const formatStudentName = (student: { name: string; surname: string; username: string }): string => {
    if (!student.name && !student.surname) {
        return student.username;
    }

    return fullNameReverse(student);
};

export const formatUserName = (
    user: { name: string; surname: string; username: string },
    reverse: boolean = false
): string => {
    if (!user?.name?.trim() && !user?.surname?.trim()) {
        return '[No Name]';
    }

    return reverse ? fullNameReverse(user) : fullName(user);
};

export const dash = <T>(value: T | string = ''): string | T => {
    return value || '—';
};

export { capitalize, fullName, pluralize };

export const formatTimeDuration = (durationInSeconds: number): string => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${minutes}:${returnedSeconds}`;
};
