import { formatStudentName } from '@/filters';

export function sortStudentsByFullName(rows: any[], isDescSort = true) {
    return rows.sort((a, b) => {
        let nameA = formatStudentName(a);
        let nameB = formatStudentName(b);

        if (!nameA && !nameB) {
            nameA = formatStudentName(a.student);
            nameB = formatStudentName(b.student);
        }

        if (nameA) {
            if (isDescSort) {
                const comparisonResult = nameA.localeCompare(nameB);

                return comparisonResult === 0 ? comparisonResult : comparisonResult > 0 ? -1 : 1;
            } else {
                return nameA.localeCompare(nameB);
            }
        } else {
            return 0;
        }
    });
}
