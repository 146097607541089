import { GetterTree } from 'vuex';

import { RootState } from '@/store';
import { ReportState, ReportGetterNames } from './types';

const getters: GetterTree<ReportState, RootState> = {
    [ReportGetterNames.filters]: (state: ReportState) => state.filters,

    [ReportGetterNames.getBookQuizzes]: (state: ReportState) => state.bookQuizzes,

    [ReportGetterNames.getReportRequest]: (state: ReportState) => state.reportRequestModel,

    [ReportGetterNames.isLoading]: (state: ReportState) => state.isLoading,

    [ReportGetterNames.studentPhonicsReport]: (state: ReportState) => state.studentPhonicsReport,

    [ReportGetterNames.reportModel]: (state: ReportState) => state.reportModel,

    [ReportGetterNames.grades]: (state: ReportState) => state.grades,

    [ReportGetterNames.totalReportData]: (state: ReportState) => state.totalReportData,

    [ReportGetterNames.totalSkills]: (state: ReportState) => state.totalSkills
};

export default getters;
