import type { UserDetailModel } from '@/types';

export type AuthState = {
    isAuthenticated: boolean;
    error: string | null;
    loading: boolean;
    role?: string | null;
    username: string | null;
    profile: UserDetailModel | null;
    userToken: string | null;
    fetchingProfile: boolean;
    interfaceType: InterfaceType | null;
    isFullInterfaceType: boolean | null;
};

export enum InterfaceType {
    CLASSIC = 'CLASSIC',
    NEXT = 'NEXT',
    FULL = 'FULL',
}

export enum StudentInterfaceTypeSetting {
    LOWER_ELEMENTARY = 'LOWER_ELEMENTARY',
    UPPER_ELEMENTARY = 'UPPER_ELEMENTARY',
}
