
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RostersLayout extends Vue {
    @Prop({
        type: Function,
        required: true
    })
    default!: Vue;

    get getLayout() {
        return this.$route.meta!.layout || this.default;
    }
}
