
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LUListItem extends Vue {
    @Prop({ default: false, type: Boolean })
    clickable!: boolean;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    render(createElement) {
        const label = createElement(
            'span',
            {
                class: 'lu-list-item__label'
            },
            this.$slots.default
        );

        const arrow = createElement(
            'span',
            {
                class: 'lu-list-item__arrow'
            },
            [
                createElement(
                    'span',
                    {
                        class: 'material-icons'
                    },
                    'chevron_right'
                )
            ]
        );

        return createElement(
            'div',
            {
                class: ['lu-list-item', this.clickable && 'lu-list-item_clickable'],
                on: {
                    click: event => {
                        event.preventDefault();

                        if (this.disabled) {
                            return;
                        }

                        this.$emit('click');
                    }
                }
            },
            [label, ...[this.clickable ? [arrow] : []]]
        );
    }
}
