import axios from 'axios';
import { ActionContext, ActionTree } from 'vuex';

import rostersRestService from '@/rest/rosters';
import { RootState } from '@/store';
import {
    ClassroomDetailsModel,
    DistrictModel,
    SchoolDto,
    SchoolModel,
    GroupItem,
    UserDetailModel,
    UserModel,
} from '@/types';
import { RostersState, RostersActions, RostersProgresses, RostersMutations } from './types';
import router from '@/router';

const actions: ActionTree<RostersState, RootState> = {
    [RostersActions.fetchClassroomDetails]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        classroomId: number
    ) => {
        dispatch('wait/start', RostersProgresses.fetchingClassroomDetails, { root: true });

        try {
            const { data: classroom }: { data: ClassroomDetailsModel } = await rostersRestService.getClassroomDetails(
                classroomId
            );

            commit(RostersMutations.updateClassroom, classroom);

            return classroom;
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingClassroomDetails, { root: true });
        }
    },

    [RostersActions.fetchDistricts]: async ({ commit, dispatch }: ActionContext<RostersState, RootState>) => {
        dispatch('wait/start', RostersProgresses.fetchingDistricts, { root: true });

        try {
            const { data: districts } = await rostersRestService.getDistricts();

            commit(RostersMutations.setDistricts, districts);

            return districts;
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingDistricts, { root: true });
        }
    },

    [RostersActions.saveDistrict]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        district: DistrictModel
    ) => {
        dispatch('wait/start', RostersProgresses.districtSaving, { root: true });

        try {
            const { data: updatedDistrict } = await rostersRestService.updateDistrict(district);

            commit(RostersMutations.updateDistrict, updatedDistrict);
        } finally {
            dispatch('wait/end', RostersProgresses.districtSaving, { root: true });
        }
    },

    [RostersActions.fetchSchools]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        districtId: number
    ) => {
        dispatch('wait/start', RostersProgresses.fetchingSchools, { root: true });

        try {
            const { data: schools }: { data: SchoolDto[] } = await rostersRestService.getSchools(districtId);

            commit(RostersMutations.setSchools, schools);
            commit(RostersMutations.setDistrictSchools, { districtId, schools });
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingSchools, { root: true });
        }
    },

    [RostersActions.fetchSchoolsWithClassrooms]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        districtId: number
    ) => {
        dispatch('wait/start', RostersProgresses.fetchingSchools, { root: true });

        try {
            const {
                data: schools,
            }: {
                data: GroupItem[];
            } = await rostersRestService.getSchoolsWithClassrooms(districtId);

            commit(RostersMutations.setSchoolsWithClassrooms, schools);
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingSchools, { root: true });
        }
    },

    [RostersActions.fetchSchoolDetails]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        schoolId: number
    ) => {
        dispatch('wait/start', RostersProgresses.fetchingSchoolDetails, { root: true });

        try {
            const { data: school }: { data: SchoolDto } = await rostersRestService.getSchoolDetails(schoolId);

            commit(RostersMutations.updateSchool, school);
            commit(RostersMutations.setClassrooms, school.classrooms);
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingSchoolDetails, { root: true });
        }
    },

    [RostersActions.fetchDistrictAdmins]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        districtId: number
    ) => {
        dispatch('wait/start', RostersProgresses.fetchingDistrictAdmins, { root: true });

        try {
            const { data: users }: { data: UserModel[] } = await rostersRestService.getDistrictAdmins(districtId);

            commit(RostersMutations.setGroupAdmins, { groupId: districtId, users });
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingDistrictAdmins, { root: true });
        }
    },

    [RostersActions.fetchDistrictAdminById]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        { districtId, userId }: { districtId: number; userId: number }
    ) => {
        dispatch('wait/start', RostersProgresses.fetchingDistrictAdmins, { root: true });

        try {
            const { data: user }: { data: UserModel } = await rostersRestService.getDistrictAdminById(
                districtId,
                userId
            );

            commit(RostersMutations.setGroupAdmins, { groupId: districtId, users: [user] });
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingDistrictAdmins, { root: true });
        }
    },

    [RostersActions.saveDistrictAdmin]: async (
        { dispatch }: ActionContext<RostersState, RootState>,
        { districtId, user }: { districtId: number; user: Partial<UserDetailModel> }
    ) => {
        dispatch('wait/start', RostersProgresses.districtAdminSaving, { root: true });

        const { id } = user;

        try {
            const { data: districtAdmin }: { data: UserDetailModel } = id
                ? await rostersRestService.updateDistrictAdmin({ districtId, user })
                : await rostersRestService.createDistrictAdmin({ districtId, user });

            return districtAdmin;
        } finally {
            dispatch('wait/end', RostersProgresses.districtAdminSaving, { root: true });
        }
    },

    [RostersActions.fetchSchoolAdmins]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        schoolId: number
    ) => {
        dispatch('wait/start', RostersProgresses.fetchingSchoolAdmins, { root: true });

        try {
            const { data: users }: { data: UserModel[] } = await rostersRestService.getSchoolAdmins(schoolId);

            commit(RostersMutations.setGroupAdmins, { groupId: schoolId, users });
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingSchoolAdmins, { root: true });
        }
    },

    [RostersActions.fetchSchoolAdminById]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        { schoolId, userId }: { schoolId: number; userId: number }
    ) => {
        dispatch('wait/start', RostersProgresses.fetchingSchoolAdmins, { root: true });

        try {
            const { data: user }: { data: UserModel } = await rostersRestService.getSchoolAdminById(schoolId, userId);

            commit(RostersMutations.setGroupAdmins, { groupId: schoolId, users: [user] });
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingSchoolAdmins, { root: true });
        }
    },

    [RostersActions.saveSchoolAdmin]: async (
        { dispatch }: ActionContext<RostersState, RootState>,
        { schoolId, user }: { schoolId: number; user: Partial<UserDetailModel> }
    ) => {
        dispatch('wait/start', RostersProgresses.schoolAdminSaving, { root: true });

        const { id } = user;

        try {
            const { data: schoolAdmin }: { data: UserDetailModel } = id
                ? await rostersRestService.updateSchoolAdmin({ schoolId, user })
                : await rostersRestService.createSchoolAdmin({ schoolId, user });

            return schoolAdmin;
        } finally {
            dispatch('wait/end', RostersProgresses.schoolAdminSaving, { root: true });
        }
    },

    [RostersActions.saveSchool]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        school: SchoolModel
    ) => {
        dispatch('wait/start', RostersProgresses.schoolSaving, { root: true });

        const { id } = school;

        try {
            let updatedSchool: SchoolModel;

            if (id) {
                await rostersRestService.updateSchool(school);
                const { data }: { data: SchoolModel } = await rostersRestService.getSchoolDetails(id);
                updatedSchool = data;
            } else {
                const { data }: { data: SchoolModel } = await rostersRestService.createSchool(school);
                updatedSchool = data;
            }

            commit(RostersMutations.updateSchool, updatedSchool);
        } finally {
            dispatch('wait/end', RostersProgresses.schoolSaving, { root: true });
        }
    },

    [RostersActions.saveClassroom]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        classroom: ClassroomDetailsModel
    ) => {
        dispatch('wait/start', RostersProgresses.classroomSaving, { root: true });

        try {
            const { data: updatedClassroom }: { data: ClassroomDetailsModel } =
                await rostersRestService.updateClassroom(classroom);

            commit(RostersMutations.updateClassroom, updatedClassroom);
        } finally {
            dispatch('wait/end', RostersProgresses.classroomSaving, { root: true });
        }
    },

    [RostersActions.createClassroom]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        payload: Partial<ClassroomDetailsModel>
    ) => {
        dispatch('wait/start', RostersProgresses.classroomSaving, { root: true });

        try {
            const { parentGroupId: schoolId } = payload;
            const { data: classroom }: { data: ClassroomDetailsModel } = await rostersRestService.createClassroom(
                payload
            );

            commit(RostersMutations.addClassroom, classroom);
            commit(RostersMutations.addClassroomToSchool, { schoolId, classroom: classroom });
        } finally {
            dispatch('wait/end', RostersProgresses.classroomSaving, { root: true });
        }
    },

    [RostersActions.validateUsername]: async (context: ActionContext<RostersState, RootState>, username: string) => {
        const { data: isAvailable } = await rostersRestService.isUsernameAvailable(username);

        return isAvailable;
    },

    [RostersActions.saveClassroomUser]: async (
        { dispatch }: ActionContext<RostersState, RootState>,
        { classroomId, user }: { classroomId: number; user: Partial<UserDetailModel> }
    ) => {
        dispatch('wait/start', RostersProgresses.userSaving, { root: true });

        const { id } = user;

        try {
            const { data: rosterUser }: { data: UserDetailModel } = id
                ? await rostersRestService.updateClassroomUser({ classroomId, user })
                : await rostersRestService.createClassroomUser({ classroomId, user });

            return rosterUser;
        } finally {
            dispatch('wait/end', RostersProgresses.userSaving, { root: true });
        }
    },

    [RostersActions.fetchClassroomUserById]: async (
        { commit, dispatch }: ActionContext<RostersState, RootState>,
        { classroomId, userId }: { classroomId: number; userId: number }
    ) => {
        dispatch('wait/start', RostersProgresses.fetchingClassroomUsers, { root: true });

        try {
            const { data: user }: { data: UserModel } = await rostersRestService.getClassroomUserById(
                classroomId,
                userId
            );

            commit(RostersMutations.setClassroomUsers, { classroomId, users: [user] });
        } catch (error) {
            if (!axios.isCancel(error)) {
                router.push('/rosters');
            }
        } finally {
            dispatch('wait/end', RostersProgresses.fetchingClassroomUsers, { root: true });
        }
    },
};

export default actions;
