
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { DATE_FORMAT } from '@/constants';
import { formattedDate } from '@/filters';
import { LUButton } from '@/components';
import Popover from '@/common/components/Popover.vue';

@Component({
    components: {
        LUButton,
        Popover
    }
})
export default class AssignmentDatePicker extends Vue {
    @Prop()
    maxDate!: Date;

    @Prop({ default: () => new Date() })
    minDate!: Date;

    @Prop({ default: 'Select date...' })
    placeholder!: string;

    @Prop()
    value!: Date;

    $refs!: {
        button: HTMLElement;
        popover: Popover;
    };

    model: Date | null = null;

    isOpen = false;

    created() {
        if (this.value) {
            this.model = new Date(this.value);
        }
    }

    @Watch('value')
    onValueChange() {
        this.model = new Date(this.value);
    }

    get label(): string {
        if (!this.value) {
            return this.placeholder;
        }

        return formattedDate(this.value);
    }

    save() {
        this.$emit('input', this.model ? formattedDate(this.model, DATE_FORMAT.API) : null);
        this.$refs.popover.hide();
    }

    cancel() {
        this.$refs.popover.hide();
    }
}
