import { NavigationGuard, Route } from 'vue-router';

import { pinia, useAuth } from '@/store';

const searchRouteGuard = (to: Route, from: Route, next: Function): NavigationGuard => {
    const auth = useAuth(pinia);

    (to.meta as any).from = from;

    if (auth.profile?.searchSettings && !auth.profile.searchSettings.search) {
        return next('/');
    }

    return next();
};

export default searchRouteGuard;
