import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';

const assignmentsRoutes: RouteConfig[] = [
    {
        path: '/assignments',
        name: 'assignments',
        redirect: { name: 'assignments.log' },
        component: () => import(/* webpackChunkName: "assignments" */ '@/site-pages/assignments/AssignmentsView.vue'),
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER],
        },
        children: [
            {
                path: 'log',
                name: 'assignments.log',
                component: () =>
                    import(/* webpackChunkName: "assignments" */ '@/site-pages/assignments/views/AssignmentLog.vue'),
                meta: {
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                },
            },
            {
                path: 'wizard',
                name: 'assignments.wizard',
                component: () =>
                    import(/* webpackChunkName: "assignments" */ '@/site-pages/assignments/views/AssignmentWizard.vue'),
                meta: {
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                },
            },
        ],
    },
];

export default assignmentsRoutes;
