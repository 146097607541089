import { differenceInDays, endOfDay, parse } from 'date-fns';

export const formattedDueDate = (date: string) => {
    const MAX_DAYS_IN_PAST = -30;
    const MAX_DAYS_IN_FUTURE = 30;

    const today = endOfDay(new Date());
    const dueDateTime = endOfDay(parse(date, 'yyyy-MM-dd', new Date()));
    const diff = differenceInDays(dueDateTime, today);

    if (diff < MAX_DAYS_IN_PAST) {
        return `Due ${Math.abs(MAX_DAYS_IN_PAST)}+ Days Ago`;
    }

    if (diff == -1) {
        return 'Due 1 Day Ago';
    }

    if (diff < 0 && diff >= MAX_DAYS_IN_PAST) {
        return `Due ${Math.abs(diff)} Days Ago`;
    }

    if (diff == 1) {
        return 'Due in 1 Day';
    }

    if (diff > 0 && diff <= MAX_DAYS_IN_FUTURE) {
        return `Due in ${diff} Days`;
    }

    if (diff > MAX_DAYS_IN_FUTURE) {
        return `Due in ${MAX_DAYS_IN_FUTURE}+ Days`;
    }

    return 'Due Today';
};
