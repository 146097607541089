import { BookModel } from '@/types/books';

export enum CarouselItemType {
    BOOK = 'book',
    RUNNING_RECORD = 'running-record',
}

export interface CarouselModel {
    id: string;
    title: string;
    subTitle: string;
    books: CarouselItem[];
    exclusive: boolean;
}

export interface CarouselItem extends BookModel {
    entityType: CarouselItemType;
}
