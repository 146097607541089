
import { Component, Prop, Vue } from 'vue-property-decorator';
import { directive as onClickaway } from 'vue-clickaway';
import { BPopover } from 'bootstrap-vue';

@Component({
    components: {},

    directives: {
        onClickaway,
    },
})
export default class Popover extends Vue {
    @Prop({ default: 'viewport' })
    public boundary!: string;

    @Prop({ default: true })
    public hideOnScroll!: boolean;

    @Prop({ default: false, type: Boolean })
    public overlay!: boolean;

    @Prop()
    public title!: string;

    @Prop()
    public triggers!: string;

    @Prop({ default: 'left' })
    public placement!: string;

    @Prop({ required: true })
    public target!: string;

    @Prop({ required: false })
    public customClass!: string;

    @Prop({ default: false })
    disabled!: boolean;

    $refs!: {
        popover: BPopover;
    };

    show: boolean = false;

    hide() {
        if (this.$refs.popover?.show) {
            this.$emit('before-close');
            this.$refs.popover.$emit('close');
        }
    }

    showed() {
        this.$emit('showed');

        if (this.hideOnScroll) {
            window.addEventListener('scroll', this.hide, false);
        }
    }

    hidden() {
        this.$emit('hidden');

        if (this.hideOnScroll) {
            window.removeEventListener('scroll', this.hide, false);
        }
    }

    onShow() {
        this.$emit('show');
    }
}
