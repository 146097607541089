import { RouteConfig } from 'vue-router';

import { ReportType } from '@/site-pages/reports/model/report-constants';
import { Role } from '@/constants';

export enum MultiDistrictReportRoute {
    MULTI_DISTRICT = 'report.multi-district',
    MULTI_DISTRICT_ACTIVITY = 'report.multi-district.activity',
}

const MultiDistrictReportView = () =>
    import(/* webpackChunkName: "reports-md" */ '@/site-pages/reports/view/report/MultiDistrictReport.vue');

const SkillsStandardsBreakdownReportView = () =>
    import(/* webpackChunkName: "reports-md" */ '@/site-pages/reports/view/activity/SkillsStandardsBreakdown.vue');

const miltiDistrictRoutes: RouteConfig[] = [
    {
        path: 'multi-district',
        name: MultiDistrictReportRoute.MULTI_DISTRICT,
        props: {
            reportType: ReportType.REPORT_MULTI_DISTRICT,
        },
        component: MultiDistrictReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER],
        },
    },
    {
        path: 'multi-district/activity',
        name: MultiDistrictReportRoute.MULTI_DISTRICT_ACTIVITY,
        props: {
            type: ReportType.REPORT_MULTI_DISTRICT,
        },
        component: SkillsStandardsBreakdownReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER],
        },
    },
];

export default miltiDistrictRoutes;
