import { GroupAssignment, StudentAssignment } from './assignments';
import { MultipartType } from '@/shared/model/book-details-model';

export interface BookModel {
    id: number;
    idNumber: string;
    bookGroupId: number;
    title: string;
    titlePrefix: string;
    lexileCode: string;
    lexileWeight: number;
    bookType: string;
    cover: string;
    inCollection: boolean;
    tagsIds: string[];
    shelfRangeLow: number | null;
    shelfRangeHigh: number | null;
    phonic: boolean;
    poetry?: boolean;
    altText?: string;
    dueDate?: string;
    pageNumber?: string;
    pageHeader?: string;
    content?: string;
    containerId?: string;
    query?: string;
    parts?: BookModel[];
    multipartType: MultipartType;
    previousPartBook?: {
        number: number;
        recap: string;
    };
}

export enum BookLanguage {
    ENGLISH = 'English',
    SPANISH = 'Spanish',
    BILINGUAL = 'Bilingual',
}

export interface BookAssignments {
    groups: GroupAssignment[];
    students: StudentAssignment[];
}

export interface IBookCompletetion {
    id: number;
    idNumber: string;
    completed: boolean;
}
