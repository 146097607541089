import { defineStore } from 'pinia';
import { Route } from 'vue-router';

interface IRouteStoreState {
    previousRoute?: Route;
    previousRouteBeforeBookAndQuiz?: Route;
}

export const useRouteStore = defineStore('route', {
    state: (): IRouteStoreState => ({ previousRoute: undefined }),
    actions: {
        setpreviousRoute(value: Route) {
            this.previousRoute = value;
        },
        setPreviousRouteBeforeBookAndQuiz(value: Route) {
            this.previousRouteBeforeBookAndQuiz = value;
        },
    },
});
