import { BookModel } from '@/types/books';
import { StudentDetailsModel } from '@/shared/model/student-details-model';

export interface TeacherMaterialModel {
    id: number;
    idNumber: string;
    title: string;
    linkDescriptor: string;
    fileLocation: string;
    materialCategory: string;
}

export interface PrintableBook {
    title: string;
    fileLocation: string;
}

export interface BookDetailsModel extends Omit<BookModel, 'cover' | 'tagsIds' | 'shelfRangeLow' | 'shelfRangeHigh'> {
    id: number;
    subtitle: string;
    linkDescriptor: string | null;
    pageCount: number;
    audioLength: number;
    summary: string;
    creationDate: string;
    lastModifiedDate: string;
    language: string;
    coverFileType: string;
    fileType: string;
    quizExist: boolean;
    teacherMaterials: Array<TeacherMaterialModel>;
    students: Array<StudentDetailsModel>;
    printableBooks: Array<PrintableBook>;
}

export enum MultipartType {
    NONE = 'NONE',
    CONSOLIDATED = 'CONSOLIDATED',
    INDIVIDUAL = 'INDIVIDUAL',
}

export enum BookDetailsState {
    BOOK_PARTS = 'book-parts',
    RESOURCES_VIEW = 'resources',
    ASSIGN_VIEW = 'assign',
    PREVIEW_QUIZ_VIEW = 'preview-quiz',
    SAVE_TO_COLLECTION_VIEW = 'save-to-collection',
    OPEN_BOOK_VIEW = 'open-book',
}
