import { AxiosPromise } from 'axios';

import BaseRestService from '@/common/rest/base-rest-service';

import type { BookModel, TagModel } from '@/types';

class CollectionRestService extends BaseRestService {
    getCollection(tags?: TagModel[]): AxiosPromise<BookModel[]> {
        const url = tags && tags.length > 0 ? `/api/collection?tagIds=${tags.map((tag) => tag.id)}` : '/api/collection';
        return super.get(url);
    }

    addBookIntoCollection(idNumber: string) {
        return super.post(`/api/collection/${idNumber}`, null);
    }

    removeBookFromCollection(idNumber: string) {
        return super.delete(`/api/collection/${idNumber}`);
    }
}

const collectionRestService: CollectionRestService = new CollectionRestService();

export default collectionRestService;
