export interface TrackStoreModel {
    newTimeAdded: boolean;
}

export const enum TrackGetters {
    isNewTimeAdded = 'isNewTimeAdded'
}

export const enum TrackActions {
    logTime = 'logTime',
    clearPolling = 'clearPolling'
}

export const enum TrackMutations {
    logActivityTime = 'logActivityTime',
    reset = 'RESET'
}

export const trackNamespace = 'track';

export const enum TrackExternals {
    clearPolling = 'track/clearPolling',
    startLogTime = 'track/logTime'
}
