
import { Component, Vue } from 'vue-property-decorator';
import { directive as onClickaway } from 'vue-clickaway';

import { firstLetter } from '@/filters';
import { useAuth } from '@/store';
import { Role } from '@/constants';
import { MySettingsRoute } from '@/router/routes/my-settings';

@Component({
    directives: {
        onClickaway,
    },
})
export default class AccountMenu extends Vue {
    MySettingsRoute = MySettingsRoute;

    Role = Role;

    auth = useAuth();

    isOpen: boolean = false;

    get initials(): string {
        if (this.auth.fullname) {
            return firstLetter(this.auth.fullname);
        }

        if (this.auth.username) {
            return firstLetter(this.auth.username);
        }

        return '';
    }

    hideDropdown() {
        this.isOpen = false;
    }

    toogleDropdown() {
        this.isOpen = !this.isOpen;
    }
}
