var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._g({class:[
        'lu-button',
        _vm.fullWidth && `lu-button_full-width`,
        _vm.size && `lu-button_size_${_vm.size}`,
        _vm.variant && `lu-button_variant_${_vm.variant}`,
        _vm.disabled && `lu-button_disabled`,
    ],attrs:{"disabled":_vm.disabled,"type":_vm.type}},_vm.$listeners),[(_vm.$slots.icon)?_c('span',{staticClass:"lu-button__icon"},[_vm._t("icon")],2):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }