
import { Component, Prop, Vue } from 'vue-property-decorator';
import { directive as onClickaway } from 'vue-clickaway';

export interface LUSelectOption {
    value: string | number | null;
    title: string;
}

@Component({
    directives: {
        onClickaway,
    },
})
export default class LUSelect extends Vue {
    @Prop()
    options!: LUSelectOption[];

    @Prop({
        default: null,
        type: [Object, String, Number],
    })
    value!: LUSelectOption | string | number;

    @Prop({
        default: false,
        type: Boolean,
    })
    disabled!: boolean;

    isOpen: boolean = false;

    get selected() {
        if (this.value && typeof this.value === 'object') {
            return this.value;
        }

        return this.options.find((option) => option.value == this.value);
    }

    optionClicked(option: LUSelectOption) {
        const value = typeof this.value === 'object' && this.value !== null ? option : option.value;

        this.hideDropdown();
        this.$emit('input', value);
    }

    toogleDropdown() {
        this.isOpen = !this.isOpen;
    }

    hideDropdown() {
        this.isOpen = false;
    }

    isSelectedOption(option: LUSelectOption) {
        if (this.value && typeof this.value === 'object') {
            return option.value === this.value.value;
        }

        return option.value == this.value;
    }
}
