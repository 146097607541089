import { render, staticRenderFns } from "./LUPageHeader.vue?vue&type=template&id=7540a08f&scoped=true&"
import script from "./LUPageHeader.vue?vue&type=script&lang=ts&"
export * from "./LUPageHeader.vue?vue&type=script&lang=ts&"
import style0 from "./LUPageHeader.vue?vue&type=style&index=0&id=7540a08f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7540a08f",
  null
  
)

export default component.exports