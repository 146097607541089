import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';

export enum CollectionRoute {
    COLLECTION = 'collection',
}

const collectionRoutes: RouteConfig[] = [
    {
        path: '/collection',
        name: CollectionRoute.COLLECTION,
        component: () => import(/* webpackChunkName: "collection" */ '@/site-pages/collection/MyCollection.vue'),
        meta: {
            roles: [Role.STUDENT, Role.TEACHER],
        },
        beforeEnter: (to, from, next) => {
            (to.meta as any).from = from;
            next();
        },
    },
];

export default collectionRoutes;
