import CollectionIcon from '@/assets/my-collection.svg';
import ClassroomIcon from '@/assets/classroom-icon.svg';
import LevelUpGameIcon from '@/assets/level-up-now.svg';
import ReportIcon from '@/assets/report.svg';

export default function useTeacherMenuItems() {
    return [
        {
            id: 'menu-collection-tab',
            icon: CollectionIcon,
            name: 'My collection',
            url: '/collection',
        },
        {
            id: 'menu-classroom-tab',
            icon: ClassroomIcon,
            name: 'My Classroom',
            url: '/classroom',
        },
        {
            id: 'menu-report-tab',
            icon: ReportIcon,
            name: 'Reports',
            url: '/reports',
        },
        {
            id: 'menu-game-tab',
            icon: LevelUpGameIcon,
            name: 'LevelUp Land',
            url: '/game',
        },
    ];
}
