import qs, { IStringifyOptions } from 'qs';

const qsOptions: IStringifyOptions = {
    addQueryPrefix: true,
    arrayFormat: 'comma',
    encode: false,
    indices: false,
    skipNulls: true
};

export const serializeQueryString = (params: object): string => {
    return qs.stringify(params, qsOptions);
};
