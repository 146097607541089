import { UserModel } from '@/types';

type UserAlike = Omit<UserModel, 'id' | 'role'>;

export const getSortableUsername = <T extends UserAlike>(user: T): string => {
    if (!user.surname && !user.name) {
        return user.username!.toLocaleLowerCase();
    }

    return user.surname ? user.surname.toLocaleLowerCase() : user.name!.toLocaleLowerCase();
};

export const sortByUserName = <T extends UserAlike>(users: T[], order: 'asc' | 'desc' = 'asc'): T[] =>
    sortLocaleNumeric(users, getSortableUsername, order);

export const sortLocaleNumeric = <T>(
    items: T[],
    sortBy: string | ((item: T) => string),
    sortDir: 'asc' | 'desc' = 'asc'
): T[] =>
    items.slice().sort((a, b) => {
        const aValue = typeof sortBy === 'function' ? sortBy(a) : a[sortBy];
        const bValue = typeof sortBy === 'function' ? sortBy(b) : b[sortBy];

        return sortDir === 'asc'
            ? aValue.localeCompare(bValue, 'en', { numeric: true })
            : bValue.localeCompare(aValue, 'en', { numeric: true });
    });
