import { defineStore } from 'pinia';

import { groupsRestService } from '@/rest';

import type { PageRequest } from '@/rest';
import type { ReadingGroup } from '@/types';
import type { GroupsState } from './types';

export const useGroupsStore = defineStore('groups', {
    state: (): GroupsState => ({
        groups: [] as ReadingGroup[],
        totalNumberOfGroups: 0,
    }),

    actions: {
        async loadAllGroups(page: PageRequest) {
            const {
                data: { content: groups, totalElements },
            } = await groupsRestService.getAllPageableGroups(page);

            this.groups = groups;
            this.totalNumberOfGroups = totalElements;
        },
    },
});
