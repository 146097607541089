import { Route, RouteConfig } from 'vue-router';

import { Role } from '@/constants';
import { ReportType } from '@/site-pages/reports/model/report-constants';
import { castNumericParamsToProps } from '@/router/lib';

export enum DistrictReportRoute {
    DISTRICT = 'report.district',
    DISTRICT_ACTIVITY = 'report.district.activity',
    SCHOOL = 'report.district.school',
    SCHOOL_ACTIVITY = 'report.district.school.activity',
    CLASSROOM = 'report.district.classroom',
    CLASSROOM_ACTIVITY = 'report.district.classroom.activity',
    STUDENT = 'report.district.student',
    STUDENT_ACTIVITY = 'report.district.student.activity',
}

const SchoolAndDistrictReportView = () =>
    import(/* webpackChunkName: "reports-district" */ '@/site-pages/reports/view/report/SchoolAndDistrictReport.vue');

const ClassroomReportView = () =>
    import(/* webpackChunkName: "reports-district" */ '@/site-pages/reports/view/report/ClassroomReport.vue');

const StudentReportView = () =>
    import(/* webpackChunkName: "reports-district" */ '@/site-pages/reports/view/report/StudentReport.vue');

const SkillsStandardsBreakdownReportView = () =>
    import(
        /* webpackChunkName: "reports-district" */ '@/site-pages/reports/view/activity/SkillsStandardsBreakdown.vue'
    );

const StudentActivityReportView = () =>
    import(/* webpackChunkName: "reports-district" */ '@/site-pages/reports/view/activity/StudentActivity.vue');

const districtRoutes: RouteConfig[] = [
    {
        path: 'district/:districtId?',
        name: DistrictReportRoute.DISTRICT,
        props: (route: Route) => ({
            reportType: ReportType.REPORT_DISTRICT,
            ...castNumericParamsToProps(route, ['districtId']),
        }),
        component: SchoolAndDistrictReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER],
        },
    },
    {
        path: 'district/:districtId?/activity',
        name: DistrictReportRoute.DISTRICT_ACTIVITY,
        props: (route: Route) => ({
            type: ReportType.REPORT_DISTRICT,
            id: route.params.districtId,
        }),
        component: SkillsStandardsBreakdownReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER],
        },
    },
    {
        path: 'district/:districtId/school/:schoolId',
        name: DistrictReportRoute.SCHOOL,
        props: (route: Route) => ({
            reportType: ReportType.REPORT_SCHOOL,
            ...castNumericParamsToProps(route, ['districtId', 'schoolId']),
        }),
        component: SchoolAndDistrictReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER],
        },
    },
    {
        path: 'district/:districtId/school/:schoolId/activity',
        name: DistrictReportRoute.SCHOOL_ACTIVITY,
        props: (route: Route) => ({
            type: ReportType.REPORT_SCHOOL,
            id: route.params.schoolId,
        }),
        component: SkillsStandardsBreakdownReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER],
        },
    },
    {
        path: 'district/:districtId/school/:schoolId/classroom/:classroomId',
        name: DistrictReportRoute.CLASSROOM,
        props: (route: Route) => ({
            ...castNumericParamsToProps(route, ['districtId', 'schoolId', 'classroomId']),
        }),
        component: ClassroomReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER],
        },
    },
    {
        path: 'district/:districtId/school/:schoolId/classroom/:classroomId/activity',
        name: DistrictReportRoute.CLASSROOM_ACTIVITY,
        props: (route: Route) => ({
            id: route.params.classroomId,
            type: ReportType.REPORT_CLASSROOM,
        }),
        component: SkillsStandardsBreakdownReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER],
        },
    },
    {
        path: 'district/:districtId/school/:schoolId/classroom/:classroomId/student/:studentId',
        name: DistrictReportRoute.STUDENT,
        props: (route: Route) => ({
            ...castNumericParamsToProps(route, ['studentId']),
        }),
        component: StudentReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER],
        },
    },
    {
        path: 'district/:districtId/school/:schoolId/classroom/:classroomId/student/:studentId/activity',
        name: DistrictReportRoute.STUDENT_ACTIVITY,
        props: (route: Route) => ({
            ...castNumericParamsToProps(route, ['studentId']),
        }),
        component: StudentActivityReportView,
        meta: {
            roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER],
        },
    },
];

export default districtRoutes;
