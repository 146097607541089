import { defineStore } from 'pinia';

import { searchRestService } from '@/rest';

import type { SearchAutocompleteState } from './types';

export const useSearchAutocompleteStore = defineStore('search-autocomplete', {
    state: (): SearchAutocompleteState => ({
        isLoading: false,
        results: [],
    }),

    actions: {
        async fetchResults(query: string) {
            this.isLoading = true;

            try {
                const { data: results } = await searchRestService.fetchAutocompleteResults(query);

                this.results = results;
            } finally {
                this.isLoading = false;
            }
        },
    },
});
