import { defineStore } from 'pinia';

import { tagsRestService } from '@/rest';

import { removeTag, setBookTags, setTags, startLoadingBookTags, stopLoadingBookTags } from './utils';

import type { TagsState, IBookTagsMapping } from './types';
import type { TagModel } from '@/types/tags';

export const useTagsStore = defineStore('tags', {
    state: (): TagsState => ({
        isTagsLoading: false,
        tags: new Map<number, TagModel>(),
        tagsMap: new Map<string, IBookTagsMapping>(),
        selectedTags: [],
        isAddingTag: false,
        isRemovingTag: false,
        isUpdatingTagInBook: false,
        isFetching: false,
    }),

    actions: {
        async loadTags() {
            this.isFetching = true;

            try {
                const { data: tags } = await tagsRestService.getTags();

                this.tags = setTags(tags);
            } finally {
                this.isFetching = false;
            }
        },

        async loadBookTags(idNumber: string) {
            if (this.tagsMap.has(idNumber)) return;
            this.tagsMap = startLoadingBookTags(this.tagsMap, idNumber);
            try {
                const response = await tagsRestService.getBookTags(idNumber);
                this.tagsMap = setBookTags(this.tagsMap, {
                    bookIdNumber: idNumber,
                    tagsIds: response.data.map((t) => t.id),
                });
            } catch (error) {
                console.error('Unexpected error on loading book tags', error);
                throw error;
            } finally {
                const newTagsMap = stopLoadingBookTags(this.tagsMap, idNumber);
                if (newTagsMap) {
                    this.tagsMap = newTagsMap;
                }
            }
        },

        async updateBookTags({ tags, idNumber }: { tags: TagModel[]; idNumber: string }) {
            this.isUpdatingTagInBook = true;
            this.tagsMap = startLoadingBookTags(this.tagsMap, idNumber);
            try {
                await tagsRestService.updateBookTags(tags, idNumber);
                this.tagsMap = setBookTags(this.tagsMap, {
                    bookIdNumber: idNumber,
                    tagsIds: tags.map((t) => t.id),
                });
            } finally {
                this.isUpdatingTagInBook = false;
                const newTagsMap = stopLoadingBookTags(this.tagsMap, idNumber);
                if (newTagsMap) {
                    this.tagsMap = newTagsMap;
                }
            }
        },

        async createTag(tag: string) {
            this.isAddingTag = true;

            try {
                const { data } = await tagsRestService.createTag(tag);

                const newTags = new Map(this.tags);
                newTags.set(data.id, data);
                this.tags = newTags;
            } finally {
                this.isAddingTag = false;
            }
        },

        async deleteTag(tag: TagModel) {
            this.isRemovingTag = true;
            try {
                await tagsRestService.deleteTag(tag);

                const { tags, tagsMap } = removeTag(this, tag);

                this.tags = tags;
                this.tagsMap = tagsMap;
            } finally {
                this.isRemovingTag = false;
            }
        },

        async selectTag(tag: TagModel) {
            this.selectedTags.push(tag);
        },

        async deselectTag(tag: TagModel) {
            this.selectedTags = this.selectedTags.filter((i) => i.id != tag.id);
        },

        async updateTag(tag: TagModel) {
            const { data: updatedTag }: { data: TagModel } = await tagsRestService.updateTag(tag);

            this.tags = new Map(this.tags.set(updatedTag.id, updatedTag));
        },

        subscribeOnBookTagsMapping(bookIdNumber: string) {
            const mapping = this.tagsMap.get(bookIdNumber) || {
                subscriptionsCount: 0,
                bookIdNumber: bookIdNumber,
                tagsIds: [],
                isLoading: false,
            };
            const newTagsMap = new Map([
                ...this.tagsMap.entries(),
                [
                    bookIdNumber,
                    {
                        ...(mapping as IBookTagsMapping),
                        subscriptionsCount: mapping.subscriptionsCount + 1,
                    },
                ],
            ]);
            this.tagsMap = newTagsMap;
        },

        unsubscribeOnBookTagsMapping(bookIdNumber: string) {
            const mapping = this.tagsMap.get(bookIdNumber) || {
                subscriptionsCount: 0,
                bookIdNumber: bookIdNumber,
                tagsIds: [],
                isLoading: false,
            };
            const newTagsMap = new Map([
                ...this.tagsMap.entries(),
                [
                    bookIdNumber,
                    {
                        ...(mapping as IBookTagsMapping),
                        subscriptionsCount: mapping.subscriptionsCount - 1,
                    },
                ],
            ]);
            if (mapping.subscriptionsCount <= 0) newTagsMap.delete(bookIdNumber);
            this.tagsMap = newTagsMap;
        },

        clearTags() {
            this.tags = new Map<number, TagModel>();
            this.selectedTags = [];
        },
    },

    getters: {
        getTags: (state: TagsState) =>
            [...state.tags.values()].sort((a: TagModel, b: TagModel) => {
                return a.tagName.localeCompare(b.tagName);
            }),

        tag: (state: TagsState) => (tagId: number) => {
            return state.tags.get(tagId);
        },

        bookTagsIds: (state: TagsState) => (bookIdNumber: string) => {
            return state.tagsMap.has(bookIdNumber) ? (state.tagsMap.get(bookIdNumber) as IBookTagsMapping).tagsIds : [];
        },

        isBookTagsMappingLoading: (state: TagsState) => (bookIdNumber: string) => {
            return state.tagsMap.has(bookIdNumber)
                ? (state.tagsMap.get(bookIdNumber) as IBookTagsMapping).isLoading
                : false;
        },

        isUniqueTag(state: TagsState) {
            return ({ id = -1, tagName = '' }: { id?: number; tagName?: string }) => {
                if (state.tags.size === 0) {
                    return true;
                }

                const tag: TagModel | undefined = this.getTags.find(
                    (tag: TagModel) => tag.tagName.toUpperCase() === tagName.toUpperCase() && tag.id !== id
                );

                return tag ? false : true;
            };
        },
    },
});
