import { defineStore } from 'pinia';

import { runningRecordsRestService } from '@/rest';

import type { RunningRecordDetailsState } from './types';
import { BatchAssignment, StudentRunningRecordAssignment } from '@/types';

export const useRunningRecordDetailsStore = defineStore('running-record-details', {
    state: (): RunningRecordDetailsState => ({
        isFetching: false,
        isFetchingAssignments: false,
        isFetchingResources: false,
        isAssigning: false,
        teacherMaterials: [],
    }),

    actions: {
        async fetchDetails(idNumber: string) {
            this.isFetching = true;

            try {
                const { data: details } = await runningRecordsRestService.fetchRunningRecordDetails(idNumber);

                return details;
            } finally {
                this.isFetching = false;
            }
        },

        async fetchAssignments(id: number) {
            this.isFetchingAssignments = true;

            try {
                const { data: assignments } = await runningRecordsRestService.fetchAssignmentsByIdNumber(id);

                return assignments;
            } finally {
                this.isFetchingAssignments = false;
            }
        },

        async fetchTeacherResources(idNumber: string) {
            this.isFetchingResources = true;

            try {
                const {
                    data: { teacherMaterials },
                } = await runningRecordsRestService.fetchRunningRecordResources(idNumber);

                this.teacherMaterials = teacherMaterials;
            } finally {
                this.isFetchingResources = false;
            }
        },

        async assignRunningRecord({
            id,
            assignments,
        }: {
            id: number;
            assignments: Partial<StudentRunningRecordAssignment>[];
        }) {
            this.isAssigning = true;
            try {
                const { data: updatedAssignments } = await runningRecordsRestService.assignRunningRecord(
                    id,
                    assignments
                );

                return updatedAssignments;
            } finally {
                this.isAssigning = false;
            }
        },

        async batchAssignRunningRecord({ id, assignment }: { id: number; assignment: BatchAssignment }) {
            this.isAssigning = true;
            try {
                const { data: updatedAssignment } = await runningRecordsRestService.batchAssignRunningRecord(
                    id,
                    assignment
                );

                return updatedAssignment;
            } finally {
                this.isAssigning = false;
            }
        },

        async unassignRunningRecord({ id, assignments }: { id: number; assignments: number[] }) {
            this.isAssigning = true;
            try {
                const { data: updatedAssignments } = await runningRecordsRestService.unassignRunningRecord(
                    id,
                    assignments
                );

                return updatedAssignments;
            } finally {
                this.isAssigning = false;
            }
        },
    },

    getters: {},
});
