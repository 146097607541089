
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';

export type LUTableCellAlign = 'center' | 'left' | 'right';

@Component
export default class LUTableCell extends Vue {
    @Prop({
        default: '',
        type: String
    })
    align!: LUTableCellAlign;

    @InjectReactive()
    density!: string;
}
