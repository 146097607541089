
import { compact, difference } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueTagsInput from '@johmun/vue-tags-input';

import { TagModel } from '@/types/tags';
import { BookModel } from '@/types/books';
import { useCollectionStore, useTagsStore } from '@/store';

interface IInputTgasTag {
    id: number;
    text: string;
}

@Component({
    components: {
        VueTagsInput,
    },
})
export default class CollectionMenu extends Vue {
    @Prop({ required: true })
    book!: BookModel;

    collectionStore = useCollectionStore();
    tagsStore = useTagsStore();

    tag: string = '';

    autocompleteAlwaysOpen: Boolean = false;

    private bookTagsLoaded = false;
    private tagsAssignedOnBook: TagModel[] = [];

    pass() {}

    get tags() {
        return this.tagsStore.getTags;
    }

    get autocompleteVariants(): IInputTgasTag[] {
        return this.tags
            .filter((tag) => tag.tagName.toLowerCase().includes(this.tag.trim().toLowerCase()))
            .map((tag) => {
                return {
                    id: tag.id,
                    text: tag.tagName,
                };
            });
    }

    get selectedTags(): IInputTgasTag[] {
        const tagsIds = this.tagsAssignedOnBook
            ? this.tagsAssignedOnBook.map((tag) => tag.id)
            : this.tagsStore.bookTagsIds(this.book.idNumber);
        const selected = compact(tagsIds.map((tagId: number) => this.tagsStore.tag(tagId)));

        return selected.map((tag) => {
            return {
                id: tag.id,
                text: tag.tagName,
            };
        });
    }

    get collectionAction(): string {
        return this.inCollection ? 'Remove from My Collection' : 'Add to My Collection';
    }

    get showLoading(): boolean {
        return this.tagsStore.isBookTagsMappingLoading(this.book.idNumber);
    }

    get shouldDisplaySaveButton(): boolean {
        const bookTagsIds = this.tagsStore.bookTagsIds(this.book.idNumber);
        const assignedTagsIds = this.tagsAssignedOnBook.map((tag) => tag.id);

        return (
            !this.tagsStore.isBookTagsMappingLoading(this.book.idNumber) &&
            (difference(bookTagsIds, assignedTagsIds).length !== 0 ||
                difference(assignedTagsIds, bookTagsIds).length !== 0)
        );
    }

    get showLinkToCollection() {
        return this.inCollection && this.$router.currentRoute.name != 'collection';
    }

    get inCollection(): boolean {
        return this.collectionStore.isInCollection(this.book.idNumber);
    }

    created() {
        this.fetchTags();
    }

    tagsChanged(tags: IInputTgasTag[]) {
        this.tagsAssignedOnBook = tags.map((tag) => {
            return {
                id: tag.id,
                tagName: tag.text,
            };
        });

        this.closeAutocomplete();
        (document.getElementsByClassName('ti-new-tag-input')[0] as HTMLElement).blur();
    }

    clickCollection() {
        if (this.inCollection) {
            this.tagsStore.updateBookTags({
                tags: [],
                idNumber: this.book.idNumber,
            });
            this.tagsAssignedOnBook = [];
            this.collectionStore.removeBookFromCollection(this.book.idNumber);
        } else {
            this.collectionStore.addBookIntoCollection(this.book.idNumber);
        }
    }

    async fetchTags() {
        await this.tagsStore.loadTags();

        if (!this.tagsStore.isBookTagsMappingLoading(this.book.idNumber)) {
            await this.tagsStore.loadBookTags(this.book.idNumber);

            this.loadtagsAssignedOnBookFromStore();
            this.bookTagsLoaded = true;
            this.tagsStore.subscribeOnBookTagsMapping(this.book.idNumber);
        }
    }

    async beforeAddingTag(obj: { tag: IInputTgasTag; addTag: Function }) {
        obj.tag.text = obj.tag.text.trim();

        if (!obj.tag.id) {
            if (!this.tags.find((t) => t.tagName === obj.tag.text)) {
                await this.tagsStore.createTag(obj.tag.text);
            }
            const tag = this.tags.find((t) => t.tagName === obj.tag.text);
            if (tag) {
                obj.tag['id'] = tag.id;
            }
        }
        obj.addTag();
    }

    beforeDeletingTag(obj: { deleteTag: Function }) {
        obj.deleteTag();
    }

    loadtagsAssignedOnBookFromStore() {
        const tagsIds = this.tagsStore.bookTagsIds(this.book.idNumber);
        this.tagsAssignedOnBook = tagsIds.map((tagId: number) => this.tagsStore.tag(tagId));
    }

    async saveBookTags() {
        await this.tagsStore.updateBookTags({
            tags: this.tagsAssignedOnBook,
            idNumber: this.book.idNumber,
        });

        this.loadtagsAssignedOnBookFromStore();
        this.$emit('tagsUpdate', this.tagsAssignedOnBook);
    }

    goToCollection() {
        this.$router.push({ name: 'collection' });
    }

    beforeDestroy() {
        this.tagsStore.unsubscribeOnBookTagsMapping(this.book.idNumber);
    }

    openAutocomplete() {
        this.autocompleteAlwaysOpen = true;
    }

    closeAutocomplete() {
        this.autocompleteAlwaysOpen = false;
    }
}
