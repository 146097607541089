
import { Component, InjectReactive, Vue } from 'vue-property-decorator';

import { LelelUpTheme } from '@/types/app';

@Component
export default class BookCardSkeleton extends Vue {
    @InjectReactive('appTheme')
    theme!: LelelUpTheme;
}
