import { Route, RouteConfig } from 'vue-router';

import { formatUserName } from '@/filters';
import { Role } from '@/constants';
import { RostersGetters, rostersNamespace } from '@/store/rosters/types';
import { ClassroomModel, SchoolModel, UserModel } from '@/types';

const RostersLayoutNoNav = () =>
    import(/* webpackChunkName: "rosters" */ '@/site-pages/rosters/layouts/RostersLayoutNoNav.vue');

const ClassroomDetailsView = () =>
    import(/* webpackChunkName: "rosters-classroom" */ '@/site-pages/rosters/views/ClassroomDetailsView.vue');

const ClassroomExpirationFormView = () =>
    import(/* webpackChunkName: "rosters-classroom" */ '@/site-pages/rosters/views/ClassroomExpirationFormView.vue');

const ClassroomStudentRosteringFormView = () =>
    import(
        /* webpackChunkName: "rosters-classroom" */ '@/site-pages/rosters/views/ClassroomStudentRosteringFormView.vue'
    );

const ClassroomFormView = () =>
    import(/* webpackChunkName: "rosters-classroom" */ '@/site-pages/rosters/views/ClassroomFormView.vue');

const ClassroomGradeFormView = () =>
    import(/* webpackChunkName: "rosters-classroom" */ '@/site-pages/rosters/views/ClassroomGradeFormView.vue');

const SchoolAndDistrictAdminFormView = () =>
    import(
        /* webpackChunkName: "rosters-district-admins" */ '@/site-pages/rosters/views/SchoolAndDistrictAdminFormView.vue'
    );

const UserFormView = () =>
    import(/* webpackChunkName: "rosters-users" */ '@/site-pages/rosters/views/UserFormView.vue');

const StudentFormView = () =>
    import(/* webpackChunkName: "rosters-students" */ '@/site-pages/rosters/views/StudentFormView.vue');

const DistrictDetailsView = () =>
    import(/* webpackChunkName: "rosters-district" */ '@/site-pages/rosters/views/DistrictDetailsView.vue');

const DistrictsView = () => import(/* webpackChunkName: "rosters" */ '@/site-pages/rosters/views/DistrictsView.vue');

const RostersView = () => import(/* webpackChunkName: "rosters" */ '@/site-pages/rosters/RostersView.vue');

const SchoolDetailsView = () =>
    import(/* webpackChunkName: "rosters-school" */ '@/site-pages/rosters/views/SchoolDetailsView.vue');

const SchoolFormView = () =>
    import(/* webpackChunkName: "rosters-school" */ '@/site-pages/rosters/views/SchoolFormView.vue');

const castDistrictProps = (route: Route) => ({
    districtId: Number.parseInt(route.params.districtId, 10),
});

const castClassroomProps = (route: Route) => ({
    classroomId: Number.parseInt(route.params.classroomId, 10),
    districtId: Number.parseInt(route.params.districtId, 10),
    schoolId: Number.parseInt(route.params.schoolId, 10),
});

const castClassroomUserProps = (route: Route) => ({
    classroomId: Number.parseInt(route.params.classroomId, 10),
    districtId: Number.parseInt(route.params.districtId, 10),
    schoolId: Number.parseInt(route.params.schoolId, 10),
    userId: Number.parseInt(route.params.userId, 10),
});

const castDistrictAdminProps = (route: Route) => ({
    districtId: Number.parseInt(route.params.districtId, 10),
    userId: Number.parseInt(route.params.userId, 10),
});

const castSchoolProps = (route: Route) => ({
    districtId: Number.parseInt(route.params.districtId, 10),
    schoolId: Number.parseInt(route.params.schoolId, 10),
});

const castSchoolAdminProps = (route: Route) => ({
    districtId: Number.parseInt(route.params.districtId, 10),
    schoolId: Number.parseInt(route.params.schoolId, 10),
    userId: Number.parseInt(route.params.userId, 10),
});

const rostersRoutes: RouteConfig[] = [
    {
        path: '/rosters',
        component: RostersView,
        meta: {
            key: 'rosters',
            roles: [Role.MULTI_DISTRICT_MANAGER],
            breadcrumb: 'All Districts',
        },
        children: [
            {
                path: '/',
                name: 'rosters.districts',
                component: DistrictsView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                },
            },
            {
                path: 'districts/:districtId',
                name: 'rosters.district.details',
                props: castDistrictProps,
                component: DistrictDetailsView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb() {
                        const district = this.$store.getters[`${rostersNamespace}/${RostersGetters.getDistrictById}`](
                            Number.parseInt(this.$route.params.districtId, 10)
                        );

                        return district
                            ? {
                                  label: district.name,
                                  parent: 'rosters.districts',
                                  tooltip: 'District',
                              }
                            : {};
                    },
                },
            },
            {
                path: 'districts/:districtId/admins/create',
                name: 'rosters.district.admins.create',
                props: castDistrictProps,
                component: SchoolAndDistrictAdminFormView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb: {
                        label: 'Create District Administrator…',
                        parent: 'rosters.district.details',
                    },
                },
            },
            {
                path: 'districts/:districtId/admins/:userId',
                name: 'rosters.district.admins.edit',
                props: castDistrictAdminProps,
                component: SchoolAndDistrictAdminFormView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb() {
                        const districtId = Number.parseInt(this.$route.params.districtId, 10);
                        const userId = Number.parseInt(this.$route.params.userId, 10);
                        const districtAdmin: UserModel = this.$store.getters[
                            `${rostersNamespace}/${RostersGetters.getGroupAdminById}`
                        ](districtId, userId);

                        return {
                            label: districtAdmin ? formatUserName(districtAdmin) : '',
                            parent: 'rosters.district.details',
                        };
                    },
                },
            },
            {
                path: 'districts/:districtId/school/create',
                name: 'rosters.school.create',
                props: castDistrictProps,
                component: SchoolFormView,
                meta: {
                    key: 'rosters',
                    layout: RostersLayoutNoNav,
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId',
                name: 'rosters.school.details',
                props: castSchoolProps,
                component: SchoolDetailsView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb() {
                        const schoolId = Number.parseInt(this.$route.params.schoolId, 10);
                        const school: SchoolModel =
                            this.$store.getters[`${rostersNamespace}/${RostersGetters.getSchoolById}`](schoolId);

                        return school
                            ? {
                                  label: school.name,
                                  parent: 'rosters.district.details',
                                  tooltip: 'School',
                              }
                            : {};
                    },
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/admins/create',
                name: 'rosters.school.admins.create',
                props: castSchoolProps,
                component: SchoolAndDistrictAdminFormView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb: {
                        label: 'Create School Administrator…',
                        parent: 'rosters.school.details',
                    },
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/admins/:userId',
                name: 'rosters.school.admins.edit',
                props: castSchoolAdminProps,
                component: SchoolAndDistrictAdminFormView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb() {
                        const schoolId = Number.parseInt(this.$route.params.schoolId, 10);
                        const userId = Number.parseInt(this.$route.params.userId, 10);
                        const schoolAdmin: UserModel = this.$store.getters[
                            `${rostersNamespace}/${RostersGetters.getGroupAdminById}`
                        ](schoolId, userId);

                        return {
                            label: schoolAdmin ? formatUserName(schoolAdmin) : '',
                            parent: 'rosters.school.details',
                        };
                    },
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/classroom/create',
                name: 'rosters.classroom.create',
                props: castSchoolProps,
                component: ClassroomFormView,
                meta: {
                    key: 'rosters',
                    layout: RostersLayoutNoNav,
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/classroom/:classroomId',
                name: 'rosters.classroom.details',
                props: castClassroomProps,
                component: ClassroomDetailsView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb() {
                        const classroomId = Number.parseInt(this.$route.params.classroomId, 10);
                        const classroom: ClassroomModel =
                            this.$store.getters[`${rostersNamespace}/${RostersGetters.getClassroomById}`](classroomId);

                        return classroom
                            ? {
                                  label: classroom.name,
                                  parent: 'rosters.school.details',
                                  tooltip: 'Classroom',
                              }
                            : {};
                    },
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/classroom/:classroomId/teachers/create',
                name: 'rosters.classroom.teachers.create',
                props: castClassroomProps,
                component: UserFormView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb: {
                        label: 'Create Teacher…',
                        parent: 'rosters.classroom.details',
                    },
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/classroom/:classroomId/students/create',
                name: 'rosters.classroom.students.create',
                props: castClassroomProps,
                component: StudentFormView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb: {
                        label: 'Create Student…',
                        parent: 'rosters.classroom.details',
                    },
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/classroom/:classroomId/teachers/:userId',
                name: 'rosters.classroom.teachers.edit',
                props: castClassroomUserProps,
                component: UserFormView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb() {
                        const classroomId = Number.parseInt(this.$route.params.classroomId, 10);
                        const userId = Number.parseInt(this.$route.params.userId, 10);
                        const teacher: UserModel = this.$store.getters[
                            `${rostersNamespace}/${RostersGetters.getClassroomUserById}`
                        ](classroomId, userId);

                        return {
                            label: teacher ? formatUserName(teacher) : '',
                            parent: 'rosters.classroom.details',
                        };
                    },
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/classroom/:classroomId/students/:userId',
                name: 'rosters.classroom.students.edit',
                props: castClassroomUserProps,
                component: StudentFormView,
                meta: {
                    key: 'rosters',
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                    breadcrumb() {
                        const classroomId = Number.parseInt(this.$route.params.classroomId, 10);
                        const userId = Number.parseInt(this.$route.params.userId, 10);
                        const student: UserModel = this.$store.getters[
                            `${rostersNamespace}/${RostersGetters.getClassroomUserById}`
                        ](classroomId, userId);

                        return {
                            label: student ? formatUserName(student) : '',
                            parent: 'rosters.classroom.details',
                        };
                    },
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/classroom/:classroomId/rostering',
                name: 'rosters.classroom.rostering',
                props: castClassroomProps,
                component: ClassroomStudentRosteringFormView,
                meta: {
                    key: 'rosters',
                    layout: RostersLayoutNoNav,
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/classroom/:classroomId/expiration',
                name: 'rosters.classroom.expiration',
                props: castClassroomProps,
                component: ClassroomExpirationFormView,
                meta: {
                    key: 'rosters',
                    layout: RostersLayoutNoNav,
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                },
            },
            {
                path: 'districts/:districtId/school/:schoolId/classroom/:classroomId/grade',
                name: 'rosters.classroom.grade',
                props: castClassroomProps,
                component: ClassroomGradeFormView,
                meta: {
                    key: 'rosters',
                    layout: RostersLayoutNoNav,
                    roles: [Role.MULTI_DISTRICT_MANAGER],
                },
            },
            {
                path: '*',
                redirect: '/rosters',
            },
        ],
    },
];

export default rostersRoutes;
