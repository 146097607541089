
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ButtonHTMLAttributes } from 'vue/types/jsx';

export type LUButtonSize = 'compact' | 'large' | 'medium' | 'xlarge';
export type LUButtonVariant = 'icon' | 'inline' | 'outline' | 'outline-secondary' | 'plain' | 'primary' | 'secondary';

@Component
export default class LUButton extends Vue {
    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: false, type: Boolean })
    fullWidth!: boolean;

    @Prop({ default: 'medium' })
    size!: LUButtonSize;

    @Prop({ default: 'button' })
    type!: ButtonHTMLAttributes['type'];

    @Prop({ default: 'primary' })
    variant!: LUButtonVariant;
}
