import { AxiosPromise } from 'axios';

import BaseRestService from '@/common/rest/base-rest-service';
import type { AssessmentResult, AssessmentUserResponse, LexileAssessment } from '@/types';

class LexileRestService extends BaseRestService {
    fetchCurrentAssessment(): AxiosPromise<LexileAssessment> {
        return super.get(`/api/lexiles`);
    }

    saveLexileUserAnswers(userAnswers: Array<AssessmentUserResponse>): AxiosPromise<AssessmentResult> {
        return super.post(`/api/lexiles/answers`, userAnswers);
    }

    checkNewLexileAvailable(): AxiosPromise<boolean> {
        return super.shadowGet(`/api/lexiles/available`);
    }

    markFormAsStarted() {
        return super.get(`/api/lexiles/mark-started`);
    }

    triggerLexileCheck(): AxiosPromise<boolean> {
        return super.get(`/api/lexiles/check-availability-and-assign-new-test`);
    }
}

const lexileRestService: LexileRestService = new LexileRestService();

export default lexileRestService;
