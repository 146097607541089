import trackRestService from '@/common/rest/track/track-rest-service';
import { TrackActions, TrackGetters, TrackMutations, TrackStoreModel } from '@/common/rest/track/track-polling-model';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store';

const initialState = (): TrackStoreModel => ({
    newTimeAdded: false,
});

const state: TrackStoreModel = initialState();

let polling;

const getters: GetterTree<TrackStoreModel, RootState> = {
    [TrackGetters.isNewTimeAdded]: (state: TrackStoreModel) => state.newTimeAdded,
};

const actions: ActionTree<TrackStoreModel, RootState> = {
    [TrackActions.logTime]({ commit }: ActionContext<TrackStoreModel, RootState>) {
        const interval = 60 * 1000;

        polling = setInterval(() => {
            trackRestService
                .trackLoggedInTime()
                .then((result) => {
                    commit(TrackMutations.logActivityTime, result.data);
                })
                .catch((error) => {
                    console.error('Error when log time ', error);
                });
        }, interval);
    },
    [TrackActions.clearPolling]() {
        clearInterval(polling);
    },
};

const mutations: MutationTree<TrackStoreModel> = {
    [TrackMutations.logActivityTime]: (state: TrackStoreModel, newTimeAdded: boolean) => {
        state.newTimeAdded = newTimeAdded;
    },

    [TrackMutations.reset]: (state: TrackStoreModel) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

const namespaced: boolean = true;

const track: Module<TrackStoreModel, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};

export default track;
