import { defineStore } from 'pinia';

import activityRestService from '@/rest/activity';

interface IActivitiesState {
    newTimeAdded: boolean;
    isListening: boolean;
    showPoints: boolean;
    coins: number;
    showRecordingSentText: boolean;
}

let polling: number;
const ONE_MINUTE = 60000;

export const useActivitiesStore = defineStore('activities', {
    state: (): IActivitiesState => ({
        newTimeAdded: false,
        isListening: false,
        showPoints: false,
        coins: 0,
        showRecordingSentText: false,
    }),
    actions: {
        logActivityTime(bookIdNumber: string) {
            this.track(bookIdNumber);
            polling = setInterval(() => this.track(bookIdNumber), ONE_MINUTE);
        },
        async track(bookIdNumber: string) {
            const trackActivity = this.isListening ? 'trackListening' : 'trackReading';
            const result = await activityRestService[trackActivity](bookIdNumber);

            this.newTimeAdded = result.data;
        },
        clearPolling() {
            clearInterval(polling);
        },
        async finishBook(bookIdNumber: string, voiceRecordingLinkId?: string) {
            try {
                const result = await activityRestService.finishBook(bookIdNumber, voiceRecordingLinkId);
                this.coins = result.data;
                if (voiceRecordingLinkId) {
                    this.showRecordingSentText = true;
                } else {
                    this.showRecordingSentText = false;
                }
            } catch (error) {
                console.error('Error during finish book');
                throw error;
            }
        },
        startListening() {
            this.isListening = true;
        },
        stopListening() {
            this.isListening = false;
        },
    },
});
