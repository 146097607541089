import BaseRestService from '@/common/rest/base-rest-service';
import { MediaType } from '@/player/utils/constants-holder';

class ClozeRestService extends BaseRestService {
    getCloze(passage) {
        return super.post('/api/cloze', passage, {
            headers: {
                'Content-Type': MediaType.APPLICATION_JSON,
            },
        });
    }

    calculateLexile() {
        return super.get('/api/cloze');
    }

    submitInTakeFile(formData: FormData) {
        return super.post('/api/parsers/in-take', formData, {
            headers: {
                'Content-Type': MediaType.MULTIPART_FORM_DATA,
            },
        });
    }

    submitTestletFile(formData: FormData) {
        return super.post('/api/parsers/testlet', formData, {
            headers: {
                'Content-Type': MediaType.MULTIPART_FORM_DATA,
            },
        });
    }

    submitClozeFile(formData: FormData) {
        return super.post('/api/parsers/cloze', formData, {
            headers: {
                'Content-Type': MediaType.MULTIPART_FORM_DATA,
            },
        });
    }
}

const clozeRestService: ClozeRestService = new ClozeRestService();

export default clozeRestService;
