import { defineStore } from 'pinia';

import { classroomRestService } from '@/rest';
import { sortStudentsByFullName } from '@/utils';

import type { ClassroomSettings, Grade, StudentModel } from '@/types';
import type { ClassroomRosterState } from './types';
import { useClassroomSwitcherStore } from '@/store';
import { StudentInterfaceTypeSetting } from '@/store/auth/types';

export const useClassroomRosterStore = defineStore('classroom-roster', {
    state: (): ClassroomRosterState => ({
        classroomId: null,
        isLoading: false,
        gradeLevel: null,
        roster: new Map(),
        settings: {
            canTeacherAddStudents: false,
            maximumCapacity: 0,
        },
    }),

    actions: {
        async fetchRoster() {
            this.isLoading = true;

            try {
                const {
                    data: { id, users, settings, gradeLevel },
                } = await classroomRestService.getClass();

                this.classroomId = id;
                this.gradeLevel = gradeLevel;
                this.setRoster(users);
                this.setSettings(settings);
            } finally {
                this.isLoading = false;
            }
        },

        async setBookAudioSettingForStudent(student: StudentModel, value: boolean) {
            const stateBackup = JSON.parse(JSON.stringify(this.students));

            try {
                const roster = new Map(this.roster);
                const studentFromRoster = roster.get(student.id);

                if (studentFromRoster) {
                    roster.set(studentFromRoster.id, {
                        ...studentFromRoster,
                        bookPlayerSettings: {
                            isAudioBookAvailable: value,
                            isAloudReadingAvailable: studentFromRoster.bookPlayerSettings.isAloudReadingAvailable,
                        },
                    });
                } else {
                    throw new Error('Invalid student ID.');
                }

                this.roster = roster;

                await classroomRestService.updateBookSettings([this.roster.get(studentFromRoster.id)]);
            } catch (error) {
                this.setRoster(stateBackup);
                throw error;
            }
        },

        async setBookAudioSettingForStudents(students: StudentModel[], value: boolean) {
            const stateBackup = JSON.parse(JSON.stringify(this.students));

            try {
                const roster = new Map(this.roster);

                students.forEach((student: StudentModel) => {
                    const studentFromRoster = roster.get(student.id);

                    if (studentFromRoster) {
                        roster.set(studentFromRoster.id, {
                            ...studentFromRoster,
                            bookPlayerSettings: {
                                isAudioBookAvailable: value,
                                isAloudReadingAvailable: studentFromRoster.bookPlayerSettings.isAloudReadingAvailable,
                            },
                        });
                    } else {
                        throw new Error('Invalid student ID.');
                    }
                });

                this.roster = roster;

                await classroomRestService.updateBookSettings(students.map((student) => this.roster.get(student.id)));
            } catch (error) {
                this.setRoster(stateBackup);
                throw error;
            }
        },

        async setStudentInterfaceTypeSettingForStudent(
            student: StudentModel,
            studentInterfaceTypeSetting: StudentInterfaceTypeSetting
        ) {
            const stateBackup = JSON.parse(JSON.stringify(this.students));

            try {
                const roster = new Map(this.roster);
                const studentFromRoster = roster.get(student.id);

                if (studentFromRoster) {
                    roster.set(studentFromRoster.id, {
                        ...studentFromRoster,
                        studentInterfaceSettings: {
                            userId: studentFromRoster.id,
                            interfaceSetting: studentInterfaceTypeSetting,
                        },
                    });
                } else {
                    throw new Error('Invalid student ID.');
                }

                this.roster = roster;

                await classroomRestService.updateStudentsInterfaceTypeSetting(
                    [studentFromRoster],
                    studentInterfaceTypeSetting
                );
            } catch (error) {
                this.setRoster(stateBackup);
                throw error;
            }
        },

        async setStudentInterfaceTypeSettingsForStudents(
            students: StudentModel[],
            studentInterfaceTypeSetting: StudentInterfaceTypeSetting
        ) {
            const stateBackup = JSON.parse(JSON.stringify(this.students));

            try {
                const roster = new Map(this.roster);

                students.forEach((user: StudentModel) => {
                    const student = roster.get(user.id);

                    if (student) {
                        roster.set(user.id, {
                            ...student,
                            studentInterfaceSettings: {
                                userId: student.id,
                                interfaceSetting: studentInterfaceTypeSetting,
                            },
                        });
                    } else {
                        throw new Error('Invalid student ID.');
                    }
                });

                this.roster = roster;

                await classroomRestService.updateStudentsInterfaceTypeSetting(students, studentInterfaceTypeSetting);
            } catch (error) {
                this.setRoster(stateBackup);
                throw error;
            }
        },

        async setStudentRecordingSettingForStudent(student: StudentModel, value: boolean) {
            const stateBackup = JSON.parse(JSON.stringify(this.students));

            try {
                const roster = new Map(this.roster);
                const studentFromRoster = roster.get(student.id);

                if (studentFromRoster) {
                    roster.set(studentFromRoster.id, {
                        ...studentFromRoster,
                        bookPlayerSettings: {
                            isAudioBookAvailable: studentFromRoster.bookPlayerSettings.isAudioBookAvailable,
                            isAloudReadingAvailable: value,
                        },
                    });
                } else {
                    throw new Error('Invalid student ID.');
                }

                this.roster = roster;

                await classroomRestService.updateBookSettings([this.roster.get(studentFromRoster.id)]);
            } catch (error) {
                this.setRoster(stateBackup);
                throw error;
            }
        },

        async setStudentRecordingSettingForStudents(students: StudentModel[], value: boolean) {
            const stateBackup = JSON.parse(JSON.stringify(this.students));

            try {
                const roster = new Map(this.roster);

                students.forEach((user: StudentModel) => {
                    const student = roster.get(user.id);

                    if (student) {
                        roster.set(user.id, {
                            ...student,
                            bookPlayerSettings: {
                                isAudioBookAvailable: student.bookPlayerSettings.isAudioBookAvailable,
                                isAloudReadingAvailable: value,
                            },
                        });
                    } else {
                        throw new Error('Invalid student ID.');
                    }
                });

                this.roster = roster;

                await classroomRestService.updateBookSettings(students.map((student) => this.roster.get(student.id)));
            } catch (error) {
                this.setRoster(stateBackup);
                throw error;
            }
        },

        async changeStudentQuizFrequency({ quizKey, userId }: { quizKey: string; userId: number }) {
            await this.tryToSingleUpdateRoster(userId, { quizFrequency: quizKey });
        },

        async changeStudentQuizAvailability({ quizKey, userId }: { quizKey: string; userId: number }) {
            await this.tryToSingleUpdateQuiz(userId, { quizAvailability: quizKey });
        },

        async toggleConstructedResponse({ userId, value }: { userId: number; value: boolean }) {
            await this.tryToSingleUpdateRoster(userId, { constructedResponse: value });
        },

        async toggleReferToBook({ userId, value }: { userId: number; value: boolean }) {
            await this.tryToSingleUpdateRoster(userId, { referToBook: value });
        },

        async batchChangeStudentQuizFrequency({ quizKey, userIds }: { quizKey: string; userIds: number[] }) {
            await this.tryToBatchUpdateRoster(userIds, { quizFrequency: quizKey });
        },

        async batchChangeStudentQuizAvailability({ quizKey, userIds }: { quizKey: string; userIds: number[] }) {
            await this.tryToBatchUpdateQuiz(userIds, { quizAvailability: quizKey });
        },

        async batchToggleConstructedResponse({ userIds, value }: { userIds: number[]; value: boolean }) {
            await this.tryToBatchUpdateRoster(userIds, { constructedResponse: value });
        },

        async batchToggleReferToBook({ userIds, value }: { userIds: number[]; value: boolean }) {
            await this.tryToBatchUpdateRoster(userIds, { referToBook: value });
        },

        setRoster(students: StudentModel[]) {
            const roster = new Map(this.roster);

            students.forEach((student) => roster.set(student.id, student));

            this.roster = roster;
        },

        setSettings(settings: ClassroomSettings | null = null) {
            this.settings = settings;
        },

        async tryToSingleUpdateRoster(userId: number, value: Partial<StudentModel>) {
            return await this.tryToSingleUpdate(userId, value, 'updateClassUsers');
        },

        async tryToSingleUpdateQuiz(userId: number, value: Partial<StudentModel>) {
            return await this.tryToSingleUpdate(userId, value, 'updateQuizAvailability');
        },

        async tryToBatchUpdateRoster(userIds: number[], value: Partial<StudentModel>) {
            return await this.tryToBatchUpdate(userIds, value, 'updateClassUsers');
        },

        async tryToBatchUpdateQuiz(userIds: number[], value: Partial<StudentModel>) {
            return await this.tryToBatchUpdate(userIds, value, 'updateQuizAvailability');
        },

        async tryToSingleUpdate(userId: number, value: Partial<StudentModel>, serviceMethod: string) {
            const stateBackup = JSON.parse(JSON.stringify(this.students));

            try {
                this.updateRoster(userId, value);
                await classroomRestService[serviceMethod]([this.roster.get(userId)]);
            } catch (error) {
                this.setRoster(stateBackup);

                throw error;
            }
        },

        async tryToBatchUpdate(userIds: number[], value: Partial<StudentModel>, serviceMethod: string) {
            const stateBackup = JSON.parse(JSON.stringify(this.students));

            try {
                this.batchUpdateRoster(userIds, value);

                const students = userIds.map((userId) => this.roster.get(userId));

                await classroomRestService[serviceMethod](students);
            } catch (error) {
                this.setRoster(stateBackup);

                throw error;
            }
        },

        updateRoster(userId: number, value: Partial<StudentModel>) {
            const roster = new Map(this.roster);
            const student = roster.get(userId);

            if (student) {
                roster.set(userId, {
                    ...student,
                    ...value,
                });
            }

            this.roster = roster;
        },

        batchUpdateRoster(userIds: number[], value: Partial<StudentModel>) {
            const roster = new Map(this.roster);

            userIds.forEach((userId: number) => {
                const student = roster.get(userId);

                if (student) {
                    roster.set(userId, {
                        ...student,
                        ...value,
                    });
                }
            });

            this.roster = roster;
        },
    },

    getters: {
        availableSpaces(): number | null {
            return this.classroomCapacity !== null ? Math.max(0, this.classroomCapacity - this.totalStudents) : null;
        },

        classroomCapacity: (state: ClassroomRosterState): number | null => state.settings?.maximumCapacity ?? null,

        classroomGrade: (state: ClassroomRosterState): Grade | null => state.gradeLevel ?? null,

        students: (state: ClassroomRosterState): StudentModel[] => {
            const classroomSwitcherStore = useClassroomSwitcherStore();

            if (classroomSwitcherStore.selectedStudentIds.length) {
                return sortStudentsByFullName([...state.roster.values()], false).filter((student) => {
                    return classroomSwitcherStore.selectedStudentIds.includes(student.id);
                });
            } else {
                return sortStudentsByFullName([...state.roster.values()], false);
            }
        },

        allStudents: (state: ClassroomRosterState): StudentModel[] => {
            return sortStudentsByFullName([...state.roster.values()], false);
        },

        totalStudents: (state: ClassroomRosterState): number => state.roster.size,
    },
});
