import { Route, RouteConfig } from 'vue-router';

import { ReportType } from '@/site-pages/reports/model/report-constants';
import { Role } from '@/constants';
import { castNumericParamsToProps } from '@/router/lib';

export enum SchoolReportRoute {
    SCHOOL = 'report.school',
    SCHOOL_ACTIVITY = 'report.school.activity',
    CLASSROOM = 'report.school.classroom',
    CLASSROOM_ACTIVITY = 'report.school.classroom.activity',
    STUDENT = 'report.school.student',
    STUDENT_ACTIVITY = 'report.school.student.activity',
}

const SchoolAndDistrictReportView = () =>
    import(/* webpackChunkName: "reports-school" */ '@/site-pages/reports/view/report/SchoolAndDistrictReport.vue');

const ClassroomReportView = () =>
    import(/* webpackChunkName: "reports-school" */ '@/site-pages/reports/view/report/ClassroomReport.vue');

const StudentReportView = () =>
    import(/* webpackChunkName: "reports-school" */ '@/site-pages/reports/view/report/StudentReport.vue');

const SkillsStandardsBreakdownReportView = () =>
    import(/* webpackChunkName: "reports-school" */ '@/site-pages/reports/view/activity/SkillsStandardsBreakdown.vue');

const StudentActivityReportView = () =>
    import(/* webpackChunkName: "reports-school" */ '@/site-pages/reports/view/activity/StudentActivity.vue');

const schoolRoutes: RouteConfig[] = [
    {
        path: 'school',
        name: SchoolReportRoute.SCHOOL,
        props: () => ({
            reportType: ReportType.REPORT_SCHOOL,
        }),
        component: SchoolAndDistrictReportView,
        meta: {
            roles: [Role.SCHOOL_MANAGER],
        },
    },
    {
        path: 'school/:schoolId?/activity',
        name: SchoolReportRoute.SCHOOL_ACTIVITY,
        props: (route: Route) => ({
            type: ReportType.REPORT_SCHOOL,
            id: route.params.schoolId,
        }),
        component: SkillsStandardsBreakdownReportView,
        meta: {
            roles: [Role.SCHOOL_MANAGER],
        },
    },
    {
        path: 'school/:schoolId/classroom/:classroomId',
        name: SchoolReportRoute.CLASSROOM,
        props: (route: Route) => ({
            ...castNumericParamsToProps(route, ['schoolId', 'classroomId']),
        }),
        component: ClassroomReportView,
        meta: {
            roles: [Role.SCHOOL_MANAGER],
        },
    },
    {
        path: 'school/:schoolId/classroom/:classroomId/activity',
        name: SchoolReportRoute.CLASSROOM_ACTIVITY,
        props: (route: Route) => ({
            type: ReportType.REPORT_CLASSROOM,
            id: route.params.classroomId,
        }),
        component: SkillsStandardsBreakdownReportView,
        meta: {
            roles: [Role.SCHOOL_MANAGER],
        },
    },
    {
        path: 'school/:schoolId/classroom/:classroomId/student/:studentId',
        name: SchoolReportRoute.STUDENT,
        props: (route: Route) => ({
            ...castNumericParamsToProps(route, ['schoolId', 'classroomId', 'studentId']),
        }),
        component: StudentReportView,
        meta: {
            roles: [Role.SCHOOL_MANAGER],
        },
    },
    {
        path: 'school/:schoolId/classroom/:classroomId/student/:studentId/activity',
        name: SchoolReportRoute.STUDENT_ACTIVITY,
        props: (route: Route) => ({
            ...castNumericParamsToProps(route, ['studentId']),
            username: route.params.username,
        }),
        component: StudentActivityReportView,
        meta: {
            roles: [Role.SCHOOL_MANAGER],
        },
    },
];

export default schoolRoutes;
