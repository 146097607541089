import { ActionTree, ActionContext } from 'vuex';

import { reportsRestService } from '@/rest/reports';

import { RootState } from '@/store';
import { ReportRequestModel, ReportResponseModel, ReportResultModel, TotalReportData } from '@/types/reports';
import reportUtils from '@/utils/reports-utils';
import { saveAsCSV } from '@/utils/save-file';
import { ReportState, ReportActionNames, ReportMutationNames } from './types';

const actions: ActionTree<ReportState, RootState> = {
    async [ReportActionNames.getStudentSkillOrStandard](
        { commit }: ActionContext<ReportState, RootState>,
        reportRequest: ReportRequestModel
    ) {
        commit(ReportMutationNames.setLoading, true);
        try {
            const response = await reportsRestService.getStudentSkillOrStandard({
                id: reportRequest.id,
                type: reportRequest.type,
                startDate: reportRequest.startDate,
                endDate: reportRequest.endDate,
                language: reportRequest.language,
                studentId: reportRequest.studentId,
            });
            commit(ReportMutationNames.setBookQuizzes, reportUtils.makeBookQuizzes(response.data.bookQuiz));
        } catch (error) {
            console.error('Unexpected error on loading report model', error);
            throw error;
        } finally {
            commit(ReportMutationNames.setLoading, false);
        }
    },

    async [ReportActionNames.fetchStudentPhonicsReport](
        { commit }: ActionContext<ReportState, RootState>,
        request: ReportRequestModel
    ) {
        const { data: report } = await reportsRestService.getStudentPhonicsReport(request);

        commit(ReportMutationNames.setStudentPhonicsReport, report);
    },

    async [ReportActionNames.saveActivityReportCSV](
        context: ActionContext<ReportState, RootState>,
        { startDate, endDate, groupBy, orderBy, orderType, language, grade, groupIds }
    ) {
        const { data } = await reportsRestService.getActivityReportCSV({
            startDate,
            endDate,
            groupBy,
            orderBy,
            orderType,
            language,
            grade,
            groupIds,
        });

        saveAsCSV(data, 'activity-report');
    },

    async [ReportActionNames.getClassRoomReport](
        { commit }: ActionContext<ReportState, RootState>,
        { id, startDate, endDate, language, grade, classroomIds, readingGroupIds }
    ) {
        try {
            const { data }: { data: ReportResponseModel } = await reportsRestService.getClassroom({
                id,
                startDate,
                endDate,
                language,
                grade,
                classroomIds,
                readingGroupIds,
            });
            commit(ReportMutationNames.resetReportData);
            commit(ReportMutationNames.setTeacherReportData, data);
            commit(ReportMutationNames.setGrades, data.grades);
        } catch (error) {
            console.error('Unexpected error on loading class report', error);
            throw error;
        }
    },

    async [ReportActionNames.getSchoolReport](
        { commit, dispatch }: ActionContext<ReportState, RootState>,
        { id, startDate, endDate, language, grade, groupIds }
    ) {
        commit(ReportMutationNames.resetReportData);

        try {
            const { data }: { data: ReportResultModel } = await reportsRestService.getSchoolReport({
                id,
                startDate,
                endDate,
                language,
                grade,
                groupIds,
            });
            commit(ReportMutationNames.setReportData, data);
            dispatch(ReportActionNames.getGrades, [data.id]);
        } catch (error) {
            console.error('Unexpected error on loading school report', error);
            throw error;
        }
    },

    async [ReportActionNames.getDistrictReport](
        { commit, dispatch }: ActionContext<ReportState, RootState>,
        { id, startDate, endDate, language, grade, groupIds }
    ) {
        commit(ReportMutationNames.resetReportData);

        try {
            const { data }: { data: ReportResultModel } = await reportsRestService.getDistrictReport({
                id,
                startDate,
                endDate,
                language,
                grade,
                groupIds,
            });
            commit(ReportMutationNames.setReportData, data);
            dispatch(ReportActionNames.getGrades, [data.id]);
        } catch (error) {
            console.error('Unexpected error on loading district report', error);
            throw error;
        }
    },

    async [ReportActionNames.getMultiDistrictReport](
        { commit, dispatch }: ActionContext<ReportState, RootState>,
        { startDate, endDate, language, grade, groupIds }
    ) {
        commit(ReportMutationNames.resetReportData);

        try {
            const { data }: { data: ReportResultModel } = await reportsRestService.getMultiDistrictReport({
                startDate,
                endDate,
                language,
                grade,
                groupIds,
            });
            commit(ReportMutationNames.setReportData, data);
            dispatch(ReportActionNames.getGrades, []);
        } catch (error) {
            console.error('Unexpected error on loading district report', error);
            throw error;
        }
    },

    async [ReportActionNames.getTotalReportData](
        { commit }: ActionContext<ReportState, RootState>,
        { startDate, endDate, language, grade, groupIds }
    ) {
        commit(ReportMutationNames.resetTotalReportData);
        try {
            const { data }: { data: TotalReportData } = await reportsRestService.getTotalReportData({
                startDate,
                endDate,
                language,
                grade,
                groupIds,
            });
            commit(ReportMutationNames.setTotalReportData, data);
        } catch (error) {
            console.error('Unexpected error on loading total report data', error);
            throw error;
        }
    },

    async [ReportActionNames.getTotalDistrictReportData](
        { commit }: ActionContext<ReportState, RootState>,
        payload: ReportRequestModel
    ) {
        commit(ReportMutationNames.resetTotalReportData);
        try {
            const { data }: { data: TotalReportData } = await reportsRestService.getTotalDistrictReportData(payload);
            commit(ReportMutationNames.setTotalReportData, data);
        } catch (error) {
            console.error('Unexpected error on loading total report data', error);
            throw error;
        }
    },

    async [ReportActionNames.getMultiDistrictTotalSkills](
        { commit }: ActionContext<ReportState, RootState>,
        { startDate, endDate, language, grade, groupIds }
    ) {
        commit(ReportMutationNames.resetTotalSkills);
        try {
            const { data }: { data: TotalReportData } = await reportsRestService.getMultiDistrictTotalSkills({
                startDate,
                endDate,
                language,
                grade,
                groupIds,
            });
            commit(ReportMutationNames.setTotalSkills, data);
        } catch (error) {
            console.error('Unexpected error on loading total skills', error);
            throw error;
        }
    },

    async [ReportActionNames.getDistrictTotalSkills](
        { commit }: ActionContext<ReportState, RootState>,
        payload: ReportRequestModel
    ) {
        commit(ReportMutationNames.resetTotalSkills);
        try {
            const { data }: { data: TotalReportData } = await reportsRestService.getDistrictTotalSkills(payload);
            commit(ReportMutationNames.setTotalSkills, data);
        } catch (error) {
            console.error('Unexpected error on loading total skills', error);
            throw error;
        }
    },

    async [ReportActionNames.getGrades]({ commit }: ActionContext<ReportState, RootState>, groupIds: number[]) {
        const payload = { groupIds };
        const { data } = await reportsRestService.getGrades(payload);

        commit(ReportMutationNames.setGrades, data);
    },
};

export default actions;
