
import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import { get, orderBy } from 'lodash';

import Scrollbar from '@/common/directives/scrollbar';
import { Checkbox } from '@/components';
import LUTableCell, { LUTableCellAlign } from './LUTableCell.vue';
import LUTableRow from './LUTableRow.vue';
import LUTablePagination from './LUTablePagination.vue';

import type { StyleValue } from 'vue/types/jsx';

export interface LUTableColumn {
    label: string;
    field: string;
    comparator?: Function;
    maxWidth?: number | string;
    minWidth?: number | string;
    slotName?: string;
    sortable?: boolean;
    align?: LUTableCellAlign;
    headerAlign?: LUTableCellAlign;
}

export type LUTableDensity = 'default' | 'comfortable' | 'compact';

enum SortDir {
    ASC = 'asc',
    DESC = 'desc',
}

@Component({
    components: {
        Checkbox,
        LUTableCell,
        LUTableRow,
        LUTablePagination,
    },

    directives: {
        Scrollbar,
    },
})
export default class LUTable extends Vue {
    @Prop({ default: false, type: Boolean })
    showEmpty!: boolean;

    @Prop({ default: false, type: Boolean })
    bulkEdit!: boolean;

    @Prop()
    columns!: LUTableColumn[];

    @Prop()
    customFilter!: (() => [])[] | (() => []);

    @Prop({ default: 'default' })
    density!: LUTableDensity;

    @Prop({ default: false, type: Boolean })
    hover!: boolean;

    @Prop()
    maxHeight!: number;

    @Prop()
    name!: string;

    @Prop({ default: () => [] })
    rows!: [];

    @Prop({ default: '', type: [String, Function] })
    rowClass!: string | ((row: unknown) => string);

    @Prop()
    sortBy!: string;

    @Prop({ default: SortDir.ASC, type: String })
    sortDirection!: SortDir;

    @Prop({
        default: () => [],
    })
    value!: [];

    @Prop({ default: ({ id }) => id, type: Function })
    uniqueIdResolver!: (row: object) => string;

    @ProvideReactive('density')
    densityProp: LUTableDensity = this.density;

    @ProvideReactive('hover')
    hoverProp: boolean = this.hover;

    @Prop({ default: false, type: Boolean })
    paginate!: boolean;

    @Prop({ default: 25 })
    pageSize!: number;

    @Prop({ default: undefined })
    isRowSelectable: Function | undefined;

    allSelected = false;

    sortedBy: string | null = null;

    sortDir = this.sortDirection;

    comparator: Function | null = null;

    currentPage: number = 0;

    defaultComparator = (rows: [], sortBy: string | [], sortDir: boolean | 'asc' | 'desc') => {
        return orderBy(rows, sortBy, sortDir);
    };

    created() {
        this.setInitialOrder();
    }

    @Watch('selectableRows')
    watchSelectableRows() {
        this.$emit('selectable-rows-count', this.selectableRows.length);
    }

    @Watch('value')
    onValueChange() {
        this.allSelected = this.allRowsSelected;
    }

    @Watch('rows')
    @Watch('customFilter')
    onRowsChange() {
        this.resetSelection();
        this.currentPage = 0;
    }

    get allRowsSelected(): boolean {
        if (this.isRowSelectable !== undefined) {
            const selectableRowsCount = this.visibleRows.filter((item) => {
                return (this.isRowSelectable as Function)(item);
            }).length;

            return (
                this.visibleRows.length > 0 && selectableRowsCount === this.value.length && selectableRowsCount !== 0
            );
        }

        return this.visibleRows.length > 0 && this.visibleRows.length === this.value.length;
    }

    get bulkEditLabel(): string {
        if (this.hasMultipleSelected) {
            return 'Edit Selected';
        }

        return 'Select All';
    }

    get selectedRows(): object[] {
        return this.value || [];
    }

    set selectedRows(value) {
        this.$emit('input', value);
    }

    get someRowsSelected(): boolean {
        return this.value.length > 0 && !this.allRowsSelected;
    }

    get hasMultipleSelected(): boolean {
        return this.value.length > 1;
    }

    get hasSelectedRows(): boolean {
        return this.value.length > 0;
    }

    get bulkEditClasses(): string[] {
        return ['lu-table__batch-select', this.hasMultipleSelected ? 'lu-table__batch-select_selected' : ''];
    }

    get bodyStyles(): StyleValue {
        return {
            maxHeight: this.maxHeight ? `${this.maxHeight}px` : undefined,
        };
    }

    get bulkEditColumns(): LUTableColumn[] {
        return this.columns.slice(1);
    }

    get headerColumns(): LUTableColumn[] {
        return this.name ? this.columns.slice(1) : this.columns;
    }

    get isEmpty(): boolean {
        return this.filteredRows.length === 0;
    }

    get rowStyles(): { [key: string]: string | null } {
        const gridTemplateColumns = this.columns.reduce((acc, column) => {
            let minWidth: number | string = '120px';
            let maxWidth: string = '1fr';

            if (column.minWidth) {
                minWidth = typeof column.minWidth === 'string' ? column.minWidth : `${column.minWidth}px`;
            }

            if (column.maxWidth) {
                maxWidth = typeof column.maxWidth === 'string' ? column.maxWidth : `${column.maxWidth}px`;
            }

            acc += `minmax(${minWidth}, ${maxWidth})`;
            return acc;
        }, '');

        return {
            display: 'grid',
            gridTemplateColumns,
        };
    }

    get filteredRows() {
        if (!this.customFilter) {
            return this.rows;
        }

        if (Array.isArray(this.customFilter) && this.customFilter.length) {
            return this.customFilter.filter(Boolean).reduce((d: unknown[], f) => d.filter(f), this.rows);
        }

        if (typeof this.customFilter == 'function') {
            return this.rows.filter(this.customFilter);
        }

        return this.rows;
    }

    get sortedRows() {
        if (!this.sortedBy) {
            return this.filteredRows;
        }

        if (typeof this.comparator === 'function') {
            return this.comparator(this.filteredRows, this.sortedBy, this.sortDir);
        }

        return this.defaultComparator(this.filteredRows as [], this.sortedBy, this.sortDir);
    }

    get shouldDisplayBulkEdit(): boolean {
        return this.filteredRows.length > 0 && this.bulkEdit;
    }

    get shouldDisplayPagination(): boolean {
        return this.paginate && this.filteredRows.length > this.pageSize;
    }

    get totalPages(): number {
        return Math.ceil(this.filteredRows.length / this.pageSize);
    }

    get visibleRowsCount(): number {
        return this.visibleRows.length;
    }

    get hasSelectableRows() {
        if (this.isRowSelectable === undefined) {
            return this.visibleRows.length !== 0;
        } else {
            const selectableRows = this.visibleRows.filter((item) => {
                return (this.isRowSelectable as Function)(item);
            });

            return selectableRows.length !== 0;
        }
    }

    get selectableRows() {
        if (this.isRowSelectable) {
            const selectableRows = this.visibleRows.filter((item) => {
                return (this.isRowSelectable as Function)(item);
            });
            return selectableRows;
        } else {
            return [];
        }
    }

    get visibleRows() {
        if (this.paginate) {
            const { currentPage, pageSize } = this;
            const offset = currentPage * pageSize;

            return this.sortedRows.slice(offset, offset + pageSize);
        }

        return this.sortedRows;
    }

    isSorted(column: LUTableColumn) {
        return this.sortedBy === column.field;
    }

    getCellValue(row, field): string {
        return get(row, field);
    }

    getCellSlotName(column: LUTableColumn): string {
        return `cell.${column.slotName ? column.slotName : column.field}`;
    }

    getHeaderCellSlotName(column: LUTableColumn): string {
        return `headerCell.${column.slotName ? column.slotName : column.field}`;
    }

    getHeaderCellClassnamesWithoutSorting(column: LUTableColumn): string[] {
        const align = column.headerAlign || column.align;

        return [
            'lu-table__column',
            `lu-table__column_density_${this.density}`,
            ...(align ? [`lu-table__column_align_${align}`] : []),
        ];
    }

    getHeaderCellClassnames(column: LUTableColumn): string[] {
        const align = column.headerAlign || column.align;

        return [
            'lu-table__column',
            `lu-table__column_density_${this.density}`,
            ...(align ? [`lu-table__column_align_${align}`] : []),
            ...(column.sortable ? ['lu-table__column_sortable'] : []),
        ];
    }

    getBulkCellClassnames(column: LUTableColumn): string[] {
        const align = column.headerAlign || column.align;

        return [
            ...(column.field ? [`lu-table-cell_${column.field}`] : []),
            ...(align ? [`lu-table-cell_align_${align}`] : []),
        ];
    }

    getRowClasses(row): string {
        if (typeof this.rowClass === 'function') {
            return this.rowClass(row);
        }

        return this.rowClass;
    }

    shouldHighlightRow(row): boolean {
        const isSelectedRow = this.isSelectedRow(row);

        return isSelectedRow;
    }

    isSelectedRow(row): boolean {
        return Boolean(this.selectedRows.find((item) => this.uniqueIdResolver(item) === this.uniqueIdResolver(row)));
    }

    onPageChange(n) {
        this.currentPage = n - 1;
        this.resetSelection();
    }

    selectRow(row) {
        if (this.isSelectedRow(row)) {
            return;
        }

        this.selectedRows = [...this.selectedRows, row];
    }

    resetSelection() {
        this.$emit('input', []);
    }

    toggleRow(row) {
        const rowIndex = this.selectedRows.findIndex(
            (item) => this.uniqueIdResolver(item) === this.uniqueIdResolver(row)
        );

        if (rowIndex >= 0) {
            this.selectedRows = [...this.selectedRows.slice(0, rowIndex), ...this.selectedRows.slice(rowIndex + 1)];
        } else {
            this.selectedRows = [...this.selectedRows, row];
        }
    }

    toggleAll(checked) {
        if (this.isRowSelectable) {
            const selectableRows = this.visibleRows.filter((item) => {
                return (this.isRowSelectable as Function)(item);
            });
            this.$emit('input', checked ? selectableRows : []);
        } else {
            this.$emit('input', checked ? this.visibleRows.slice() : []);
        }
    }

    sort(column: LUTableColumn) {
        if (!column.sortable) {
            return;
        }

        this.currentPage = 0;
        this.resetSelection();
        this.comparator = column.comparator || null;
        this.sortDir = this.sortDir === SortDir.ASC && this.isSorted(column) ? SortDir.DESC : SortDir.ASC;
        this.sortedBy = column.field;
        this.$emit('update:sortBy', this.sortedBy);
        this.$emit('update:sortDirection', this.sortDir);
    }

    setInitialOrder() {
        const column = this.sortBy
            ? this.columns.find((column) => column.field === this.sortBy)
            : this.columns.find((column) => column.sortable);

        if (column) {
            this.comparator = column.comparator || null;
            this.sortedBy = column.field;
        }
    }
}
