export enum ReportType {
    REPORT_MULTI_DISTRICT = 'multi-district',
    REPORT_DISTRICT = 'district',
    REPORT_CLASSROOM = 'classroom',
    REPORT_SCHOOL = 'school',
    REPORT_STUDENT = 'student',
}

export const ReportName = {
    [ReportType.REPORT_CLASSROOM]: 'Classroom',
    [ReportType.REPORT_MULTI_DISTRICT]: 'All districts',
    [ReportType.REPORT_DISTRICT]: 'District',
    [ReportType.REPORT_SCHOOL]: 'School',
    [ReportType.REPORT_STUDENT]: 'Student',
};

export interface Breadcrumb {
    name: string;
    text: string;
    params?: object;
    tooltip?: string;
}

export enum ParentType {
    DISTRICT = 'district',
    SCHOOL = 'school',
    CLASSROOM = 'class',
    STUDENT = 'student',
    ACTIVITY = 'activity',
}

export enum GroupType {
    DISTRICT = 'district',
    SCHOOL = 'school',
    CLASSROOM = 'classroom',
    GROUP = 'group',
}

export const GROUP_TYPES = new Map<ReportType, string>([
    [ReportType.REPORT_MULTI_DISTRICT, GroupType.DISTRICT],
    [ReportType.REPORT_DISTRICT, GroupType.SCHOOL],
    [ReportType.REPORT_SCHOOL, GroupType.CLASSROOM],
    [ReportType.REPORT_CLASSROOM, GroupType.GROUP],
]);

export const FILTER_PLACEHOLDERS = new Map<ReportType, string>([
    [ReportType.REPORT_MULTI_DISTRICT, 'All Districts'],
    [ReportType.REPORT_DISTRICT, 'All Schools'],
    [ReportType.REPORT_SCHOOL, 'All Classrooms'],
    [ReportType.REPORT_CLASSROOM, 'All Groups'],
]);
