import { UserModel } from '@/types';
import { BookLanguage } from './books';

export enum RunningRecordAssignmentType {
    READ = 'READ',
    READ_AND_RETELL = 'READ_AND_RETELL',
}

interface BaseAssignment {
    id: number;
    rawText: string;
    photoCredit?: string;
    type: RunningRecordAssignmentType;
    readingAudioUploadLink: string;
    retellingAudioUploadLink?: string;
    title: string;
}

export interface ReadAssignment extends BaseAssignment {
    type: RunningRecordAssignmentType.READ;
}

export interface ReadAndRetellAssignment extends BaseAssignment {
    type: RunningRecordAssignmentType.READ_AND_RETELL;
    retellingAudioUploadLink: string;
}

export type Assignment = ReadAssignment | ReadAndRetellAssignment;

export enum RunningRecordErrorType {
    'OMISSION' = 'OMISSION',
    'INSERTION' = 'INSERTION',
    'MISPRONUNCIATION' = 'MISPRONUNCIATION',
    'SUBSTITUTION' = 'SUBSTITUTION',
}

export enum RunningRecordSelfCorrectionType {
    'SELF_CORRECTION' = 'SELF_CORRECTION',
    'SC_STRUCTURE' = 'SC_STRUCTURE',
    'SC_MEANING' = 'SC_MEANING',
    'SC_VISUAL' = 'SC_VISUAL',
}

export enum RunningRecordRepetitionType {
    'REPETITION' = 'REPETITION',
}

export type RunningRecordMarkType =
    | RunningRecordErrorType
    | RunningRecordSelfCorrectionType
    | RunningRecordRepetitionType;

export interface RunningRecordMark {
    word: string;
    wordIndex: number;
    type: RunningRecordMarkType;
}

export type RunningRecordWord = {
    word: string;
    types: RunningRecordMarkType[];
};

export enum RunningRecordStatus {
    'NEW' = 'NEW',
    'FINISHED' = 'FINISHED',
    'REVIEWED' = 'REVIEWED',
}

export interface RunningRecord {
    assignDate: string;
    assignmentStatus: 'NEW' | 'FINISHED' | 'REVIEWED';
    assignmentType: Assignment['type'];
    id: number;
    lexileWeight: number;
    linkToReadRecord: string;
    linkToRetellRecord?: string;
    rawText: string;
    recordDate: string;
    runningRecordId: number;
    studentData: UserModel;
    title: string;
    wordCount: number;
}

export interface RunningRecordReview {
    notes: string;
    segmentEnd: number | null;
    segmentStart: number | null;
    teacherMarks: RunningRecordMark[];
    unusable: boolean;
    urrId: number;
}

export interface RunningRecordPassage {
    bookType: string;
    idNumber: string;
    lexileWeight: number;
    title: string;
}

export interface RunningRecordStudents {
    assigned: number[];
    completed: number[];
}

export interface RunningRecordDetails {
    id: number;
    idNumber: string;
    title: string;
    lexileWeight: number;
    bookType: string;
    language: BookLanguage;
    wordCount: number;
    photoCredit: string;
    rawText: string;
    status: keyof typeof RunningRecordActivityStatus;
}

export enum RunningRecordActivityStatus {
    INACTIVE,
    ACTIVE,
}

export enum RunningRecordDetailsState {
    RESOURCES_VIEW = 'resources',
    ASSIGN_VIEW = 'assign',
}

export interface RunningRecordAssignmentAudio {
    linkToReadRecord: string;
    linkToRetellRecord: string;
}
