import { Route, RouteConfig } from 'vue-router';

import { Role } from '@/constants';
import { ReportType } from '@/site-pages/reports/model/report-constants';
import { castNumericParamsToProps } from '@/router/lib';

export enum TeacherReportRoute {
    CLASSROOM = 'report.teacher.classroom',
    CLASSROOM_ACTIVITY = 'report.teacher.classroom.activity',
    STUDENT = 'report.teacher.student',
    STUDENT_ACTIVITY = 'report.teacher.student.activity',
}

const ClassroomReportView = () =>
    import(/* webpackChunkName: "reports-teacher" */ '@/site-pages/reports/view/report/ClassroomReport.vue');

const StudentReportView = () =>
    import(/* webpackChunkName: "reports-teacher" */ '@/site-pages/reports/view/report/StudentReport.vue');

const SkillsStandardsBreakdownReportView = () =>
    import(/* webpackChunkName: "reports-teacher" */ '@/site-pages/reports/view/activity/SkillsStandardsBreakdown.vue');

const StudentActivityReportView = () =>
    import(/* webpackChunkName: "reports-teacher" */ '@/site-pages/reports/view/activity/StudentActivity.vue');

const teacherRoutes: RouteConfig[] = [
    {
        path: 'classroom',
        name: TeacherReportRoute.CLASSROOM,
        component: ClassroomReportView,
        meta: {
            roles: [Role.TEACHER],
        },
    },
    {
        path: 'classroom/activity',
        name: TeacherReportRoute.CLASSROOM_ACTIVITY,
        props: (route: Route) => ({
            type: ReportType.REPORT_CLASSROOM,
            id: route.params.classroomId,
        }),
        component: SkillsStandardsBreakdownReportView,
        meta: {
            roles: [Role.TEACHER],
        },
    },
    {
        path: 'student/:studentId',
        name: TeacherReportRoute.STUDENT,
        props: (route: Route) => ({
            ...castNumericParamsToProps(route, ['studentId']),
            username: route.params.username,
        }),
        component: StudentReportView,
        meta: {
            roles: [Role.TEACHER],
        },
    },
    {
        path: 'student/:studentId/activity',
        name: TeacherReportRoute.STUDENT_ACTIVITY,
        props: (route: Route) => ({
            ...castNumericParamsToProps(route, ['studentId']),
        }),
        component: StudentActivityReportView,
        meta: {
            roles: [Role.TEACHER],
        },
    },
];

export default teacherRoutes;
