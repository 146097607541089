import { saveAs } from 'file-saver';

export const saveAsCSV = (data: string, filename = 'data'): void => {
    saveAs(new Blob([data], { type: 'text/csv' }), `${filename}.csv`, { autoBom: true });
};

export const saveAsMP3 = async (url: string, filename = 'recording'): Promise<void> => {
    const blob = await fetch(url).then(res => res.blob());

    saveAs(blob, `${filename}.mp3`);
};
