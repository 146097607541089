
import { Component, Vue, Prop, Provide } from 'vue-property-decorator';
import { directive as onClickaway } from 'vue-clickaway';

const emptyValue = {};

@Component({
    directives: {
        onClickaway,
    },
})
export default class Dropdown extends Vue {
    @Provide('close') closeDropdown = this.close;

    @Prop({ default: 'left', type: String })
    align!: string;

    @Prop({ default: false, type: Boolean })
    disabled!: string;

    @Prop({ default: () => emptyValue })
    showContent!: boolean | Object;

    @Prop(Boolean)
    noCaret!: boolean;

    public innerShow: boolean = false;
    isOpen: boolean = false;

    open() {
        this.$emit('open');
        this.isOpen = true;
    }

    close() {
        this.$emit('close');
        this.isOpen = false;
    }

    toggle() {
        if (this.disabled) {
            return false;
        }

        this.isOpen = !this.isOpen;
    }
}
