
import { Component, Prop, Vue } from 'vue-property-decorator';

import { formatBookTitle, formattedLexile } from '@/filters';
import Popover from '@/common/components/Popover.vue';
import CollectionMenu from '@/shared/popups/CollectionMenu.vue';
import { useAuth, useCollectionStore } from '@/store';

import BookCover from './BookCover.vue';
import BookExcerpt from './BookExcerpt.vue';
import LUButton from './LUButton.vue';
import LUCard from './LUCard.vue';
import DueDateLabel from './DueDateLabel.vue';
import MultipartBookLabel from './MultipartBookLabel.vue';

import type { BookModel, CardClickEvent, CarouselModel, TagModel } from '@/types';
import { MultipartType } from '@/shared/model/book-details-model';

@Component({
    components: {
        BookCover,
        BookExcerpt,
        CollectionMenu,
        DueDateLabel,
        LUButton,
        LUCard,
        Popover,
        MultipartBookLabel,
    },
})
export default class BookCard extends Vue {
    auth = useAuth();
    collectionStore = useCollectionStore();

    @Prop()
    readonly book!: BookModel;

    @Prop({ type: Boolean, default: true })
    readonly showActions!: boolean;

    @Prop({ default: true, type: Boolean })
    readonly showBookDetails!: boolean;

    @Prop({ default: false, type: Boolean })
    readonly listView!: boolean;

    @Prop()
    carousel!: CarouselModel;

    @Prop()
    popoverTagId!: string;

    private _uid!: string;

    MultipartType = MultipartType;

    get collectionMenuId(): string {
        return `addToCollection_${this._uid}`;
    }

    get useCollectionMenu(): boolean {
        return this.auth.hasTeacherRole;
    }

    get title(): string {
        return formatBookTitle(this.book);
    }

    get type(): string {
        return this.book.bookType;
    }

    get inCollection(): boolean {
        return this.collectionStore.isInCollection(this.book.idNumber);
    }

    get idNumber(): string {
        return this.book.idNumber;
    }

    get collectionTitle(): string {
        let title: string;

        if (this.auth.hasTeacherRole) {
            title = this.inCollection ? 'Add a tag or remove from My Collection' : 'Add to My Collection';
        } else {
            title = this.inCollection ? 'Remove from My Favorites' : 'Add to My Favorites';
        }

        return title;
    }

    get collectionIcon(): string {
        let icon: string;

        if (this.auth.hasTeacherRole) {
            icon = this.inCollection ? 'check_box' : 'add_box';
        } else {
            icon = this.inCollection ? 'favorite' : 'favorite_border';
        }

        return icon;
    }

    get lexileWeight(): string {
        return formattedLexile(this.book.lexileWeight);
    }

    onLoadImg() {
        this.$emit('cover:loaded');
    }

    clickCard(e: MouseInput) {
        if (!e.target.matches('.book-card__button-icon')) {
            this.hidePopovers();
            this.$emit('cardClick', {
                idNumber: this.idNumber,
                query: this.book.query,
                containerId: this.book.containerId,
                carousel: this.carousel,
                multipartType: this.book.multipartType,
            } as CardClickEvent);
        }
    }

    clickAssign() {
        this.hidePopovers();
        this.$emit('cardClick', {
            idNumber: this.idNumber,
            action: 'assign',
        } as CardClickEvent);
    }

    clickCollection() {
        this.hidePopovers();
        if (this.inCollection) {
            if (!this.auth.hasTeacherRole) {
                this.collectionStore.removeBookFromCollection(this.idNumber);
            }
        } else {
            this.collectionStore.addBookIntoCollection(this.idNumber);
        }
    }

    hidePopovers() {
        this.$root.$emit('bv::hide::popover');
    }

    onTagsUpdate(tags: TagModel[]) {
        this.$emit('tagsUpdate', tags);
    }
}
