import { defineStore } from 'pinia';

import { lexileRestService } from '@/rest';
import type { LexilePollingState } from './types';

let pollingInterval: number;
const POLLING_TIMEOUT = 5 * 60 * 1000;

export const useLexilePolling = defineStore('lexile-polling', {
    state: (): LexilePollingState => ({
        hasTestAssigned: false,
        newLexileAvailable: false,
        shouldDisplayReminder: false,
    }),

    actions: {
        async startAssessmentAvailabilityCheck() {
            const { data: isNewLexileAvailable } = await lexileRestService.checkNewLexileAvailable();

            this.newLexileAvailable = isNewLexileAvailable;

            pollingInterval = setInterval(async () => {
                const { data: isNewLexileAvailable } = await lexileRestService.checkNewLexileAvailable();
                this.newLexileAvailable = isNewLexileAvailable;
            }, POLLING_TIMEOUT);
        },

        showReminder(value: boolean) {
            this.shouldDisplayReminder = value;
        },

        clearPolling() {
            clearInterval(pollingInterval);
        },
    },
});
