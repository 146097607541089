import { SearchParamsModel } from './types';

const DEFAULT_BOOK_SEARCH_SIZE = 12;

export const defaultSearchParams: SearchParamsModel = {
    size: DEFAULT_BOOK_SEARCH_SIZE,
    last: false,
    page: 0,
    totalPages: 0,
    totalElements: 0
};
