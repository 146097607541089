import { AxiosRequestConfig } from 'axios';
import BaseRestService from '@/common/rest/base-rest-service';
import type { IPageRequest } from '@/rest';
import { AxiosPromise } from 'axios';
import type { BookProgress } from '@/types';
import { IBookCompletetion } from '@/types';
class BookshelfRestService extends BaseRestService {
    getPageableBooks(page: IPageRequest) {
        return super.get(`/api/books?page=${page.page}&size=${page.size}`);
    }

    getBookDetails(idNumber: string, config?: AxiosRequestConfig) {
        return super.get(`/api/books/${idNumber}/details`, config);
    }

    getBooksByGroup(groupId: number) {
        return super.get(`/api/books/groups/${groupId}`);
    }

    getBooksCompletedByUser(bookIdNumber: string): AxiosPromise<IBookCompletetion[]> {
        return super.get(`/api/books/users/book/${bookIdNumber}/multipart/completed`);
    }

    getBookFile(idNumber: string, config?: AxiosRequestConfig) {
        const data = {
            method: 'GET',
            url: `/api/books/${idNumber}/file`,
            responseType: 'blob',
            ...config,
        };
        return super.customRequest(data, config);
    }

    getBookCover(idNumber: string) {
        const data = {
            method: 'GET',
            url: `/api/books/${idNumber}/cover`,
            responseType: 'blob',
        };
        return super.cancelableShadowCustomRequest(data);
    }

    getAllBooks() {
        return super.get('/api/books');
    }

    async saveBookProgress(bookProgress: BookProgress): Promise<void> {
        await super.post(`/api/reading_progress`, {
            bookIdNumber: bookProgress.bookIdNumber,
            lastPageCoordinate: bookProgress.lastPageCoordinate,
            quiz: bookProgress.quiz,
        });
    }

    async getBookProgress(bookId: string): Promise<BookProgress | null> {
        const result = await super.get(`/api/reading_progress?bookIdNumber=${bookId}`);
        if (result && result.data) {
            const data = result.data;
            if (
                typeof data.bookIdNumber === 'string' &&
                typeof data.lastPageCoordinate === 'string' &&
                typeof data.quiz === 'boolean'
            ) {
                return {
                    bookIdNumber: data.bookIdNumber,
                    lastPageCoordinate: data.lastPageCoordinate,
                    quiz: data.quiz,
                } as BookProgress;
            } else {
                throw new Error('API: Invalid object type.');
            }
        }
        return null;
    }
}

const bookshelfRestService: BookshelfRestService = new BookshelfRestService();

export default bookshelfRestService;
