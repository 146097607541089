import { DebouncedFunc, throttle } from 'lodash';

const bulkAddEventListeners = (events: string[] = [], handler: () => void) => {
    events.forEach(event => {
        document.addEventListener(event, handler);
    });
};

const bulkRemoveEventListeners = (events: string[] = [], handler: () => void) => {
    events.forEach(event => {
        document.removeEventListener(event, handler);
    });
};

const IDLE_LIMIT = 900000;
const HEARTBEAT_INTERVAL = 1000;
const STORAGE_KEY = '_expiresAt';
const THROTTLE_TIMEOUT = 300;

export default class IdleTracker {
    private events: string[] = [
        'keydown',
        'scroll',
        'wheel',
        'click',
        'mousemove',
        'mousewheel',
        'touchstart',
        'touchmove'
    ];

    private interval: number | undefined;

    private idleLimit: number;

    private storageKey: string;

    private throttledUpdate: DebouncedFunc<() => void>;

    private onIdleTimeout: () => void;

    constructor({ idleLimit = IDLE_LIMIT, storageKey = STORAGE_KEY, onIdleTimeout = () => ({}) }) {
        this.idleLimit = idleLimit;
        this.storageKey = storageKey;
        this.onIdleTimeout = onIdleTimeout;
        this.throttledUpdate = throttle(this.updateExpirationTime.bind(this), THROTTLE_TIMEOUT);

        if (this.hasExpired) {
            this.onIdleTimeout();
            this.cleanUp();
            return;
        }

        this.startIdleTracking();
    }

    get hasExpired(): boolean {
        const expiresAt = localStorage.getItem(this.storageKey);

        if (!expiresAt) {
            return true;
        }

        return Date.now() >= Number.parseInt(expiresAt, 10);
    }

    checkExpired() {
        if (this.hasExpired) {
            this.onIdleTimeout();
            this.stopIdleTracking();
        }
    }

    cleanUp() {
        clearInterval(this.interval);
        localStorage.removeItem(this.storageKey);
    }

    startIdleTracking() {
        this.updateExpirationTime();
        bulkAddEventListeners(this.events, this.throttledUpdate);
        this.interval = window.setInterval(() => this.checkExpired(), HEARTBEAT_INTERVAL);
    }

    stopIdleTracking() {
        bulkRemoveEventListeners(this.events, this.throttledUpdate);
        this.throttledUpdate.cancel();
        this.cleanUp();
    }

    updateExpirationTime() {
        localStorage.setItem(this.storageKey, String(Date.now() + this.idleLimit));
    }
}
