import Vue from 'vue';
import Router from 'vue-router';

import routes from './routes';
import authGuard from './guards/auth-guard';
import aclGuard from './guards/acl-guard';
import routeGuard from './guards/route-guard';

export * from './routes';

Vue.use(Router);

const router = new Router({ routes });

router.beforeEach(authGuard);
router.beforeEach(aclGuard);
router.beforeEach(routeGuard);

export default router;
