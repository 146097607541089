import { defineStore } from 'pinia';

import { classroomRestService } from '@/rest';
import { useClassroomRosterStore } from './roster';

import type { BatchImportStudentModel } from '@/types';
import type { TeacherRosteringState } from './types';

export const useTeacherRosteringStore = defineStore('teacher-rostering', {
    state: (): TeacherRosteringState => ({
        isSaving: false,
        hasChanges: false,
        uploadedStudents: [],
    }),

    actions: {
        async createStudents(payload: BatchImportStudentModel[]) {
            const { classroomId } = useClassroomRosterStore();

            if (!classroomId) {
                return;
            }

            this.isSaving = true;

            try {
                const students = payload.map((student: BatchImportStudentModel) => {
                    delete student.id;

                    return student;
                });

                return classroomRestService.bulkCreateStudents({
                    classroomId,
                    students,
                });
            } finally {
                this.isSaving = false;
            }
        },
    },
});
