import { Route } from 'vue-router';

export const castNumericParamsToProps = (route: Route, params: string[]) =>
    params.reduce(
        (acc, param) => ({
            ...acc,
            [param]: route.params[param] ? Number.parseInt(route.params[param], 10) : route.params[param],
        }),
        {}
    );
