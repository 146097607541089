import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';
import Vuetify from 'vuetify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import VModal from 'vue-js-modal';
import VueLodash from 'vue-lodash';
import VueLazyload from 'vue-lazyload';
import VueImg from 'v-img';
import VueWait from 'vue-wait';
import VCalendar from 'v-calendar';
import Vuelidate from 'vuelidate';
import vuelidateErrorExtractor from 'vuelidate-error-extractor';
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import Notifications from 'vue-notification';
import PortalVue from 'portal-vue';
import { directive as onClickaway } from 'vue-clickaway';

import '@lottiefiles/lottie-player';
import 'vuetify/dist/vuetify.min.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vue2-timepicker/dist/VueTimepicker.css';
import 'vue-slider-component/theme/default.css';
import '@/styles/overrides/index.scss';

import store, { pinia } from '@/store';
import router from '@/router';
import App from './App.vue';
import { TestIdDirective } from '@/common/directives/test-id-directive';
import { ScrollTopDirective } from '@/common/directives/scrolltop';
import { FocusDirective } from '@/common/directives/focus';
import {
    commaSeparated,
    dash,
    formatNumber,
    formatStudentName,
    formattedDate,
    formattedDateRange,
    formattedLexile,
    formatUserName,
    fullName,
    fullNameReverse,
    pluralize,
} from '@/filters';
import axiosSetup from '@/axios-config';

import LoadingImage from '@/assets/loading-spinner.svg';
import ErrorImage from '@/assets/image.svg';

Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(fas); // Include needed icons.

Vue.use(Vuetify, {
    iconfont: 'mdi' || 'md' || 'mdi' || 'fa' || 'fa4',
});

Vue.use(VModal, { dialog: true, dynamic: true });
Vue.use(BootstrapVue);

Vue.use(VueLazyload, {
    preLoad: 1.3,
    loading: LoadingImage,
    error: ErrorImage,
    attempt: 1,
    observer: true,
});
Vue.use(VueLodash, { name: 'lodash' });

// Image popup
Vue.use(VueImg);
Vue.use(VCalendar);
Vue.use(Vuelidate);
Vue.use(vuelidateErrorExtractor);
Vue.use(VueWait);
Vue.use(VueBreadcrumbs);
Vue.use(Notifications);
Vue.use(PortalVue);

Vue.filter('commaSeparated', commaSeparated);
Vue.filter('dash', dash);
Vue.filter('formatStudentName', formatStudentName);
Vue.filter('formattedDateRange', formattedDateRange);
Vue.filter('formattedDate', formattedDate);
Vue.filter('formattedLexile', formattedLexile);
Vue.filter('number', formatNumber);
Vue.filter('fullName', fullName);
Vue.filter('fullNameReverse', fullNameReverse);
Vue.filter('formatUserName', formatUserName);
Vue.filter('pluralize', pluralize);

Vue.directive('test-id', TestIdDirective);
Vue.directive('scrolltop', ScrollTopDirective);
Vue.directive('focus', FocusDirective);
Vue.directive('on-clickaway', onClickaway);

Vue.config.ignoredElements = ['lottie-player'];

axiosSetup();

const app = new Vue({
    pinia,
    router,
    store,
    wait: new VueWait({ useVuex: true }),
    render: (h) => h(App),
}).$mount('#app');

export default app;
