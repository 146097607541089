import { RouteConfig } from 'vue-router';
import { Role } from '@/constants';

export enum MySettingsRoute {
    Index = '[MySettingsRoute]  Index',
    AccountSettings = '[MySettingsRoute] AccountSettings',
    BookshelfSettings = '[MySettingsRoute] BookshelfSettings',
    BookshelfRows = '[MySettingsRoute] BookshelfRows',
}

const MySettingsView = () =>
    import(/* webpackChunkName: "my-settings" */ '@/site-pages/my-settings/MySettingsView.vue');

const AccountSettingsView = () =>
    import(/* webpackChunkName: "my-settings" */ '@/site-pages/my-settings/account-settings/AccountSettingsView.vue');

const BookshelfSettingsView = () =>
    import(
        /* webpackChunkName: "my-settings" */ '@/site-pages/my-settings/bookshelf-settings/BookshelfSettingsView.vue'
    );

const BookshelfRowsView = () =>
    import(/* webpackChunkName: "my-settings" */ '@/site-pages/my-settings/bookshelf-rows/BookshelfRowsView.vue');

const mySettingsRoutes: RouteConfig[] = [
    {
        path: '/my-settings',
        component: MySettingsView,
        name: MySettingsRoute.Index,
        redirect: { name: MySettingsRoute.AccountSettings },
        meta: { roles: [Role.TEACHER] },
        children: [
            {
                path: 'account-settings',
                name: MySettingsRoute.AccountSettings,
                component: AccountSettingsView,
                meta: { roles: [Role.TEACHER] },
            },
            {
                path: 'bookshelf-settings',
                name: MySettingsRoute.BookshelfSettings,
                component: BookshelfSettingsView,
                meta: { roles: [Role.TEACHER] },
            },
            {
                path: 'bookshelf-rows',
                name: MySettingsRoute.BookshelfRows,
                component: BookshelfRowsView,
                meta: { roles: [Role.TEACHER] },
            },
        ],
    },
];

export default mySettingsRoutes;
