
import { Component, Prop, Vue } from 'vue-property-decorator';
import Paginate from 'vuejs-paginate';

import { formatNumber, pluralize } from '@/filters';

@Component({
    components: {
        Paginate,
    },
})
export default class Pagination extends Vue {
    @Prop({ default: 0, type: Number })
    currentPage!: number;

    @Prop({ default: 0 })
    count!: number;

    @Prop()
    pageSize!: number;

    @Prop()
    pageCount!: number;

    @Prop()
    total!: number;

    @Prop()
    query!: string;

    get summary(): string {
        const { resultsRange, total } = this;
        const resultsWord = pluralize('result', total);

        const searchRequest = this.query ? `for “<b>${this.query}</b>”` : '';

        return `Showing ${resultsRange} ${resultsWord} ${searchRequest}`;
    }

    get resultsRange(): string {
        const { endingNumber, count, startingNumber, pageCount, total } = this;

        return pageCount > 1
            ? `${formatNumber(startingNumber)}&ndash;${formatNumber(endingNumber)} of ${formatNumber(total)}`
            : formatNumber(count);
    }

    get startingNumber(): number {
        const { currentPage, pageSize } = this;

        return currentPage * pageSize + 1;
    }

    get endingNumber(): number {
        const { pageSize } = this;
        const endingNumber = this.startingNumber + pageSize - 1;

        return endingNumber > this.total ? this.total : endingNumber;
    }

    get selectedPage(): number {
        return this.currentPage + 1;
    }

    set selectedPage(value: number) {
        this.$emit('change', value);
    }

    get pagesOverfow(): boolean {
        const { pageCount } = this;
        return pageCount > 10;
    }

    get hasPages(): boolean {
        const { pageCount } = this;
        return pageCount > 1;
    }
}
