import { AxiosPromise } from 'axios';
import BaseRestService from '@/common/rest/base-rest-service';

class ReadAloudRestService extends BaseRestService {
    endpoints = {
        get: (id) => `/api/books/aloud-reading/review/${id}`,
        update: () => `/api/books/aloud-reading/grade`,
    };

    getReadAloud(id: number): AxiosPromise<any> {
        const endpoint = this.endpoints.get(id);
        return super.get(endpoint);
    }

    updateReadAloud(payload: { id: number; notes: string }): AxiosPromise<any> {
        const endpoint = this.endpoints.update();
        return super.put(endpoint, payload);
    }
}

const readAloudRestService = new ReadAloudRestService();

export default readAloudRestService;
