
import { Component, Vue } from 'vue-property-decorator';

interface MenuItem {
    name: string;
    route: string;
}

@Component
export default class Menu extends Vue {
    menuItems: MenuItem[] = [
        {
            name: 'Reports',
            route: 'reports',
        },
        {
            name: 'Rosters',
            route: 'rosters.districts',
        },
        {
            name: 'Assignments',
            route: 'assignments',
        },
    ];
}
