import VueRouter from 'vue-router';
const { isNavigationFailure, NavigationFailureType } = VueRouter;

export const handleNavigationFailure = (e: Error) => {
    if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
        Promise.reject(e);
    }
};

export const handleNavigationDuplicated = (e: Error) => {
    if (!isNavigationFailure(e, NavigationFailureType.duplicated)) {
        Promise.reject(e);
    }
};
