import { defineStore } from 'pinia';
import carouselRestService from '@/rest/carousels';
import { useCollectionStore } from '@/store/collection';
import type { BookModel, CarouselModel } from '@/types';
import type { BookshelfState } from './types';

export const useBookshelfLandingStore = defineStore('bookshelf-landing', {
    state: (): BookshelfState => ({
        isLoading: false,
        books: {},
        carousels: [],
        abortController: null,
    }),

    actions: {
        addCarousels(carousels: CarouselModel[]) {
            const existingCarouselIds = this.carousels.map((carousel) => carousel.id);

            this.carousels = this.carousels.concat(
                carousels.filter((carousel) => !existingCarouselIds.includes(carousel.id))
            );
        },

        async fetchCarousels(landingType: 'NYC' | 'GRAMMAR_WRITING_INTERVENTION' | 'POETRY') {
            this.isLoading = true;
            const collectionStore = useCollectionStore();

            try {
                if (!this.abortController) {
                    this.abortController = new AbortController();
                }

                let carousels = [] as CarouselModel[];

                if (landingType === 'NYC') {
                    carousels = (
                        await carouselRestService.getNycCarousels({
                            signal: this.abortController.signal,
                        })
                    ).data;
                } else if (landingType === 'GRAMMAR_WRITING_INTERVENTION') {
                    carousels = (
                        await carouselRestService.getGrammarWritingInterventionCarousels({
                            signal: this.abortController.signal,
                        })
                    ).data;
                } else if (landingType === 'POETRY') {
                    carousels = (
                        await carouselRestService.getPoetryCarousels({
                            signal: this.abortController.signal,
                        })
                    ).data;
                }

                const books = carousels.reduce(
                    (acc: BookModel[], carousel: CarouselModel) => acc.concat(carousel.books),
                    []
                );

                this.addCarousels(carousels);
                collectionStore.addBooksToCollection(books.filter((book: BookModel) => book.inCollection));
            } finally {
                this.isLoading = false;
                this.abortController = null;
            }
        },

        reset() {
            this.carousels = [];

            if (this.abortController) {
                this.abortController.abort('Cancel previous requests.');
                this.abortController = null;
            }

            this.$reset();
        },
    },
});
