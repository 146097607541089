import { defineStore } from 'pinia';

import { clozeRestService } from '@/rest';
import { ClozeState } from './types';

export const useClozeStore = defineStore('cloze', {
    state: (): ClozeState => ({
        isLoading: false,
        passage: {
            clozes: [],
            lexile: 0,
            raw_text: '',
            text: '',
        },
        score: {
            b_new: 0,
            sigma_new: 0,
        },
    }),

    actions: {
        async calculateLexile() {
            this.isLoading = true;

            try {
                const { data: score } = await clozeRestService.calculateLexile();

                this.score = score;
            } finally {
                this.isLoading = false;
            }
        },

        async getCloze(passageText: string) {
            this.isLoading = true;

            try {
                const { data: passage } = await clozeRestService.getCloze(passageText);

                this.passage = passage;
            } finally {
                this.isLoading = false;
            }
        },

        async submitClozeFile(formData: FormData) {
            this.isLoading = true;

            try {
                await clozeRestService.submitClozeFile(formData);
            } finally {
                this.isLoading = false;
            }
        },

        async submitIntakeFile(formData: FormData) {
            this.isLoading = true;

            try {
                await clozeRestService.submitInTakeFile(formData);
            } finally {
                this.isLoading = false;
            }
        },

        async submitTestletFile(formData: FormData) {
            this.isLoading = true;
            try {
                await clozeRestService.submitTestletFile(formData);
            } finally {
                this.isLoading = false;
            }
        },
    },
});
