export enum MediaType {
    APPLICATION_EPUB_ZIP = 'application/epub+zip',
    APPLICATION_JSON = 'application/json',
    APPLICATION_SMIL = 'application/smil+xml',
    APPLICATION_NCX = 'application/x-dtbncx+xml',
    APPLICATION_OPF_PACKAGE = 'application/oebps-package+xml',
    APPLICATION_PDF = 'application/pdf',
    APPLICATION_XHTML = 'application/xhtml+xml',
    APPLICATION_VND_MS_OPENTYPE = 'application/vnd.ms-opentype',
    APPLICATION_XML = 'application/xml',
    APPLICATION_ZIP = 'application/zip',
    AUDIO_MPEG = 'audio/mpeg',
    AUDIO_MP4 = 'audio/mp4',
    AUDIO_OGG = 'audio/ogg',
    AUDIO_WEBM = 'audio/webm',
    AUDIO_WAV = 'audio/wav',
    AUDIO_WAVE = 'audio/wave',
    FONT_OTF = 'font/opentype',
    FONT_TTF = 'font/truetype',
    FONT_WOFF = 'font/woff',
    FONT_WOFF2 = 'font/woff2',
    IMAGE_BMP = 'image/bmp',
    IMAGE_PNG = 'image/png',
    IMAGE_JPEG = 'image/jpeg',
    IMAGE_SVG = 'image/svg+xml',
    IMAGE_GIF = 'image/gif',
    TEXT_CSS = 'text/css',
    TEXT_JAVASCRIPT = 'text/javascript',
    TEXT_HTML = 'text/html',
    VIDEO_MP4 = 'video/mp4',
    VIDEO_WEBM = 'video/webm',
    MULTIPART_FORM_DATA = 'multipart/form-data'
}
