import { defineStore } from 'pinia';

import { classroomRestService } from '@/rest';

import type { ClassroomInboxItem, ConstructedResponseModel, ResponseGrade, SkillStandardGroupDto } from '@/types';
import type { InboxState } from './types';
import { useClassroomSwitcherStore } from '@/store';

export const useInboxStore = defineStore('inbox', {
    state: (): InboxState => ({
        filters: {
            type: -1,
            query: '',
        },
        responses: [],
        quizResponse: null,
        hasChanges: false,
        isSaving: false,
        isFetching: false,
    }),

    actions: {
        async fetchResponses() {
            this.isFetching = true;

            try {
                const { data: responses } = await classroomRestService.getConstructedResponse();

                this.responses = responses.map((response: ClassroomInboxItem) => ({
                    ...response,
                    username: response.userUsername,
                    name: response.userName,
                    surname: response.userSurname,
                }));
            } finally {
                this.isFetching = false;
            }
        },

        async fetchQuizResponse({ studentId, questionId }: { studentId: number; questionId: number }) {
            this.isFetching = true;

            try {
                const { data: quizResponse } = await classroomRestService.getUserQuiz(studentId, questionId);

                this.quizResponse = quizResponse;
            } finally {
                this.isFetching = false;
            }
        },

        async submitResponseGrade() {
            if (!this.quizResponse) {
                return;
            }

            this.isSaving = true;

            try {
                const { userId: studentId, questionId, respMeets: respMeet } = this.quizResponse;

                const payload = {
                    studentId,
                    questionId,
                    respMeet,
                };

                const { data: updatedResponseDetail }: { data: ConstructedResponseModel } =
                    await classroomRestService.changeSkillStandardMeet(payload);

                this.quizResponse = {
                    ...this.quizResponse,
                    respMeets: updatedResponseDetail.respMeets,
                };
            } finally {
                this.isSaving = false;
            }
        },

        setFilter({ key, value }) {
            this.filters = {
                ...this.filters,
                [key]: value,
            };
        },

        gradeResponse(skillStandardGroup: SkillStandardGroupDto, meets: boolean) {
            if (!this.quizResponse) {
                return;
            }

            const responseGradeIndex = this.quizResponse.respMeets.findIndex(
                (respMeet: ResponseGrade) =>
                    respMeet.skillId === skillStandardGroup.skill.id &&
                    respMeet.standardId === skillStandardGroup.standard.id
            );

            if (responseGradeIndex !== -1) {
                this.quizResponse.respMeets[responseGradeIndex].meet = meets;
            } else {
                const { userId, questionId } = this.quizResponse;
                const {
                    skill: { id: skillId },
                    standard: { id: standardId },
                } = skillStandardGroup;

                const responseGrade = {
                    userId,
                    questionId,
                    skillId,
                    standardId,
                    meet: meets,
                };
                this.quizResponse.respMeets = [...this.quizResponse.respMeets, responseGrade];
            }

            this.hasChanges = true;
        },

        resetDetails() {
            this.hasChanges = false;
            this.quizResponse = null;
        },
    },

    getters: {
        responsesToGrage: (state: InboxState) => {
            const classroomSwitcherStore = useClassroomSwitcherStore();

            if (classroomSwitcherStore.selectedStudentIds.length) {
                return state.responses.filter((response: ClassroomInboxItem) => {
                    return (
                        !response.constructedAnswersAssessed &&
                        classroomSwitcherStore.selectedStudentIds.includes(response.userId)
                    );
                });
            } else {
                return state.responses.filter((response: ClassroomInboxItem) => !response.constructedAnswersAssessed);
            }
        },

        gradedResponses: (state: InboxState) => {
            const classroomSwitcherStore = useClassroomSwitcherStore();

            if (classroomSwitcherStore.selectedStudentIds.length) {
                return state.responses.filter((response: ClassroomInboxItem) => {
                    return (
                        response.constructedAnswersAssessed &&
                        classroomSwitcherStore.selectedStudentIds.includes(response.userId)
                    );
                });
            } else {
                return state.responses.filter((response: ClassroomInboxItem) => response.constructedAnswersAssessed);
            }
        },

        responseDetails: (state): ConstructedResponseModel =>
            state.quizResponse
                ? {
                      ...state.quizResponse,
                      questionSkillStandardGroups: state.quizResponse?.questionSkillStandardGroups.map(
                          (group: SkillStandardGroupDto) => {
                              const responseGrade = state.quizResponse?.respMeets.find(
                                  (grade: ResponseGrade) =>
                                      grade.questionId === state.quizResponse?.questionId &&
                                      grade.userId === state.quizResponse?.userId &&
                                      grade.skillId === group.skill.id &&
                                      grade.standardId === group.standard.id
                              );

                              return {
                                  ...group,
                                  ...(responseGrade ? { meet: responseGrade.meet } : {}),
                              };
                          }
                      ),
                  }
                : ({} as ConstructedResponseModel),
    },
});
