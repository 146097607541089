import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';

import gameRouteGuard from '../guards/game-guard';

const gameRoutes: RouteConfig[] = [
    {
        path: '/game',
        name: 'game',
        component: () => import(/* webpackChunkName: "game" */ '@/site-pages/game/LevelupGame.vue'),
        meta: {
            roles: [Role.STUDENT, Role.TEACHER],
        },
        beforeEnter: gameRouteGuard,
    },
];

export default gameRoutes;
