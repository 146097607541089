import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';

const assignRunningRecordRoutes: RouteConfig[] = [
    {
        path: '/assign-running-record',
        name: 'assignRunningRecord',
        component: () => import('@/site-pages/assign-running-record/AssignRunningRecord.vue'),
        meta: {
            roles: [Role.TEACHER],
        },
    },
];

export default assignRunningRecordRoutes;
