import { defineStore } from 'pinia';
import { groupBy } from 'lodash';

import { classroomRestService, assignRestService } from '@/rest';

import { AssignmentModel, AssignmentStatus, AssignmentFilterStatus, IUpdateAssignment } from '@/types';
import type { AssignmentsState } from './types';
import { useClassroomSwitcherStore } from '@/store';

const initialState = (): AssignmentsState => ({
    assignments: [],
    filters: {
        status: AssignmentFilterStatus.ALL,
        studentQuery: '',
        titleQuery: '',
    },
    isLoading: false,
});

export const useClassroomAssignmentsStore = defineStore('classroom-assignments', {
    state: (): AssignmentsState => initialState(),

    actions: {
        async fetchAssignments() {
            this.isLoading = true;

            try {
                const { data: assignments } = await classroomRestService.loadAssignedBooks();

                this.assignments = assignments;
            } finally {
                this.isLoading = false;
            }
        },

        async bulkUnassign(assignments: AssignmentModel[]) {
            const changedAssignments = assignments.map((assignment) => ({
                id: assignment.student.id,
                idNumber: assignment.book.idNumber,
                name: assignment.student.name,
                surname: assignment.student.surname,
                username: assignment.student.username,
                assigned: false,
                dueDate: null,
                displayDate: null,
                type: assignment.type,
            }));

            try {
                this.batchRemoveAssignments(assignments);
                await assignRestService.updateAssignment(changedAssignments);
            } catch (e) {
                assignments.forEach((assignment) => this.addAssignment(assignment));
                throw e;
            }
        },

        async unassign(assignment: AssignmentModel) {
            const changedAssignment: IUpdateAssignment = {
                id: assignment.student.id,
                idNumber: assignment.book.idNumber,
                name: assignment.student.name,
                surname: assignment.student.surname,
                username: assignment.student.username,
                assigned: false,
                dueDate: null,
                displayDate: null,
                type: assignment.type,
            };

            try {
                this.removeAssignment(assignment);
                await assignRestService.updateAssignment([changedAssignment]);
            } catch (e) {
                this.addAssignment(assignment);
                throw e;
            }
        },

        async bulkUpdateAssignments(assignments: AssignmentModel[]) {
            const backupAssignments = JSON.parse(JSON.stringify(this.assignments));

            const changedAssignments = assignments.map((assignment) => ({
                id: assignment.student.id,
                idNumber: assignment.book.idNumber,
                name: assignment.student.name,
                surname: assignment.student.surname,
                username: assignment.student.username,
                assigned: true,
                dueDate: assignment.dueDate,
                displayDate: assignment.displayDate,
                type: assignment.type,
            }));

            try {
                this.batchReplaceAssignments(assignments);
                await assignRestService.updateAssignment(changedAssignments);
            } catch (e) {
                this.assignments = backupAssignments;
                throw e;
            }
        },

        async updateAssignment(assignment: AssignmentModel) {
            const changedAssignment: IUpdateAssignment = {
                id: assignment.student.id,
                idNumber: assignment.book.idNumber,
                name: assignment.student.name,
                surname: assignment.student.surname,
                username: assignment.student.username,
                assigned: true,
                dueDate: assignment.dueDate,
                displayDate: assignment.displayDate,
                type: assignment.type,
            };

            const backupAssignment = this.findAssignment({
                idNumber: assignment.book.idNumber,
                studentId: assignment.student.id,
            });

            try {
                this.replaceAssignment(assignment);
                await assignRestService.updateAssignment([changedAssignment]);
            } catch (e) {
                if (backupAssignment) this.replaceAssignment(backupAssignment);
                throw e;
            }
        },

        setFilter({ key, value }: { key: string; value: string | number | null }) {
            this.filters = {
                ...this.filters,
                [key]: value,
            };
        },

        resetFilters() {
            const { filters } = initialState();
            this.filters = filters;
        },

        addAssignment(assignment: AssignmentModel) {
            this.assignments = [...this.assignments, assignment];
        },

        removeAssignment({ book: { idNumber }, student: { id: studentId } }: AssignmentModel) {
            this.assignments = this.assignments.filter(
                (assignment) =>
                    !(
                        assignment.book.idNumber === idNumber &&
                        assignment.student.id === studentId &&
                        (AssignmentStatus[assignment.status] !== AssignmentStatus.COMPLETED_ON_TIME ||
                            AssignmentStatus[assignment.status] !== AssignmentStatus.COMPLETED_LATE)
                    )
            );
        },

        batchRemoveAssignments(assignments: AssignmentModel[]) {
            assignments.forEach((assignment: AssignmentModel) => {
                this.removeAssignment(assignment);
            });
        },

        replaceAssignment(newAssignment: AssignmentModel) {
            const index = this.findCurrentAssignmentIndex(newAssignment);

            if (index > -1) {
                this.assignments = [
                    ...this.assignments.slice(0, index),
                    newAssignment,
                    ...this.assignments.slice(index + 1),
                ];
            }
        },

        batchReplaceAssignments(newAssignments: AssignmentModel[]) {
            newAssignments.forEach((newAssignment) => {
                this.replaceAssignment(newAssignment);
            });
        },
    },

    getters: {
        findAssignment:
            (state: AssignmentsState) =>
            ({ idNumber, studentId }: { idNumber: string; studentId: number }) =>
                state.assignments.find(
                    (assignment) => assignment.book.idNumber === idNumber && assignment.student.id === studentId
                ) || null,

        findCurrentAssignmentIndex:
            (state: AssignmentsState) =>
            ({ book: { idNumber }, student: { id: studentId } }: AssignmentModel) =>
                state.assignments.findIndex(
                    (assignment) =>
                        assignment.book.idNumber === idNumber &&
                        assignment.student.id === studentId &&
                        (AssignmentStatus[assignment.status] !== AssignmentStatus.COMPLETED_ON_TIME ||
                            AssignmentStatus[assignment.status] !== AssignmentStatus.COMPLETED_LATE)
                ),

        runningRecordsAssigments: (state: AssignmentsState): AssignmentModel[] => {
            const classroomSwitcherStore = useClassroomSwitcherStore();

            if (classroomSwitcherStore.selectedStudentIds.length) {
                return state.assignments.filter((assignment: AssignmentModel) => {
                    return (
                        assignment.type === 'running_record' &&
                        classroomSwitcherStore.selectedStudentIds.includes(assignment.student.id)
                    );
                });
            } else {
                return state.assignments.filter((assignment: AssignmentModel) => {
                    return assignment.type === 'running_record';
                });
            }
        },

        booksAssigments: (state: AssignmentsState): AssignmentModel[] => {
            const classroomSwitcherStore = useClassroomSwitcherStore();

            if (classroomSwitcherStore.selectedStudentIds.length) {
                return state.assignments.filter((assignment: AssignmentModel) => {
                    return (
                        assignment.type === 'book' &&
                        classroomSwitcherStore.selectedStudentIds.includes(assignment.student.id)
                    );
                });
            } else {
                return state.assignments.filter((assignment: AssignmentModel) => {
                    return assignment.type === 'book';
                });
            }
        },
    },
});
