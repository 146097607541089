import { defineStore } from 'pinia';

import { Role } from '@/constants';
import authRestService from '@/rest/auth';
import { usersRestService } from '@/rest/users-rest-service';
import { fullName } from '@/filters';
import router from '@/router';
import { BookshelfRoute } from '@/router/routes/bookshelf';

import { TokenService } from '@/utils';

import store, { useLexilePolling, useLexileAssessmentsStore, resetAllStores, useBookReaderStore } from '@/store';
import { ReportExternals } from '@/store/reports/types';
import { TrackExternals } from '@/common/rest/track/track-polling-model';
import trackRestService from '@/common/rest/track/track-rest-service';

import { InterfaceType, StudentInterfaceTypeSetting, type AuthState } from './types';
import type { Credentials, UserDetailModel } from '@/types';

export const useAuth = defineStore('auth', {
    state: (): AuthState => ({
        isAuthenticated: Boolean(TokenService.getAuthStatus()),
        error: null,
        loading: false,
        profile: TokenService.getProfile(),
        role: TokenService.getRole(),
        username: TokenService.getUsername(),
        userToken: TokenService.getUserToken(),
        interfaceType: TokenService.getInterfaceType(),
        isFullInterfaceType: TokenService.getIsFullInterfaceType(),
        fetchingProfile: false,
    }),

    actions: {
        async login(credentials: Credentials) {
            const lexileAssessmentsStore = useLexileAssessmentsStore();

            store.commit(ReportExternals.resetFilters, null, { root: true });

            await authRestService.login(credentials.userName, credentials.password);
            await trackRestService.trackLoggedInTime({ timeToTrack: 5 });
            await this.fetchProfile();

            store.dispatch(TrackExternals.startLogTime, {}, { root: true });
            lexileAssessmentsStore.triggerLexileCheck();
        },

        async badgeLogin(token: string) {
            await authRestService.badgeLogin(token);

            store.dispatch(TrackExternals.startLogTime, {}, { root: true });

            return this.fetchProfile();
        },

        async logout() {
            const lexilePolling = useLexilePolling();
            const bookReader = useBookReaderStore();

            this.isAuthenticated = false;
            this.profile = null;

            store.dispatch(TrackExternals.clearPolling, null, { root: true });
            bookReader.deleteBookCookies();
            store.dispatch('reset', null, { root: true });

            lexilePolling.clearPolling();
            resetAllStores();

            await authRestService.logout();
        },

        async fetchProfile() {
            const lexilePolling = useLexilePolling();

            this.fetchingProfile = true;

            try {
                const { data: user }: { data: UserDetailModel } = await usersRestService.getUserDetails();
                const { role, username, uuid, accountFlag, interfaceSetting } = user;

                this.isAuthenticated = Boolean(true);
                this.role = role;
                this.username = username;
                this.userToken = uuid;
                this.profile = user;

                authRestService.setAuthState('true');
                authRestService.setRole(role ?? '');
                authRestService.setUsername(username);
                authRestService.setUserToken(uuid);
                authRestService.setProfile(user);

                if (!accountFlag) {
                    this.interfaceType = InterfaceType.CLASSIC;
                    authRestService.setInterfaceType(InterfaceType.CLASSIC);
                    this.isFullInterfaceType = false;
                    authRestService.setIsFullInterfaceType(false);
                } else {
                    switch (accountFlag) {
                        case 'NEXT':
                            this.interfaceType = InterfaceType.NEXT;
                            authRestService.setInterfaceType(InterfaceType.NEXT);
                            this.isFullInterfaceType = false;
                            authRestService.setIsFullInterfaceType(false);
                            break;
                        case 'FULL':
                            this.isFullInterfaceType = true;
                            authRestService.setIsFullInterfaceType(true);

                            if (this.role === Role.STUDENT) {
                                if (interfaceSetting === StudentInterfaceTypeSetting.UPPER_ELEMENTARY) {
                                    this.interfaceType = InterfaceType.NEXT;
                                    authRestService.setInterfaceType(InterfaceType.NEXT);
                                } else {
                                    this.interfaceType = InterfaceType.CLASSIC;
                                    authRestService.setInterfaceType(InterfaceType.CLASSIC);
                                }
                            } else {
                                this.interfaceType = InterfaceType.NEXT;
                                authRestService.setInterfaceType(InterfaceType.NEXT);
                            }
                            break;
                        default:
                            this.interfaceType = InterfaceType.CLASSIC;
                            authRestService.setInterfaceType(InterfaceType.CLASSIC);
                            this.isFullInterfaceType = false;
                            authRestService.setIsFullInterfaceType(false);
                    }
                }

                lexilePolling.hasTestAssigned = user.isNewTestAssigned;
            } finally {
                this.fetchingProfile = false;
            }
        },

        async oauthLogin() {
            const lexileAssessmentsStore = useLexileAssessmentsStore();

            await this.fetchProfile();

            store.dispatch(TrackExternals.startLogTime, {}, { root: true });
            lexileAssessmentsStore.triggerLexileCheck();
        },

        async loginAs(username: string) {
            await authRestService.loginAs(username);
            await this.fetchProfile();

            router.push({
                name: BookshelfRoute.BOOKSHELF,
            });
        },
    },

    getters: {
        fullname: (state: AuthState) => (state.profile ? fullName(state.profile) : null),

        hasTeacherRole: (state: AuthState) => state.role === Role.TEACHER,

        hasStudentRole: (state: AuthState) => state.role === Role.STUDENT,

        hasManagerRole: (state: AuthState): boolean =>
            Boolean(state.role) &&
            [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER, Role.SCHOOL_MANAGER]
                .map((role) => String(role))
                .includes(state.role!),

        hasMultiDistrictRole: (state: AuthState) => state.role === Role.MULTI_DISTRICT_MANAGER,

        getUserToken: (state: AuthState) => state.userToken || '',

        hasAdminRole: (state: AuthState) => state.role === Role.ADMIN,
    },
});
