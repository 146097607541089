import { Grade, UserModel, ClassroomUserModel } from '@/types';

export interface GroupModel {
    id: number;
    name: string;
    parentGroupId?: number | null;
}

export interface ClassroomModel extends GroupModel {
    grade?: Grade | null;
    gradeLevel?: Grade | null;
    teachers: UserModel[];
}

export interface ClassroomDetailsDto extends ClassroomModel {
    paymentSettings: Payment | null;
    users: UserModel[];
}

export interface ClassroomDetailsModel extends ClassroomModel {
    users: ClassroomUserModel[];
    paymentSettings: Payment | null;
    classroomSettings: ClassroomSettings | null;
}

export interface DistrictModel {
    id: number;
    name: string;
    standard: string;
    schoolIds?: number[];
}

export interface SchoolDto {
    id: number;
    name: string;
    classrooms: ClassroomModel[];
}

export interface SchoolModel {
    id: number;
    name: string;
    classrooms: number[];
}

export enum DistrictOrders {
    BY_NAME = 'Sort by district name',
    BY_STANDARD = 'Group by learning standard',
}

export type DistrictOrder = keyof typeof DistrictOrders;

export interface Payment {
    paymentId: string;
    expirationDate: string | null;
}

export interface UserGroupModel {
    id: number;
    district: string;
    school: string;
    classroom: string;
}

export interface GroupItem {
    id: number;
    name: string;
    classrooms?: GroupItem[];
}

export interface ClassroomSettings {
    canTeacherAddStudents: boolean;
    maximumCapacity: number;
}
