import BaseRestService from '@/common/rest/base-rest-service';
import { InterfaceType } from '@/store/auth/types';
import { UserDetailModel } from '@/types';
import { TokenService } from '@/utils';

class LoginRestService extends BaseRestService {
    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     **/
    login(username: string, password: string) {
        return super.post('/api/login', {
            username,
            password,
        });
    }

    badgeLogin(token: string) {
        return super.post('/api/login/badge', { token });
    }

    setAuthState(isAuthenticated: string): void {
        TokenService.saveAuthStatus(isAuthenticated);
    }

    /**
     * Logout the current user by removing the tokens from storage.
     **/
    logout() {
        TokenService.removeAuthStatus();
        TokenService.removeRole();
        TokenService.removeUsername();
        TokenService.removeLexileCheck();
        TokenService.removeUserToken();
        TokenService.removeProfile();
        // TokenService.removeInterfaceType();
        // TokenService.removeIsFullInterfaceType();

        return super.post('/api/logout', null);
    }

    getUserRole() {
        return super.get('/api/roles');
    }

    setRole(role: string): void {
        TokenService.setRole(role);
    }

    setUsername(username: string): void {
        TokenService.setUsername(username);
    }

    setUserToken(userToken: string): void {
        TokenService.setUserToken(userToken);
    }

    setProfile(profile: UserDetailModel): void {
        TokenService.setProfile(profile);
    }

    setInterfaceType(interfaceType: InterfaceType): void {
        TokenService.setInterfaceType(interfaceType);
    }

    setIsFullInterfaceType(isFullInterfaceType: boolean): void {
        TokenService.setIsFullInterfaceType(isFullInterfaceType);
    }

    loginAs(username: string) {
        return super.post('/api/login/username', { username });
    }
}

const loginRestService: LoginRestService = new LoginRestService();

export default loginRestService;
