import { BookModel } from '@/types/books';

export const formatBookTitle = (book: Partial<BookModel>) => {
    if (!book?.title) {
        return '';
    }

    const { title, titlePrefix } = book;

    return `${titlePrefix ? `${titlePrefix} ` : ''}${title}`;
};
