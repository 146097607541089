import { AxiosPromise } from 'axios';
import BaseRestService from '@/common/rest/base-rest-service';
import { IBookCompletetion } from '@/types';

import type { AssignmentLogEntryModel, StudentAssignmentShort, IUpdateAssignment } from '@/types/assignments';
import { IPageResponse } from './types';

export interface BulkAssignmentParams {
    books: string[];
    displayDate: string | null;
    dueDate: string | null;
    grades: string[];
    lowerLexile?: number;
    students: number[];
    upperLexile?: number;
}

class AssignRestService extends BaseRestService {
    endpoints = {
        bookAssignment: (idNumber: string) => `/api/assign/${idNumber}`,
        updateAssignment: `/api/assign`,
        bulkAssignment: `/api/assign/multi`,
        getBookPartsCompletedByStudents: (bookIdNumber: string) =>
            `/api/books/users/book/${bookIdNumber}/multipart/completed`,
        getBulkAssignment: (pageNum: number) => `/api/assign/multi?page=${pageNum}`,
    };

    assignBook(idNumber: string, students: StudentAssignmentShort[]) {
        const endpoint = this.endpoints.bookAssignment(idNumber);

        return super.put(endpoint, students);
    }

    getAssignments(idNumber: string) {
        const endpoint = this.endpoints.bookAssignment(idNumber);

        return super.get(endpoint);
    }

    getAssignmentLog(pageNum: number): AxiosPromise<IPageResponse<AssignmentLogEntryModel>> {
        const endpoint = this.endpoints.getBulkAssignment(pageNum);

        return super.get(endpoint);
    }

    bulkAssign(params: BulkAssignmentParams) {
        const endpoint = this.endpoints.bulkAssignment;

        return super.post(endpoint, params);
    }

    updateAssignment(students: IUpdateAssignment[]) {
        return super.put(this.endpoints.updateAssignment, students);
    }

    getBookPartsCompletedByStudents(
        bookIdNumber: string,
        studentIds: number[]
    ): AxiosPromise<{
        completedByUser: { [key: number]: IBookCompletetion[] };
    }> {
        const endpoint = this.endpoints.getBookPartsCompletedByStudents(bookIdNumber);
        return super.post(endpoint, studentIds);
    }
}

const assignRestService: AssignRestService = new AssignRestService();

export default assignRestService;
