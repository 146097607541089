import { RouteConfig } from 'vue-router';
import { Role } from '@/constants';

const adminRoutes: RouteConfig[] = [
    {
        path: '/admin',
        component: () => import(/* webpackChunkName: "admin-layout" */ '@/common/layouts/admin/AdminLayout.vue'),
        children: [
            {
                path: '',
                redirect: '/admin/clozes',
                meta: {
                    roles: [Role.ADMIN],
                },
            },
            {
                path: '/admin/clozes',
                name: 'clozes',
                component: () => import(/* webpackChunkName: "cloze" */ '@/site-pages/admin/cloze/ClozeView.vue'),
                meta: {
                    roles: [Role.ADMIN],
                },
            },
            {
                path: '/admin/users',
                name: 'users',
                component: () => import(/* webpackChunkName: "users" */ '@/site-pages/admin/users/UsersView.vue'),
                meta: {
                    roles: [Role.ADMIN],
                },
            },
            {
                path: '/admin/groups',
                name: 'groups',
                component: () => import(/* webpackChunkName: "groups" */ '@/site-pages/admin/groups/GroupsView.vue'),
                meta: {
                    roles: [Role.ADMIN],
                },
            },
        ],
    },
];

export default adminRoutes;
