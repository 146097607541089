import { AxiosPromise } from 'axios';

import BaseRestService from '@/common/rest/base-rest-service';

import type {
    BatchImportStudentModel,
    ResponseGrade,
    StudentGamingSettings,
    StudentModel,
    BookshelfSettingsDto,
    ConstructedResponseModel,
    ClassroomInboxItem,
    ClassData,
    AssessmentSetting,
    StudentBadge,
    StudentDetailsModel,
    ClassHours,
    ReadingGroup,
    UserModel,
} from '@/types';
import { StudentInterfaceTypeSetting } from '@/store/auth/types';

interface AssessmentSettingPayload {
    userId: number;
    assessmentEnabled: boolean;
}

interface BulkCreateStudentsPayload {
    classroomId: number;
    students: Omit<BatchImportStudentModel, 'id'>[];
}

class ClassroomRestService extends BaseRestService {
    getClasses(): AxiosPromise<any> {
        return super.get('/api/classes/myclasses');
    }

    getConstructedResponse(): AxiosPromise<ClassroomInboxItem[]> {
        return super.get('/api/quiz_responses');
    }

    getUserQuiz(studentId: number, questionId: number): AxiosPromise<ConstructedResponseModel> {
        return super.get(`/api/quiz_responses/${studentId}/${questionId}`);
    }

    changeSkillStandardMeet({
        studentId,
        questionId,
        respMeet,
    }: {
        studentId: number;
        questionId: number | null;
        respMeet: ResponseGrade[];
    }): AxiosPromise<ConstructedResponseModel> {
        return super.post(`/api/quiz_responses/${studentId}/${questionId}`, respMeet);
    }

    getClass(): AxiosPromise<ClassData> {
        return super.get('/api/classes/myclass');
    }

    getClassUserById(userId: number): AxiosPromise<StudentDetailsModel> {
        return super.get(`/api/classes/myclass/${userId}`);
    }

    updateClassUser(user: Partial<StudentDetailsModel>): AxiosPromise<StudentDetailsModel> {
        const { id: userId } = user;
        return super.put(`/api/classes/myclass/${userId}`, user);
    }

    async getBadges(classroomId: number): Promise<StudentBadge[]> {
        try {
            const response = await super.get(`/api/classes/badges/${classroomId}`);

            if (response?.data && Array.isArray(response.data)) {
                return response.data;
            } else {
                throw new Error('API: Invalid response.');
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    updateClassUsers(updatedUsers): AxiosPromise {
        return super.put(`/api/users/changeparams`, updatedUsers);
    }

    updateQuizAvailability(updatedUsers) {
        const quizSettings = updatedUsers.map(({ id, quizAvailability }) => ({
            userId: id,
            quizAvailability,
        }));
        return super.post(`/api/quiz-settings`, quizSettings);
    }

    updateStudentsInterfaceTypeSetting(
        students: StudentModel[],
        studentInterfaceTypeSetting: StudentInterfaceTypeSetting
    ) {
        const requestBody = students.map((student) => ({
            userId: student.id,
            interfaceSetting: studentInterfaceTypeSetting,
        }));

        return super.post('/api/student-interface-settings', requestBody);
    }

    updateBookSettings(updatedUsers): AxiosPromise {
        const bookSettings = updatedUsers.map(
            ({ id, bookPlayerSettings: { isAudioBookAvailable, isAloudReadingAvailable } }) => ({
                userId: id,
                isAudioBookAvailable,
                isAloudReadingAvailable,
            })
        );
        return super.post(`/api/book-player-settings`, bookSettings);
    }

    updateClassHours(classId: number, settings: ClassHours): AxiosPromise<ClassHours> {
        return super.put(`/api/classes/${classId}/class_hours`, settings);
    }

    addGroup(name: string, classroomId: number, users: StudentModel[]): AxiosPromise<ReadingGroup> {
        return super.post('/api/reading-groups', { name, classroomId, users });
    }

    renameGroup(groupId: number, newGroupName: string): AxiosPromise<ReadingGroup> {
        return super.put(`/api/reading-groups/${groupId}?groupName=${newGroupName}`, {});
    }

    manageGroup(groupId: number, students: UserModel[]): AxiosPromise<ReadingGroup> {
        return super.post(`/api/reading-groups/${groupId}/users`, students);
    }

    removeGroup(groupId: number): AxiosPromise {
        return super.delete(`/api/reading-groups/${groupId}`);
    }

    triggerProgressAssessment(studentId: number): AxiosPromise {
        return super.post(`/api/triggers/progress/${studentId}`, {});
    }

    triggerInTakeAssessment(studentId: number): AxiosPromise {
        return super.post(`/api/triggers/intake/${studentId}`, {});
    }

    loadAssignedBooks(): AxiosPromise {
        return super.get('/api/assign/student-book');
    }

    getAssessmentSettings(classroomId: number | null = null): AxiosPromise<{ users: AssessmentSetting[] }> {
        return super.get(`/api/classes/${classroomId || 'myclass'}/assessments`);
    }

    bulkUpdateAssessmentSettings(payload: AssessmentSettingPayload[]): AxiosPromise {
        return super.put(`/api/classes/myclass/assessments/batch`, payload);
    }

    updateAssessmentSettings({ userId, assessmentEnabled }: AssessmentSettingPayload): AxiosPromise {
        return super.put(`/api/classes/myclass/assessments`, {
            userId,
            assessmentEnabled,
        });
    }

    getBookshelfSettings(classroomId: number | null = null): AxiosPromise<BookshelfSettingsDto[]> {
        return super.get(`/api/classes/${classroomId || 'myclass'}/bookshelf`);
    }

    bulkUpdateBookshelfSettings(settings: BookshelfSettingsDto[]): AxiosPromise<BookshelfSettingsDto[]> {
        return super.put(`/api/classes/myclass/bookshelf/batch`, settings);
    }

    updateBookshelfSettings(settings: BookshelfSettingsDto): AxiosPromise<BookshelfSettingsDto> {
        return super.put(`/api/classes/myclass/bookshelf`, settings);
    }

    bulkCreateStudents({ classroomId, students }: BulkCreateStudentsPayload) {
        return super.post(`/api/classes/${classroomId}/students`, { students });
    }

    updateGamingSettings(settings: StudentGamingSettings[]): AxiosPromise {
        return super.put(`/api/classes/myclass/gaming/batch`, settings);
    }
}

const classroomRestService: ClassroomRestService = new ClassroomRestService();

export { classroomRestService };
