import { RouteConfig } from 'vue-router';

import { BookshelfRoute } from './bookshelf';

const playerRoutes: RouteConfig[] = [
    {
        path: '/bookshelf/:id',
        name: BookshelfRoute.PLAYER,
        props: true,
        component: () => import(/* webpackChunkName: "colibrioReader" */ '@/player/reader/ColibrioReader.vue'),
    },
    {
        path: '/bookshelf/:id/:containerId/:query',
        name: BookshelfRoute.PLAYER_ON_SET_PAGE,
        props: true,
        component: () => import(/* webpackChunkName: "colibrioReader" */ '@/player/reader/ColibrioReader.vue'),
    },
];

export default playerRoutes;
