import UsersIcon from '@/assets/classroom-icon.svg';
import ClozesIcon from '@/assets/report.svg';
import GroupsIcon from '@/assets/level-up-now.svg';

export default function useAdminMenuItems() {
    return [
        {
            id: 'menu-clozes-tab',
            icon: ClozesIcon,
            name: 'Clozes',
            url: '/admin/clozes',
        },
        {
            id: 'menu-users-tab',
            icon: UsersIcon,
            name: 'Users',
            url: '/admin/users',
        },
        {
            id: 'menu-groups-tab',
            icon: GroupsIcon,
            name: 'Groups',
            url: '/admin/groups',
        },
    ];
}
