import BaseRestService from '@/common/rest/base-rest-service';

class ActivityRestService extends BaseRestService {
    trackReading(bookIdNumber: string) {
        return super.shadowPost(`/api/activities/log/read`, { bookIdNumber });
    }

    trackListening(bookIdNumber: string) {
        return super.shadowPost(`/api/activities/log/listen`, { bookIdNumber });
    }

    finishBook(bookIdNumber: string, voiceRecordingLinkId?: string) {
        if (voiceRecordingLinkId) {
            return super.post(`/api/activities/finish-book`, { bookIdNumber, linkId: voiceRecordingLinkId });
        }
        return super.post(`/api/activities/finish-book`, { bookIdNumber });
    }
}

const activityRestService: ActivityRestService = new ActivityRestService();

export default activityRestService;
