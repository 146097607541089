import { defineStore } from 'pinia';
import { classroomRestService } from '@/rest';

export type ClassroomSwitcherTeacherClass = { id: number; name: string; studentIds: number[] };

export const useClassroomSwitcherStore = defineStore('classroom-switcher', {
    state: () => ({
        selectedClasses: [] as ClassroomSwitcherTeacherClass[],
        classes: [] as ClassroomSwitcherTeacherClass[],
        initialized: false as boolean,
    }),

    actions: {
        async loadClasses() {
            const response = (await classroomRestService.getClasses()).data;

            const classes = [] as ClassroomSwitcherTeacherClass[];

            for (const teacherClass of response) {
                classes.push({
                    id: teacherClass.id,
                    name: teacherClass.name,
                    studentIds: teacherClass.users.map((user) => user.id),
                });
            }

            this.classes = classes;
            this.initialized = true;
        },
    },

    getters: {
        selectedStudentIds(): number[] {
            if (this.selectedClasses.length) {
                let allSelectedStudentIds = [] as number[];
                for (const c of this.selectedClasses) {
                    allSelectedStudentIds = [...allSelectedStudentIds, ...c.studentIds];
                }
                return [...new Set(allSelectedStudentIds)];
            } else {
                return [];
            }
        },
    },
});
