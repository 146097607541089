import { defineStore } from 'pinia';
import { format } from 'date-fns';

import { gameRestService } from '@/rest';
import { GameAccessStatus } from './types';

import type { GameState } from './types';

export const useGameStore = defineStore('game', {
    state: (): GameState => ({
        gameAccessStatus: null,
    }),

    actions: {
        async getGameAccessStatus() {
            /**
             * https://date-fns.org/v2.29.3/docs/format
             */
            const userDateTime = format(new Date(), 'yyyy-MM-dd:HH:mm:ss');
            const { data } = await gameRestService.getGameAccessStatus(userDateTime);

            this.gameAccessStatus = GameAccessStatus[data];
        },
    },
});
