
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

@Component({
    inheritAttrs: false
})
export default class LUFormControl extends Mixins(singleErrorExtractorMixin) {
    @Prop({
        default: null,
        type: String
    })
    label!: string;

    @Prop({
        default: null,
        type: String
    })
    htmlLabel!: string;

    @Prop({
        default: false,
        type: Boolean
    })
    required!: boolean;
}
