
import axios from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { TrackActions, trackNamespace } from '@/common/rest/track/track-polling-model';
import IdleTracker from '@/utils/idle-tracker';
import { useAuth } from '@/store';
import { NOTIFICATION_TYPES } from './constants';
import { Spinner } from './components';

@Component({
    methods: {
        ...mapActions(trackNamespace, {
            logTime: TrackActions.logTime,
        }),
    },
    components: {
        Spinner,
    },
})
export default class App extends Vue {
    logTime!: () => Promise<void>;

    auth = useAuth();

    idleTracker: IdleTracker | undefined;
    lsToken: string | null = '';

    public isLoading = true;

    public isFailedToLoadUserInterfaceType = false;

    setLsToken(): void {
        const lsToken = localStorage.getItem('userToken');

        if (lsToken !== this.lsToken) {
            this.lsToken = lsToken;
        }
    }

    async onIdleTimeout() {
        if (this.auth.isAuthenticated) {
            this.$router.push({ name: 'logout' }).catch(() => {});
        }

        /**
         * We send 'LOGOUT' message to our mobile app by webview
         * that it can request silent login
         */
        if (window && 'ReactNativeWebView' in window) {
            (window['ReactNativeWebView'] as any).postMessage('LOGOUT');
        }
    }

    onLocalStorageChanged(): void {
        this.setLsToken();
    }

    async checkInterfaceType() {
        try {
            if (!this.auth.isAuthenticated) {
                return;
            }

            if (!this.auth.interfaceType) {
                this.isLoading = true;
                await this.auth.fetchProfile();

                if (!this.auth.interfaceType) {
                    throw new Error('Failed to load user interfaceType.');
                }
            }
        } catch (error) {
            this.isFailedToLoadUserInterfaceType = true;
        } finally {
            this.isLoading = false;
        }
    }

    created() {
        this.idleTracker = new IdleTracker({ onIdleTimeout: this.onIdleTimeout, idleLimit: 1800000 });

        /**
         * We turn off 'auth-track' logic when Web app is open into webview,
         * because it has its own
         */
        const notWasOpenedInWebView = window && !('ReactNativeWebView' in window);

        if (notWasOpenedInWebView && this.auth.isAuthenticated && !this.idleTracker.hasExpired) {
            this.logTime();
        }
    }

    async mounted() {
        await this.checkInterfaceType();
        this.lsToken = localStorage.getItem('userToken');
        window.addEventListener('storage', this.onLocalStorageChanged);
    }

    beforeDestroy() {
        window.removeEventListener('storage', this.onLocalStorageChanged);
    }

    @Watch('lsToken')
    lsTokenChange(lsToken: string) {
        if (lsToken && this.auth.userToken && lsToken !== this.auth.userToken) {
            location.reload();
        }
    }

    @Watch('auth.isAuthenticated')
    onAuthenticatedChange(isAuthenticated: boolean) {
        if (isAuthenticated) {
            this.idleTracker!.startIdleTracking();
        } else {
            this.idleTracker!.stopIdleTracking();
        }
    }

    errorCaptured(err) {
        if (axios.isCancel(err)) {
            return false;
        }

        const text = err.data?.userMessage || err.data?.error;

        if (text) {
            this.$notify({ clean: true });
            this.$notify({
                text,
                type: NOTIFICATION_TYPES.ERROR,
            });

            return false;
        }
    }
}
