
import { Component, Vue, Prop } from 'vue-property-decorator';

const CDN_HOST = process.env.VUE_APP_CDN_HOST;

@Component({})
export default class BookCover extends Vue {
    @Prop({ required: true })
    idNumber!: string;

    @Prop({ required: true })
    title!: string;

    @Prop({ type: Boolean, default: false })
    isTopPic!: boolean;

    @Prop({ type: Boolean, default: false })
    listView!: boolean;

    @Prop({ required: false })
    altText!: string;

    get src(): string {
        const host = `${CDN_HOST}/covers/`;

        return `${host}${this.idNumber}${this.isTopPic ? '_top_pic' : ''}.png`;
    }

    get alt(): string {
        return this.altText || `Cover image for ${this.title}`;
    }

    onLoadImg() {
        this.$emit('cover:loaded');
    }
}
