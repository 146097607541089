import BaseRestService from '@/common/rest/base-rest-service';

class GameRestService extends BaseRestService {
    getGameAccessStatus(userDateTime: string) {
        return super.shadowGet(`/api/game/access?userDateTime=${userDateTime}`);
    }
}

const gameRestService: GameRestService = new GameRestService();

export default gameRestService;
