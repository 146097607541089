import { FiltersModel } from '@/types';

export const omitDefaultValues = (filters: FiltersModel, defaults: Partial<FiltersModel>): FiltersModel => ({
    ...filters,
    lexileLevel:
        filters.lexileLevel[0] !== defaults.lexileLevel?.[0] || filters.lexileLevel[1] !== defaults.lexileLevel?.[1]
            ? filters.lexileLevel
            : [],
    audioLength:
        filters.audioLength[0] !== defaults.audioLength?.[0] || filters.audioLength[1] !== defaults.audioLength?.[1]
            ? filters.audioLength
            : [],
    pageCount:
        filters.pageCount[0] !== defaults.pageCount?.[0] || filters.pageCount[1] !== defaults.pageCount?.[1]
            ? filters.pageCount
            : [],
});
