import { NavigationGuard, Route } from 'vue-router';

import { pinia, useRouteStore } from '@/store';
import { BookshelfRoute } from '../routes';

const routeGuard = (to: Route, from: Route, next: Function): NavigationGuard => {
    const routeStore = useRouteStore(pinia);

    const isFromQuiz = from.name === 'quiz' || from.name === 'quiz-score';
    const isFromBook = from.name === BookshelfRoute.PLAYER || from.name === BookshelfRoute.PLAYER_ON_SET_PAGE;

    if ((isFromBook || isFromQuiz) && !routeStore.previousRouteBeforeBookAndQuiz) {
        routeStore.previousRouteBeforeBookAndQuiz = routeStore.previousRoute;
    }

    if (!isFromBook && !isFromQuiz) {
        routeStore.previousRouteBeforeBookAndQuiz = undefined;
    }

    routeStore.previousRoute = from;

    return next();
};

export default routeGuard;
