import { RouteConfig } from 'vue-router';

import { pinia, useAuth } from '@/store';
import { HOME_PAGES, REPORT_PAGES, Role } from '@/constants';
import miltiDistrictRoutes from './multi-district';
import districtRoutes from './district';
import schoolRoutes from './school';
import teacherRoutes from './teacher';

export * from './config';

const ReportsView = () => import(/* webpackChunkName: "reports" */ '@/site-pages/reports/view/ReportsView.vue');

const SkillStandardDetailsReportView = () =>
    import(/* webpackChunkName: "reports" */ '@/site-pages/reports/view/activity/SkillStandardDetails.vue');

const reportsRoutes: RouteConfig[] = [
    {
        path: 'reports',
        component: ReportsView,
        children: [
            {
                path: '',
                name: 'reports',
                redirect() {
                    const auth = useAuth(pinia);

                    return auth.role ? REPORT_PAGES[auth.role] || HOME_PAGES.default : HOME_PAGES.default;
                },
                meta: {
                    roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER, Role.SCHOOL_MANAGER, Role.TEACHER],
                },
            },
            ...miltiDistrictRoutes,
            ...districtRoutes,
            ...schoolRoutes,
            ...teacherRoutes,
            {
                path: ':breakdownType/:itemId/:type/:id?',
                name: 'report.breakdown',
                props: true,
                component: SkillStandardDetailsReportView,
                meta: {
                    roles: [Role.MULTI_DISTRICT_MANAGER, Role.DISTRICT_MANAGER, Role.SCHOOL_MANAGER, Role.TEACHER],
                },
            },
        ],
    },
];

export default reportsRoutes;
