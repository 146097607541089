import { defineStore } from 'pinia';

import { classroomRestService } from '@/rest';

import type { ClassroomGamingState } from './types';
import { useClassroomRosterStore } from './roster';
import { StudentGamingSettings, StudentModel } from '@/types';

export const useClassroomGamingStore = defineStore('classroom-gaming', {
    state: (): ClassroomGamingState => ({
        classroomId: null,
        classHours: {
            dayFrom: '',
            dayTo: '',
            timeFrom: '',
            timeTo: '',
        },
        isLoading: false,
        isSaving: false,
        settings: new Map(),
    }),

    actions: {
        async fetchSettings() {
            const rosterStore = useClassroomRosterStore();

            this.isLoading = true;

            try {
                const {
                    data: { classHours, id, users },
                } = await classroomRestService.getClass();

                this.classroomId = id;
                this.classHours = classHours;
                this.setSettings(users);
                rosterStore.setRoster(users);
            } finally {
                this.isLoading = false;
            }
        },

        async saveClassHours() {
            if (!this.classroomId) {
                return;
            }

            const { data: classHours } = await classroomRestService.updateClassHours(this.classroomId, this.classHours);

            this.classHours = classHours;
        },

        async saveSettings(settings: StudentGamingSettings[]) {
            const reserveUserData: StudentModel[] = [...this.gamingSettings];

            this.isSaving = true;

            try {
                this.updateSettings(settings);
                await classroomRestService.updateGamingSettings(settings);
            } catch (error) {
                this.setSettings(reserveUserData);
                throw error;
            } finally {
                this.isSaving = false;
            }
        },

        updateSettings(newSettings: StudentGamingSettings[]) {
            const settings = new Map(this.settings);

            newSettings.forEach((item) => {
                const currentSettings = this.settings.get(item.userId);

                if (currentSettings) {
                    const { gameLimit, gameAccess, awardGameMoney } = item;
                    const { currentGameMoney } = currentSettings;

                    settings.set(item.userId, {
                        ...currentSettings,
                        gameLimit,
                        gameAccess,
                        ...(awardGameMoney && { currentGameMoney: currentGameMoney + awardGameMoney }),
                    });
                }
            });

            this.settings = settings;
        },

        setSettings(newSettings: StudentModel[]) {
            const settings = new Map(this.settings);

            newSettings.forEach(({ id: userId, ...rest }) => settings.set(userId, { userId, ...rest }));

            this.settings = settings;
        },

        updateClassHours({ key, value }: Record<string, string>) {
            this.classHours = {
                ...this.classHours,
                [key]: value,
            };
        },
    },

    getters: {
        gamingSettings(state: ClassroomGamingState): StudentModel[] {
            const roster = useClassroomRosterStore();

            const settings = roster.students.map((student: StudentModel) => ({
                ...student,
                ...state.settings.get(student.id)!,
            }));

            return settings;
        },
    },
});
