import { AxiosPromise } from 'axios';

import BaseRestService from '@/common/rest/base-rest-service';

import type { TeacherMaterialModel } from '@/shared/model/book-details-model';
import type {
    Assignment,
    BatchAssignment,
    StudentMultipleAssignment,
    RunningRecordReview,
    RunningRecord,
    RunningRecordDetails,
    RunningRecordPassage,
    RunningRecordStudents,
    RunningRecordAssignmentAudio,
    StudentRunningRecordAssignment,
} from '@/types';

class RunningRecordsRestService extends BaseRestService {
    endpoints = {
        assignmentResources: (idNumber) => `/api/running-records/assignment/current/${idNumber}`,
        completeAssignment: (id: number) => `/api/running-records/assignment/${id}/complete`,
        assingments: (id: number) => `/api/running-records/assign/${id}`,
        batchAssingment: (id: number) => `/api/running-records/assign/batch/${id}`,
        runningRecord: (id: number) => `/api/running-records/assignment/${id}`,
        runningRecordDetails: (idNumber: string) => `/api/running-records/details/${idNumber}`,
        runningRecordPassages: () => '/api/running-records/passages',
        runningRecordResources: (idNumber: string) => `/api/running-records/resources/${idNumber}`,
        runningRecordReview: (id: number) => `/api/running-records/teacher-review/${id}`,
        unassign: (id: number) => `/api/running-records/unassign/${id}`,
        myClass: () => '/api/running-records/myclass',
        assignmentAudio: (id: number) => `/api/running-records/assignment/audio/${id}`,
    };

    fetchRunningRecord(id: number): AxiosPromise<RunningRecord> {
        const endpoint = this.endpoints.runningRecord(id);

        return super.get(endpoint);
    }

    fetchRunningRecordReview(id: number): AxiosPromise<RunningRecordReview> {
        const endpoint = this.endpoints.runningRecordReview(id);

        return super.get(endpoint);
    }

    fetchAssignmentResources(idNumber: string): AxiosPromise<Assignment> {
        const endpoint = this.endpoints.assignmentResources(idNumber);

        return super.get(endpoint);
    }

    completeAssignment(id: number) {
        const url = this.endpoints.completeAssignment(id);

        return super.post(url);
    }

    uploadAudioRecord(url: string, blob: Blob) {
        return super.put(url, blob, {
            headers: {
                'Content-Type': blob.type,
            },
            withCredentials: false,
        });
    }

    saveRunningRecordReview(review: RunningRecordReview): AxiosPromise<RunningRecordReview> {
        const { urrId, ...payload } = review;
        const endpoint = this.endpoints.runningRecordReview(urrId);

        return super.put(endpoint, payload);
    }

    fetchRunningRecordDetails(idNumber: string): AxiosPromise<RunningRecordDetails> {
        const endpoint = this.endpoints.runningRecordDetails(idNumber);

        return super.get(endpoint);
    }

    fetchRunningRecordPassages(): AxiosPromise<RunningRecordPassage[]> {
        const endpoint = this.endpoints.runningRecordPassages();

        return super.get(endpoint);
    }

    fetchRunningRecordResources(idNumber: string): AxiosPromise<{
        teacherMaterials: TeacherMaterialModel[];
    }> {
        const endpoint = this.endpoints.runningRecordResources(idNumber);

        return super.get(endpoint);
    }

    fetchAssignmentsByIdNumber(id: number): AxiosPromise<StudentMultipleAssignment[]> {
        const endpoint = this.endpoints.assingments(id);

        return super.get(endpoint);
    }

    assignRunningRecord(
        id: number,
        assignments: Partial<StudentRunningRecordAssignment>[]
    ): AxiosPromise<StudentMultipleAssignment[]> {
        const endpoint = this.endpoints.assingments(id);

        return super.put(endpoint, assignments);
    }

    batchAssignRunningRecord(id: number, assignment: BatchAssignment): AxiosPromise<BatchAssignment> {
        const endpoint = this.endpoints.batchAssingment(id);

        return super.put(endpoint, assignment);
    }

    unassignRunningRecord(id: number, assignments: number[]): AxiosPromise<StudentMultipleAssignment[]> {
        const endpoint = this.endpoints.unassign(id);

        return super.put(endpoint, assignments);
    }

    fetchRunningRecordStudents(id: number, studentsIds: number[]): AxiosPromise<RunningRecordStudents> {
        const endpoint = this.endpoints.batchAssingment(id);

        return super.post(endpoint, studentsIds);
    }

    getRoster() {
        const endpoint = this.endpoints.myClass();

        return super.get(endpoint);
    }

    fetchRunningRecordAssignmentAudio(id: number): AxiosPromise<RunningRecordAssignmentAudio> {
        const endpoint = this.endpoints.assignmentAudio(id);

        return super.get(endpoint);
    }
}

const runningRecordsRestService: RunningRecordsRestService = new RunningRecordsRestService();

export default runningRecordsRestService;
