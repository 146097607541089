
import { Component, Prop, Vue } from 'vue-property-decorator';

import { formattedLexile } from '@/filters';
import { CardClickEvent } from '@/types/bookshelf';
import { BookCover, LUButton, LUCard, DueDateLabel } from '@/components';
import { CarouselItem } from '@/types/carousels';
import { useAuth } from '@/store';

@Component({
    components: {
        BookCover,
        DueDateLabel,
        LUButton,
        LUCard,
    },
})
export default class RunningRecordsCard extends Vue {
    @Prop()
    readonly runningRecord!: CarouselItem;

    @Prop({ type: Boolean, default: false })
    readonly withActions!: boolean;

    @Prop({ default: true, type: Boolean })
    readonly withDetails!: boolean;

    auth = useAuth();

    get idNumber(): string {
        return this.runningRecord.idNumber;
    }

    get title(): string {
        return this.runningRecord.title;
    }

    get type(): string {
        return this.runningRecord.bookType;
    }

    get lexileWeight(): string {
        return formattedLexile(this.runningRecord.lexileWeight);
    }

    hidePopovers() {
        this.$root.$emit('bv::hide::popover');
    }

    onCardClick(e: MouseInput) {
        if (!e.target.matches('.running-records-card__button-icon')) {
            this.$emit('click', {
                idNumber: this.runningRecord.idNumber,
            } as CardClickEvent);
        }
    }

    onAssignToClick() {
        this.hidePopovers();
        this.$emit('click', {
            idNumber: this.runningRecord.idNumber,
            action: 'assign',
        } as CardClickEvent);
    }

    onCoverLoaded() {
        this.$emit('cover:loaded');
    }
}
