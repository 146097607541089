import BaseRestService from '@/common/rest/base-rest-service';
import { TagModel } from '@/types/tags';

class TagsRestService extends BaseRestService {
    getTags() {
        return super.get('/api/tags');
    }

    getBookTags(idNumber: string) {
        return super.get(`/api/tags/book/${idNumber}`);
    }

    updateBookTags(tags: TagModel[], idNumber: string) {
        return super.put(`/api/tags/book/${idNumber}`, tags.map(tag => tag.id));
    }

    createTag(tagName: string) {
        return super.post('/api/tags', tagName, { headers: { 'Content-Type': 'text/plain' } });
    }

    deleteTag(tag: TagModel) {
        return super.delete(`/api/tags/${tag.id}`);
    }

    updateTag(tag: TagModel) {
        return super.put(`/api/tags`, tag);
    }
}

const tagsRestService: TagsRestService = new TagsRestService();

export { tagsRestService };
