import { RouteConfig } from 'vue-router';

import { Role } from '@/constants';

const studentReportRoutes: RouteConfig[] = [
    {
        path: 'report',
        name: 'student-report',
        component: () => import(/* webpackChunkName: "reports" */ '@/site-pages/student-report/StudentReport.vue'),
        meta: {
            roles: [Role.STUDENT],
        },
    },
];

export default studentReportRoutes;
