
import { Component, Vue, Prop, InjectReactive } from 'vue-property-decorator';
import { directive as onClickaway } from 'vue-clickaway';

import Checkbox from '@/components/Checkbox.vue';
import Dropdown from '@/common/components/Dropdown.vue';
import { AudioLengthFilter, LexileRangeFilter, PageCountFilter } from '@/components/filter-menu/filters';
import { CategoryFilterItem, FilterItem, FiltersModel, LelelUpTheme } from '@/types';
import { useAuth } from '@/store';

@Component({
    components: {
        AudioLengthFilter,
        Checkbox,
        Dropdown,
        LexileRangeFilter,
        PageCountFilter,
    },

    directives: {
        onClickaway,
    },
})
export default class FilterMenu extends Vue {
    @InjectReactive('appTheme')
    theme!: LelelUpTheme;

    @Prop()
    defaults!: Partial<FiltersModel>;

    @Prop()
    filters!: FiltersModel;

    @Prop()
    selectedFilters!: FiltersModel;

    @Prop({ default: true, type: Boolean })
    showStandards!: boolean;

    @Prop({ default: false, type: Boolean })
    teleportAdvancedFilters!: boolean;

    auth = useAuth();

    searchStandard: string = '';
    shouldDisplayMoreFilters: boolean = false;
    showAdvancedStandards: boolean = false;

    isShowAdvancedFiltersDropDown: boolean = false;

    get innerSelectedFilters(): FiltersModel {
        return this.selectedFilters;
    }

    set innerSelectedFilters(filter: FiltersModel) {
        this.$emit('update:selectedFilters', filter);
    }

    get hasItems(): boolean {
        return this.standards && !!this.standards.length;
    }

    get isShowStandardList(): boolean {
        return this.showAdvancedStandards && this.hasItems;
    }

    get selectedCategory(): Array<FilterItem> {
        return this.innerSelectedFilters.categories as Array<CategoryFilterItem>;
    }

    set selectedCategory(categories: Array<FilterItem>) {
        this.innerSelectedFilters = { ...this.innerSelectedFilters, categories };
    }

    get selectedLanguages(): Array<FilterItem> {
        return this.innerSelectedFilters.languages as Array<FilterItem>;
    }

    set selectedLanguages(languages: Array<FilterItem>) {
        this.innerSelectedFilters = { ...this.innerSelectedFilters, languages };
    }

    get selectedFictionTypes(): Array<FilterItem> {
        return this.innerSelectedFilters.fictionTypes as Array<FilterItem>;
    }

    set selectedFictionTypes(fictionTypes: Array<FilterItem>) {
        this.innerSelectedFilters = { ...this.innerSelectedFilters, fictionTypes };
    }

    get selectedLexileRange(): number[] {
        return this.innerSelectedFilters.lexileLevel;
    }

    set selectedLexileRange(lexileLevel: Array<number>) {
        const prevLexileLevel = this.selectedLexileRange;

        if (prevLexileLevel !== lexileLevel) {
            this.innerSelectedFilters = { ...this.innerSelectedFilters, lexileLevel };
        }
    }

    get selectedPageCount(): Array<number> {
        return this.innerSelectedFilters.pageCount as Array<number>;
    }

    set selectedPageCount(pageCount: Array<number>) {
        const prevPageCount = this.selectedPageCount;

        if (prevPageCount !== pageCount) {
            this.innerSelectedFilters = { ...this.innerSelectedFilters, pageCount };
        }
    }

    get selectedAudioLength(): Array<number> {
        return this.innerSelectedFilters.audioLength as Array<number>;
    }

    set selectedAudioLength(audioLength: Array<number>) {
        const prevAudioLength = this.selectedAudioLength;

        if (prevAudioLength !== audioLength) {
            this.innerSelectedFilters = { ...this.innerSelectedFilters, audioLength };
        }
    }

    get selectedSkills(): Array<FilterItem> {
        return this.innerSelectedFilters.skills as Array<FilterItem>;
    }

    set selectedSkills(skills: Array<FilterItem>) {
        this.innerSelectedFilters = { ...this.innerSelectedFilters, skills };
    }

    get selectedStandards(): Array<FilterItem> {
        return this.innerSelectedFilters.standards as Array<FilterItem>;
    }

    set selectedStandards(standards: Array<FilterItem>) {
        this.innerSelectedFilters = { ...this.innerSelectedFilters, standards };
    }

    get standards(): Array<FilterItem> {
        return this.filters.standards.filter((standard: FilterItem) => {
            return standard.name.search(new RegExp(this.searchStandard, 'i')) !== -1;
        });
    }

    get categories(): Array<CategoryFilterItem> {
        return this.filters.categories;
    }

    get languages(): Array<FilterItem> {
        return this.filters.languages;
    }

    get fictionTypes(): Array<FilterItem> {
        return this.filters.fictionTypes;
    }

    get skills(): Array<FilterItem> {
        return this.filters.skills;
    }

    get audioLengthDefaults() {
        return this.defaults
            ? {
                  min: this.defaults?.audioLength ? this.defaults.audioLength[0] : undefined,
                  max: this.defaults?.audioLength ? this.defaults.audioLength[1] : undefined,
              }
            : undefined;
    }

    get lexileRangeDefaults() {
        return this.defaults
            ? {
                  min: this.defaults?.lexileLevel ? this.defaults?.lexileLevel[0] : undefined,
                  max: this.defaults?.lexileLevel ? this.defaults?.lexileLevel[1] : undefined,
              }
            : undefined;
    }

    get pageCountDefaults() {
        return this.defaults
            ? {
                  min: this.defaults?.pageCount ? this.defaults?.pageCount[0] : undefined,
                  max: this.defaults?.pageCount ? this.defaults?.pageCount[1] : undefined,
              }
            : undefined;
    }

    removeSelectedStandard(standard: FilterItem) {
        this.selectedStandards = this.selectedFilters.standards.filter((st) => st.id !== standard.id);
    }

    selectAllStandards() {
        this.selectedStandards = this.standards;
    }

    deselectAllStandards() {
        this.selectedStandards = [];
    }

    get allStandardsSelected(): boolean {
        return this.selectedStandards.length === this.standards.length;
    }

    selectAllSkills() {
        this.selectedSkills = this.skills;
    }

    deselectAllSkills() {
        this.selectedSkills = [];
    }

    get allSkillsSelected(): boolean {
        return this.selectedSkills.length === this.skills.length;
    }

    searchStandards() {
        const standard: FilterItem | undefined = this.standards.find((standard: FilterItem) => {
            return standard.name === this.searchStandard;
        });

        if (standard && !this.selectedStandards.includes(standard as FilterItem)) {
            this.selectedStandards.push(standard as FilterItem);
        }
        this.hideStandardList();
    }

    hideStandardList() {
        this.showAdvancedStandards = false;
    }

    focusStandardInput() {
        this.showStandardList();
    }

    showStandardList() {
        this.showAdvancedStandards = true;
    }

    hideMoreFilters() {
        this.shouldDisplayMoreFilters = false;
    }

    toggleMoreFilters() {
        this.shouldDisplayMoreFilters = !this.shouldDisplayMoreFilters;
    }
}
