
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueSlider from 'vue-slider-component';

export type Range = [number, number];
export type SliderInputSize = 'large' | 'medium';

enum DOT_SIZE {
    large = 40,
    medium = 20
}

enum TRACK_HEIGHT {
    large = 8,
    medium = 4
}

@Component({
    components: {
        VueSlider
    }
})
export default class SliderInput extends Vue {
    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: 1, type: Number })
    interval!: number;

    @Prop()
    min!: number;

    @Prop()
    max!: number;

    @Prop({ default: false, type: Boolean })
    rangeTooltip!: boolean;

    @Prop({
        default: () => {},
        type: Function
    })
    rangeFormatter!: (model: number[]) => string;

    @Prop({
        default: 'medium',
        type: String
    })
    size!: SliderInputSize;

    @Prop({
        default: () => {},
        type: Function
    })
    tooltipFormatter!: (value: number | string) => string;

    @Prop()
    value!: Range;

    @Watch('value')
    onValueChange(newValue: Range) {
        this.model = newValue;
    }

    get dotSize(): number {
        return DOT_SIZE[this.size];
    }

    get formattedRange(): string {
        return this.rangeFormatter && typeof this.rangeFormatter === 'function'
            ? this.rangeFormatter(this.model)
            : this.defaultRangeFormatter(this.model);
    }

    get model(): Range {
        return this.value;
    }

    set model(value: Range) {
        this.$emit('input', value);
    }

    get trackHeight(): number {
        return TRACK_HEIGHT[this.size];
    }

    defaultRangeFormatter(model: number[]) {
        const [start, end] = model;

        return `${start} – ${end}`;
    }

    isDefaultValue(index) {
        return this.model[index] === this.min || this.model[index] === this.max;
    }

    onDragging(value: Range) {
        this.model = value;
    }

    update() {
        this.$emit('change', this.model);
    }
}
