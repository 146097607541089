import { Module } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { RootState } from '@/store';
import { ReportRequestModel, ActivityTeacherReportModel } from '@/types/reports';

import reportUtils from '@/utils/reports-utils';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { ReportState } from './types';

export const initialState = (): ReportState => ({
    bookQuizzes: [],
    isLoading: false,
    reportRequestModel: {} as ReportRequestModel,
    studentPhonicsReport: [],
    filters: {
        dateRange: reportUtils.getDefaultDateRange(),
        grade: 'all',
        language: 'all',
        groupIds: [],
        classroomIds: [],
        readingGroupIds: [], // for classroom report only
    },
    reportModel: {
        id: null,
        name: '',
        names: [],
        classrooms: [],
        groups: [],
        parentGroups: [],
        countStudents: 0,
        students: [],
        districts: [],
        duplicatedStudents: [],
        avgQuizScore: [],
        classActivityDto: {
            avgGrowth: 0,
            avgQuizScore: 0,
            booksListenedTo: 0,
            booksRead: 0,
            quizzesTaken: 0,
            timeSpentReading: 0,
        } as ActivityTeacherReportModel,
        studentReports: [],
        teacherClassrooms: [],
    },
    grades: [reportUtils.defaultGradeLevel],
    totalReportData: {
        booksRead: 0,
        timeSpentReading: 0,
        booksListenedTo: 0,
        avgQuizPercent: 0,
        countStudents: 0,
        quizzesTaken: 0,
        progress: [],
        avgGrowth: 0,
    },
    totalSkills: [],
});

export const ReportsPersistedState = createPersistedState({
    key: 'rlustate',
    paths: ['reports.filters'],
});

const state: ReportState = initialState();

const namespaced: boolean = true;

const reports: Module<ReportState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};

export default reports;
