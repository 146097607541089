import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

import { HTTP_STATUS_CODE } from '@/constants';
import { useAuth } from '@/store';
import { runningRecordsRestService } from '@/rest';
import { RunningRecordsState } from './types';

export * from './running-record-details';
export * from './running-record-review';

export const useRunningRecordsStore = defineStore('running-records', {
    state: (): RunningRecordsState => ({
        assignment: undefined,
        isFetching: false,
        isFetchingStudents: false,
        isFetchingAudio: false,
        audio: null,
    }),

    actions: {
        async fetchAssignmentResources(recordId: string) {
            const auth = useAuth();

            if (auth.hasStudentRole) {
                try {
                    const { data: assignment } = await runningRecordsRestService.fetchAssignmentResources(recordId);

                    this.assignment = assignment;
                } catch (err) {
                    if ((err as AxiosResponse)?.status === HTTP_STATUS_CODE.NOT_FOUND) {
                        // no assignment for this student
                    } else {
                        throw err;
                    }
                }
            }
        },

        async completeAssignment(assignmentId: number) {
            await runningRecordsRestService.completeAssignment(assignmentId);
        },

        async fetchRunningRecordPassages() {
            this.isFetching = true;

            try {
                const { data: runningRecordPassages } = await runningRecordsRestService.fetchRunningRecordPassages();

                return runningRecordPassages;
            } finally {
                this.isFetching = false;
            }
        },

        async fetchRunningRecordStudents({ id, studentsIds }: { id: number; studentsIds: number[] }) {
            this.isFetchingStudents = true;

            try {
                const { data: runningRecordStudents } = await runningRecordsRestService.fetchRunningRecordStudents(
                    id,
                    studentsIds
                );

                return runningRecordStudents;
            } finally {
                this.isFetchingStudents = false;
            }
        },

        loadRunningRecordAudio(url: string) {
            this.audio = new Audio(url);
            this.runningRecordAudio?.load();
        },

        async fetchRunningRecordAssignmentAudio(id: number) {
            this.isFetchingAudio = true;

            try {
                const { data: recordUrls } = await runningRecordsRestService.fetchRunningRecordAssignmentAudio(id);

                return recordUrls;
            } finally {
                this.isFetchingAudio = false;
            }
        },

        resetAssignment() {
            this.assignment = undefined;
        },
    },

    getters: {
        currentAssignment: (state: RunningRecordsState) => state.assignment,

        runningRecordAudio: (state: RunningRecordsState) => state.audio,
    },
});
