export class FiltersModel {
    type?: AutocompleteType | null = null;
    searchQuery: string = '';
    categories: CategoryFilterItem[] = [];
    languages: FilterItem[] = [];
    fictionTypes: FilterItem[] = [];
    skills: FilterItem[] = [];
    standards: FilterItem[] = [];
    lexileLevel: number[];
    pageCount: number[];
    audioLength: number[];
    insideSearchAvailable: boolean = false;

    constructor(defaults?: Partial<FiltersModel>) {
        this.audioLength = defaults?.audioLength || [0, 0];

        this.lexileLevel = defaults?.lexileLevel || [0, 0];

        this.pageCount = defaults?.pageCount || [0, 0];

        this.insideSearchAvailable = defaults?.insideSearchAvailable || false;
    }
}

export enum FilterQueryParam {
    audioLength = 'audioLength',
    categories = 'categories',
    fictionTypes = 'fictionTypes',
    languages = 'languages',
    lexileLevel = 'lexileLevel',
    pageCount = 'pageCount',
    searchQuery = 'q',
    insideSearchAvailable = 'swb',
    skills = 'skills',
    standards = 'standards',
    type = 'searchBy',
}

export interface FilterItem {
    id: number;
    name: string;
}

export interface CategoryFilterItem extends FilterItem {
    section?: string;
}

export interface FilterFlatItem {
    id: number;
    name: string;
    items: Array<FilterItem>;
    filterName: string;
    isCheckbox: boolean;
    value: number | number[] | boolean;
}

export enum ToggleFilter {
    LEXILE_LEVEL_FILTER_NAME = 'lexileLevel',
    PAGE_COUNT_FILTER_NAME = 'pageCount',
    AUDIO_LENGTH_FILTER_NAME = 'audioLength',
    SEARCH_QUERY_NAME = 'searchQuery',
    SEARCH_TYPE = 'type',
}

export interface AutocompleteModel {
    title: string;
    titlePrefix: string;
    type: AutocompleteType;
}

export enum AutocompleteType {
    TITLE,
    SUBTITLE,
    CATEGORY,
}

export enum LexileLevelRange {
    MIN = -100,
    MAX = 850,
}

export enum LexileReadingRange {
    LOW = 100,
    HIGH = 50,
}

export enum PageRange {
    MIN = 8,
    MAX = 48,
}

export enum AudioLengthRange {
    MIN = 1,
    MAX = 45,
}

export const GRADES = [
    'Pre_K',
    'K',
    'G1',
    'G2',
    'G3',
    'G4',
    'G5',
    'G6',
    'G7',
    'G8',
    'G9',
    'G10',
    'G11',
    'G12',
    'NO_GL',
] as const;

export enum GradeLevel {
    Pre_K = 'Pre-K',
    K = 'K',
    G1 = '1',
    G2 = '2',
    G3 = '3',
    G4 = '4',
    G5 = '5',
    G6 = '6',
    G7 = '7',
    G8 = '8',
    G9 = '9',
    G10 = '10',
    G11 = '11',
    G12 = '12',
    NO_GL = 'No Grade Level',
}

export enum GradeFilterLabels {
    Pre_K = 'Grade Pre-K',
    K = 'Grade K',
    G1 = 'Grade 1',
    G2 = 'Grade 2',
    G3 = 'Grade 3',
    G4 = 'Grade 4',
    G5 = 'Grade 5',
    G6 = 'Grade 6',
    G7 = 'Grade 7',
    G8 = 'Grade 8',
    G9 = 'Grade 9',
    G10 = 'Grade 10',
    G11 = 'Grade 11',
    G12 = 'Grade 12',
    NO_GL = 'Students with no grade level',
}

export type GradeTuple = typeof GRADES;
export type Grade = GradeTuple[number];
