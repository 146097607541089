import { defineStore } from 'pinia';
import { Route } from 'vue-router';
import { union, xor } from 'lodash';

import { FilterQueryParam, FiltersModel, ToggleFilter } from '@/types';

import { searchRestService } from '@/rest';
import { omitDefaultValues } from './lib';

import type { FilterItem } from '@/types';
import type { SearchFiltersState } from './types';

export const useSearchFiltersStore = defineStore('search-filters', {
    state: (): SearchFiltersState => ({
        filters: new FiltersModel(),
        selectedFilters: new FiltersModel({
            audioLength: [],
            lexileLevel: [],
            pageCount: [],
            insideSearchAvailable: false,
        }),
        query: '',
        isLoading: false,
    }),

    actions: {
        async fetchFilters() {
            this.isLoading = true;

            try {
                const { data: filters } = await searchRestService.getFilters();

                this.setFilters(filters);
            } finally {
                this.isLoading = false;
            }
        },

        setFilters(filters: FiltersModel) {
            this.filters = { ...filters, insideSearchAvailable: false };
        },

        setQuery(value: string) {
            this.query = value;
        },

        setSelectedFilters(filters: Partial<FiltersModel>) {
            this.selectedFilters = {
                ...this.selectedFilters,
                ...filters,
            };
        },

        resetFilters() {
            this.selectedFilters = {
                ...new FiltersModel(this.defaults),
                searchQuery: this.query,
            };
        },

        resetQuery() {
            this.setSelectedFilters({
                searchQuery: '',
            });
            this.setQuery('');
        },

        reset() {
            this.resetFilters();
            this.resetQuery();
        },
    },

    getters: {
        defaults: (state: SearchFiltersState): Partial<FiltersModel> => {
            const { audioLength, lexileLevel, pageCount, insideSearchAvailable } = state.filters;

            return { audioLength, lexileLevel, pageCount, insideSearchAvailable };
        },

        hasAppliedFilters(state: SearchFiltersState): boolean {
            const emptyFilters = new FiltersModel(this.defaults);
            const searchFilters = [ToggleFilter.SEARCH_QUERY_NAME, ToggleFilter.SEARCH_TYPE] as string[];

            const isSomeFilterFilled = union(
                Object.keys(state.selectedFilters)
                    .filter((filterName) => !searchFilters.includes(filterName))
                    .map((filterName) => xor(emptyFilters[filterName], state.selectedFilters[filterName]).length !== 0)
            ).find((value) => value === true);

            return Boolean(isSomeFilterFilled);
        },

        getQueryParams() {
            return (filters: FiltersModel): Route['query'] => {
                const nonDefaultValues = omitDefaultValues(filters, this.defaults);

                return Object.keys(filters)
                    .filter((key: string) => Array.isArray(filters[key]))
                    .reduce((acc: Record<string, string>, key: string) => {
                        acc[key] = nonDefaultValues[key].length
                            ? filters[key].map((item: unknown) => (item as FilterItem)?.id ?? item).join(',')
                            : undefined;
                        return acc;
                    }, {});
            };
        },

        getFiltersFromQueryParams() {
            return (query: Route['query'], filters: FiltersModel) => {
                const filterNames = Object.keys(filters);
                const defaultValues = new FiltersModel(this.defaults);

                const getSelectedValues = (value: string, values: []) => {
                    if (['true', 'false'].includes(value)) {
                        return JSON.parse(value);
                    }

                    if (!Array.isArray(values)) {
                        return value;
                    }

                    if (values.some((value) => Number.isInteger(value))) {
                        return String(value).split(',').map(Number);
                    }

                    const ids = String(value).split(',').map(Number);

                    return ids.length ? values.filter((filter: FilterItem) => ids.includes(filter.id)) : [];
                };

                return filterNames.reduce((acc: Record<string, FilterItem[] | number[]>, filter: string) => {
                    acc[filter] = query[FilterQueryParam[filter]]
                        ? getSelectedValues(String(query[FilterQueryParam[filter]]), filters[filter])
                        : defaultValues[filter];
                    return acc;
                }, {});
            };
        },
    },
});
