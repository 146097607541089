import type { TagModel } from '@/types';
import type { IBookTagsMapping, TagsState } from './types';

export const setTags = (tags: TagModel[]) => {
    return new Map<number, TagModel>(tags.map((tag: TagModel) => [tag.id, tag] as [number, TagModel]));
};

export const startLoadingBookTags = (tagsMap: Map<string, IBookTagsMapping>, bookIdNumber: string) => {
    const mapping = tagsMap.has(bookIdNumber)
        ? tagsMap.get(bookIdNumber)
        : {
              subscriptionsCount: 0,
              bookIdNumber: bookIdNumber,
              tagsIds: [],
              isLoading: true,
          };

    return new Map([
        ...tagsMap.entries(),
        [
            bookIdNumber,
            {
                ...(mapping as IBookTagsMapping),
                isLoading: true,
            },
        ],
    ]);
};

export const stopLoadingBookTags = (tagsMap: Map<string, IBookTagsMapping>, bookIdNumber: string) => {
    if (!tagsMap.has(bookIdNumber)) {
        console.error('undefined book tags mapping loading stoped');

        return;
    }
    const mapping = { ...tagsMap.get(bookIdNumber) } as IBookTagsMapping;

    return new Map([
        ...tagsMap.entries(),
        [
            mapping.bookIdNumber,
            {
                ...mapping,
                isLoading: false,
            },
        ],
    ]);
};

export const setBookTags = (
    tagsMap: Map<string, IBookTagsMapping>,
    { bookIdNumber, tagsIds }: { bookIdNumber: string; tagsIds: number[] }
) => {
    const mapping = tagsMap.has(bookIdNumber)
        ? tagsMap.get(bookIdNumber)
        : ({
              subscriptionsCount: 0,
              bookIdNumber: bookIdNumber,
              tagsIds: tagsIds,
              isLoading: true,
          } as IBookTagsMapping);

    return new Map([
        ...tagsMap.entries(),
        [
            bookIdNumber,
            {
                ...(mapping as IBookTagsMapping),
                tagsIds: tagsIds,
            },
        ],
    ]);
};

export const removeTag = ({ tags, tagsMap }: TagsState, tag: TagModel) => {
    const newTagsMap = new Map(
        [...tagsMap.values()]
            .filter((mapping: IBookTagsMapping) => mapping.tagsIds.includes(tag.id))
            .map((mapping: IBookTagsMapping) => [
                mapping.bookIdNumber,
                {
                    ...mapping,
                    tagsIds: mapping.tagsIds.filter((i: number) => i != tag.id),
                },
            ])
    );

    const newTags = new Map(tags);
    newTags.delete(tag.id);

    return { tags: newTags, tagsMap: newTagsMap };
};
