import { Route, RouteConfig } from 'vue-router';

import { Role } from '@/constants';

export enum ClassroomRosterRoute {
    ROSTER = 'classroom.roster',
    ROSTER_ADD_STUDENTS = 'classroom.roster.add-students',
    ROSTER_EDIT_STUDENT = 'classroom.roster.edit',
}

const RosterView = () => import(/* webpackChunkName: "classroom" */ '@/site-pages/classroom/view/ClassroomRoster.vue');

const AddStudentsView = () =>
    import(/* webpackChunkName: "classroom" */ '@/site-pages/classroom/add-students/AddStudentsView.vue');

const EditStudentView = () =>
    import(/* webpackChunkName: "classroom" */ '@/site-pages/classroom/view/ClassroomStudentView.vue');

const rosterRoutes: RouteConfig[] = [
    {
        path: '/classroom/roster',
        name: ClassroomRosterRoute.ROSTER,
        component: RosterView,
        meta: {
            roles: [Role.TEACHER],
        },
    },
    {
        path: '/classroom/roster/add',
        name: ClassroomRosterRoute.ROSTER_ADD_STUDENTS,
        component: AddStudentsView,
        meta: {
            roles: [Role.TEACHER],
        },
    },
    {
        path: '/classroom/roster/:userId',
        name: ClassroomRosterRoute.ROSTER_EDIT_STUDENT,
        props: (route: Route) => ({ userId: Number.parseInt(route.params.userId, 10) }),
        component: EditStudentView,
        meta: {
            roles: [Role.TEACHER],
        },
    },
];

export default rosterRoutes;
