
import { Component, Vue, Prop } from 'vue-property-decorator';

import AccountMenu from '@/components/AccountMenu.vue';
import AdminNav from '@/components/AdminNav.vue';
import { useAuth } from '@/store';
import { InterfaceType } from '@/store/auth/types';

@Component({
    components: {
        AccountMenu,
        AdminNav,
    },
})
export default class UserHeader extends Vue {
    @Prop({ default: true })
    loggedin!: boolean;

    @Prop({ default: null })
    variant!: string;

    auth = useAuth();

    get isNextLogo(): boolean {
        return this.auth.interfaceType === InterfaceType.NEXT && !this.auth.isFullInterfaceType;
    }

    get headerInterfaceType(): string {
        if (this.variant) {
            return `header--${this.variant}`;
        }

        if (this.auth.hasTeacherRole) {
            return 'header--teacher';
        }

        if (this.hasAccessToAdmin) {
            return 'header--admin';
        }

        if (this.auth.hasStudentRole) {
            if (this.auth.interfaceType === InterfaceType.NEXT) return 'header--student-next';
            else return 'header--student';
        }

        return '';
    }

    get hasAccessToAdmin(): boolean {
        return this.auth.hasMultiDistrictRole;
    }
}
